/* eslint react/jsx-no-constructed-context-values: 0 */
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import Routers from 'Routers';
import ScrollToTop from 'ScrollToTop';
import CountryContext from 'context/CountryContext';
import MainContext from 'context/MainContext';
import AuthUser from 'context/AuthUser';
import { getRoutersConfig, RouterKey } from 'configs/routers';
import { countries } from 'configs/countries';
import IsNotSupportedBrowser from 'pages/IsNotSupportedBrowser';
import { AppLoader } from 'ui/AppLoader/AppLoader';
import { ADMIN_URL } from 'services/URL';

import 'styles/globalStyles.css';

declare const process: {
  env: {
    REACT_APP_ROUTERS: RouterKey;
    REACT_APP_URL: string;
  };
};

const minSupportedVersions = {
  Opera: 54,
  Firefox: 61,
  Safari: 13,
  IE: Infinity,
  Chrome: 60,
};

const NON_MAIN_DIR_PATHS = [
  '/about_union_state',
  '/union_state_economic_effects_programs',
  '/union_state_macroeconomic_indicators',
  '/union_state_trade_in_services',
  '/union_state_trade_in_goods',
  '/union_state_investments',
  '/union_state_industrial',
  '/union_state_tonality',
];

const App = () => {
  const [isNotSupportedBrowser, setIsNotSupportedBrowser] = useState<boolean>(false);
  const [isMainDir, setIsMainDir] = useState<boolean>(true);
  const [currentCountry, setCurrentCountry] = useState('EAEU');
  const [isAuthUser, setIsAuthUser] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [isAuthVAVTUser, setIsAuthVAVTUser] = useState(false);
  const [isAuthPerforming, setIsAuthPerforming] = useState(true);
  const [commonError, setCommonError] = useState();
  const [link, setLink] = useState('');
  const [background, setBackground] = useState({
    img: countries[currentCountry].mainImg,
    bgColor: countries[currentCountry].bgColor,
    bgGradientColor: countries[currentCountry].bgGradientColor,
    loading: false,
  });
  // const [isAuthUserVavt, setIsAuthUserVavt] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    other: '',
  });
  const { pathname } = useLocation();

  const memoContext = useMemo(
    () => ({
      isMainDir,
      setIsMainDir,
      background,
      setBackground,
    }),
    [isMainDir, setIsMainDir, background, setBackground]
  );

  useEffect(() => {
    if (NON_MAIN_DIR_PATHS.some((dirPath) => dirPath === pathname)) {
      setIsMainDir(false);
    }
  }, []);

  useEffect(() => {
    setBackground({ ...background, loading: true });

    const timer = setTimeout(() => {
      setBackground({
        img: countries[currentCountry].mainImg,
        bgColor: countries[currentCountry].bgColor,
        bgGradientColor: countries[currentCountry].bgGradientColor,
        loading: false,
      });
    }, 600);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry]);

  useEffect(() => {
    const browserVersion = () => {
      const ua = navigator.userAgent;
      let tem;
      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return `IE ${tem[1] || ''}`;
      }
      if (M[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      // eslint-disable-next-line no-cond-assign
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(' ');
    };
    const browserInfo = {
      browser: browserVersion().split(' ')[0],
      version: browserVersion().split(' ')[1],
    };
    setIsNotSupportedBrowser(
      !minSupportedVersions[browserInfo.browser] ||
        minSupportedVersions[browserInfo.browser] > browserInfo.version
    );
  }, []);

  useEffect(() => {
    setBackground({ ...background, loading: true });

    const timer = setTimeout(() => {
      setBackground({
        img: countries[currentCountry].mainImg,
        bgColor: countries[currentCountry].bgColor,
        bgGradientColor: countries[currentCountry].bgGradientColor,
        loading: false,
      });
    }, 600);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry]);

  useEffect(() => {
    if (!isNotSupportedBrowser) {
      const currentRouters = getRoutersConfig(process.env.REACT_APP_ROUTERS);
      const Title = currentRouters.filter((item) => {
        return item.path === pathname;
      })[0];
      if (Title?.documentTitle) {
        document.title = Title.documentTitle;
      }
    } else {
      document.title = 'Обновите версию браузера';
    }
  }, [isNotSupportedBrowser, pathname]);

  useEffect(() => {
    if (NON_MAIN_DIR_PATHS.some((path) => path === pathname)) {
      setIsMainDir(false);
    }
  }, []);

  if (isNotSupportedBrowser) {
    return <IsNotSupportedBrowser />;
  }

  useEffect(() => {
    const isAuth = async () => {
      await axios
        .request({
          method: 'GET',
          baseURL: ADMIN_URL,
          url: '/auth/is_authenticated',
        })
        .then((resp) => {
          setIsAuthUser(resp.status === 200 && resp.data.isAuthenticated);
        });
    };
    if (process.env.REACT_APP_ROUTERS === 'full_routers' && process.env.REACT_APP_URL === 'prod')
      isAuth();
  }, []);

  useEffect(() => {
    const isAuthVavt = async () => {
      setIsLoading(true);

      try {
        // eslint-disable-next-line
        const { data, status } = await axios.get(
          'https://integration.vavt.ru/auth/api/v1/check_access',
          // 'http://localhost:7654/check_access',
          {
            withCredentials: true,
          }
        );

        if (status === 200 && data.access_is_allowed) {
          setIsAuthVAVTUser(true);
          setIsAuthPerforming(false);
        }

        if (!data.access_is_allowed) {
          const { data: dataLink } = await axios.get(
            'https://integration.vavt.ru/auth/api/v1/get_auth_link'
            // 'http://localhost:7654/get_auth_link'
          );
          setLink(dataLink.auth_link);
          setIsAuthPerforming(false);
        }

        setIsLoading(false);
      } catch (error: any) {
        setErrorMessage({ ...errorMessage, other: 'Что-то пошло не так, повторите попытку позже' });
        setIsLoading(false);
        setIsAuthPerforming(false);
      }

      // ------- для теста на локале, нежно собрать докер и использовать код ниже
      // try {
      //   const { data } = await axios.get('http://localhost:7654/check_access');
      //   setIsAuthUserVavt(true);
      //   setIsLoading(false);
      // } catch (e: any) {
      //   const { data } = await axios.get('http://localhost:7654/get_auth_link');
      //   setLink(data.auth_link);
      //   setIsLoading(false);
      // }
      //----------------------
    };
    if (process.env.REACT_APP_ROUTERS === 'full_routers' && process.env.REACT_APP_URL === 'prod')
      isAuthVavt();
  }, []);

  return (
    <AuthUser.Provider
      value={{
        isAuthUser,
        setIsAuthUser,
        openLogin,
        setOpenLogin,
        isAuthVAVTUser,
        setIsAuthVAVTUser,
        commonError,
        setCommonError,
        link,
        setLink,
        otherError: errorMessage.other,
        isAuthPerforming,
        setIsAuthPerforming,
      }}
    >
      <CountryContext.Provider value={{ currentCountry, setCurrentCountry }}>
        <MainContext.Provider value={memoContext}>
          <AppLoader isLoading={isLoading}>
            <ScrollToTop />
            <Routers />
          </AppLoader>
        </MainContext.Provider>
      </CountryContext.Provider>
    </AuthUser.Provider>
  );
};

export default App;
