const obstaclesToIntegration = {
  barriersDomainStrQC: {
    method: 'GET',
    url: 'EAEU/barriers/domain_structure/quantity_current/',
  },
  barriersTypeStrRegion: {
    method: 'GET',
    url: 'EAEU/barriers/type_structure/quantity_by_region_current/',
  },
  barriersTypeStrQC: {
    method: 'GET',
    url: 'EAEU/barriers/type_structure/quantity_current/',
  },
};

export default obstaclesToIntegration;
