import { RouteConfig } from 'configs/types';
import AboutProjectUnionState from 'pages/UnionState/FullPage/AboutProjectUnionState';
import TradeInServicesUnionState from 'pages/UnionState/FullPage/TradeInServicesUnionState';
import MacroeconomicIndicatorsUnionState from 'pages/UnionState/FullPage/MacroeconomicIndicatorsUnionState';
import EconomicEffectsProgramsUnionState from 'pages/UnionState/FullPage/EconomicEffectsProgramsUnionState';
import TradeInGoodsUnionState from 'pages/UnionState/FullPage/TradeInGoodsUnionState';
// import InvestmentUnionState from 'pages/UnionState/FullPage/InvestmentsUnionState';
// import IndustryUnionState from 'pages/UnionState/FullPage/IndustryUnionState';
import RB from 'pages/TonalityPages/RB';

// ICONS
import { ReactComponent as AboutProjectIcon } from 'assets/icons/nav-about.svg';
import { ReactComponent as EconomicEffectsProgramsIcon } from 'assets/icons/economic-effects-programs.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/dollar.svg';
import { ReactComponent as TradeGoodsIcon } from 'assets/icons/nav-trade-goods.svg';

import { ReactComponent as TradeServicesIcon } from 'assets/icons/nav-trade-services.svg';
import { ReactComponent as InvestmentIcon } from 'assets/icons/nav-investment.svg';
// import { ReactComponent as IndustryIcon } from 'assets/icons/industry.svg';

/*
    > path - путь для роутинга;
    > title - заголовок ссылки в сайдбаре;
    > search_title - заголовок по которому осуществляется поиск в компоненте SearchBar. 
        Если search_title отсутствует, поиск осуществляется по полю title
    > mainDir - путь родительских вкладок. Записывается в виде: dir1/dir2/dir3/;
    > component - компонент страницы;
    > items - массив объектов оглавления раздела:
        >> path - ссылка соответствующая id элемента (заголовка, подзаголовка) на странице;
        >> title - заголовок ссылки.
        >> nested_items - вложенные разделы элемента оглавления (объекта) items;
*/

export const SIDE_PROJECT_FULL_ROUTERS: RouteConfig[] = [
  {
    path: '/about_union_state',
    title: 'О Союзном государстве',
    icon: AboutProjectIcon,
    mainDir: '/',
    documentTitle: 'О проекте',
    component: AboutProjectUnionState,
  },
  {
    path: '/union_state_economic_effects_programs',
    title: 'Экономические эффекты и ход реализации Союзных программ',
    icon: EconomicEffectsProgramsIcon,
    mainDir: '/',
    documentTitle: 'Союзное государство - Экономические эффекты',
    component: EconomicEffectsProgramsUnionState,
  },
  {
    path: '/union_state_macroeconomic_indicators',
    title: 'Макроэкономические показатели',
    icon: DollarIcon,
    mainDir: '/',
    documentTitle: 'Союзное государство - Макроэкономические показатели',
    component: MacroeconomicIndicatorsUnionState,
  },
  {
    path: '/union_state_trade_in_goods',
    title: 'Торговля товарами',
    icon: TradeGoodsIcon,
    mainDir: '/',
    documentTitle: 'Союзное государство - Торговля товарами',
    component: TradeInGoodsUnionState,
  },
  {
    path: '/union_state_tonality',
    title: 'Тональность новостей',
    icon: InvestmentIcon,
    mainDir: '/',
    search_title: 'Тональность новостей Союзное государство рб и рф',
    documentTitle: 'Союзное государство рб и рф',
    component: RB,
  },
  {
    path: '/union_state_trade_in_services',
    title: 'Торговля услугами',
    icon: TradeServicesIcon,
    mainDir: '/',
    documentTitle: 'Союзное государство - Торговля услугами',
    component: TradeInServicesUnionState,
  },
  // {
  //   path: '/union_state_investments',
  //   title: 'Инвестиции',
  //   icon: InvestmentIcon,
  //   mainDir: '/',
  //   documentTitle: 'Союзное государство - Инвестиции',
  //   component: InvestmentUnionState,
  // },
  // {
  //   path: '/union_state_industrial',
  //   title: 'Промышленная кооперация',
  //   icon: IndustryIcon,
  //   mainDir: '/',
  //   documentTitle: 'Союзное государство - Промышленная кооперация',
  //   component: IndustryUnionState,
  // },
];
