import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import styles from './ChartLoader.module.scss';

const ChartLoader: React.FC = () => {
  return (
    <div className={styles.chart_loader_wrapper}>
      <Skeleton animation="wave" variant="rect" height={400} className={styles.chart_loader_rect} />
    </div>
  );
};

export default ChartLoader;
