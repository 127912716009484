import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  chartCPI: [
    {
      title: 'Актуальные',
      chart: 'SplineChartOneY',
      request: { ...API.consumerPriceIndex.cpiQuarter },
      chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
    },
    {
      title: 'Исторические',
      chart: 'SplineChartOneY',
      request: { ...API.consumerPriceIndex.cpiYear },
      chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
    },
  ],
};
