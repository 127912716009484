import EAEUFlagImg from 'assets/img/flag-eeu.png';
import CISFlagImg from 'assets/img/flag-cis.png';
import RussiaFlagImg from 'assets/img/flag-ru.png';
import BelarusFlagImg from 'assets/img/flag-by.png';
import ArmeniaFlagImg from 'assets/img/flag-am.png';
import KyrgyzstanFlagImg from 'assets/img/flag-kg.png';
import KazakhstanFlagImg from 'assets/img/flag-kz.png';

import AzerbaijanFlagImg from 'assets/img/flag-az.png';
import GeorgiaFlagImg from 'assets/img/flag-ge.png';
import MoldovaFlagImg from 'assets/img/flag-md.png';
import TajikistanFlagImg from 'assets/img/flag-tj.png';
import UzbekistanFlagImg from 'assets/img/flag-uz.png';
import UkraineFlagImg from 'assets/img/flag-ua.png';
import TurkmenistanFlagImg from 'assets/img/flag-tu.png';
import AbkhaziaFlagImg from 'assets/img/flag-ab.png';
import SouthOssetiaFlagImg from 'assets/img/flag-so.png';

import EAEUMainImg from 'assets/img/EAEULogo.png';
import CISMainImg from 'assets/img/cis.png';
import COVID19MainImg from 'assets/img/virus.png';
import RussiaMainImg from 'assets/img/ru.png';
import BelarusMainImg from 'assets/img/by.png';
import ArmeniaMainImg from 'assets/img/am.png';
import KyrgyzstanMainImg from 'assets/img/kg.png';
import KazakhstanMainImg from 'assets/img/kz.png';

import AzerbaijanMainImg from 'assets/img/az.png';
import GeorgiaMainImg from 'assets/img/ge.png';
import MoldovaMainImg from 'assets/img/md.png';
import TajikistanMainImg from 'assets/img/tj.png';
import UzbekistanMainImg from 'assets/img/uz.png';
import UkraineMainImg from 'assets/img/ua.png';
import TurkmenistanMainImg from 'assets/img/tu.png';
import AbkhaziaMainImg from 'assets/img/ab.png';
import SouthOssetiaMainImg from 'assets/img/so.png';

export const countries = {
  EAEU: {
    name: 'EAЭС',
    code: 'EAEU',
    flag: EAEUFlagImg,
    mainImg: EAEUMainImg,
    bgColor: '#eef1fa',
    bgGradientColor: '#dae0ff',
  },
  CIS: {
    name: 'СНГ',
    code: 'CIS',
    flag: CISFlagImg,
    mainImg: CISMainImg,
    bgColor: '#eef1fa',
    bgGradientColor: '#b3ddff',
  },
  COVID19: {
    name: 'Сovid-19',
    code: 'COVID19',
    mainImg: COVID19MainImg,
    bgColor: '#eef1fa',
    bgGradientColor: '#dae0ff',
  },
  Russia: {
    name: 'Россия',
    code: 'Russia',
    flag: RussiaFlagImg,
    mainImg: RussiaMainImg,
    bgColor: '#fafafa',
    bgGradientColor: '#dce1f1',
  },
  Belarus: {
    name: 'Беларусь',
    code: 'Belarus',
    flag: BelarusFlagImg,
    mainImg: BelarusMainImg,
    bgColor: '#fefaff',
    bgGradientColor: '#eedaf3',
  },
  Armenia: {
    name: 'Армения',
    code: 'Armenia',
    flag: ArmeniaFlagImg,
    mainImg: ArmeniaMainImg,
    bgColor: '#fffcf7',
    bgGradientColor: '#ecedc9',
  },
  Kyrgyzstan: {
    name: 'Кыргызстан',
    code: 'Kyrgyzstan',
    flag: KyrgyzstanFlagImg,
    mainImg: KyrgyzstanMainImg,
    bgColor: '#f7f9fd',
    bgGradientColor: '#e1e6fe',
  },
  Kazakhstan: {
    name: 'Казахстан',
    code: 'Kazakhstan',
    flag: KazakhstanFlagImg,
    mainImg: KazakhstanMainImg,
    bgColor: '#f5feff',
    bgGradientColor: '#beecfb',
  },

  Azerbaijan: {
    code: 'Azerbaijan',
    name: 'Азербайджан',
    bgColor: '#FAFFF8',
    bgGradientColor: '#CFFFBE',
    flag: AzerbaijanFlagImg,
    mainImg: AzerbaijanMainImg,
  },
  Georgia: {
    code: 'Georgia',
    name: 'Грузия',
    bgColor: '#FFF9F9',
    bgGradientColor: '#FEF3F4',
    flag: GeorgiaFlagImg,
    mainImg: GeorgiaMainImg,
  },
  Moldova: {
    code: 'Moldova',
    name: 'Молдова',
    bgColor: '#f5feff',
    bgGradientColor: '#beecfb',
    flag: MoldovaFlagImg,
    mainImg: MoldovaMainImg,
  },
  Tajikistan: {
    code: 'Tajikistan',
    name: 'Таджикистан',
    bgColor: '#FFF9F9',
    bgGradientColor: '#FFA9A9',
    flag: TajikistanFlagImg,
    mainImg: TajikistanMainImg,
  },
  Uzbekistan: {
    code: 'Uzbekistan',
    name: 'Узбекистан',
    bgColor: '#FBFFFB',
    bgGradientColor: '#C0FFC0',
    flag: UzbekistanFlagImg,
    mainImg: UzbekistanMainImg,
  },
  Ukraine: {
    code: 'Ukraine',
    name: 'Украина',
    bgColor: '#FFFEF6',
    bgGradientColor: '#FDFFA1',
    flag: UkraineFlagImg,
    mainImg: UkraineMainImg,
  },
  Turkmenistan: {
    code: 'Turkmenistan',
    name: 'Туркменистан',
    bgColor: '#FBFFFB',
    bgGradientColor: '#C0FFC0',
    flag: TurkmenistanFlagImg,
    mainImg: TurkmenistanMainImg,
  },
  Abkhazia: {
    code: 'Abkhazia',
    name: 'Абхазия',
    bgColor: '#fafafa',
    bgGradientColor: '#dce1f1',
    flag: AbkhaziaFlagImg,
    mainImg: AbkhaziaMainImg,
  },
  SouthOssetia: {
    code: 'SouthOssetia',
    name: 'Южная Осетия',
    bgColor: '#fffcf7',
    bgGradientColor: '#ecedc9',
    flag: SouthOssetiaFlagImg,
    mainImg: SouthOssetiaMainImg,
  },
};
