import React, { useContext } from 'react';
import SidebarContext from 'context/SidebarContext';
import classnames from 'classnames';
import styles from './Backdrop.module.scss';

const Backdrop: React.FC = () => {
  const { showSidebar, setShowSidebar } = useContext(SidebarContext);

  return (
    <div
      className={classnames(styles.backdrop, { [styles.backdropShow]: showSidebar })}
      onClick={() => setShowSidebar(false)}
      onKeyPress={() => setShowSidebar(false)}
      role="button"
      aria-label="backdrop"
      tabIndex={0}
    />
  );
};

export default Backdrop;
