import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  ServicesTradeTopFive: [
    {
      title: 'Россия',
      filter: 'Экспорт',
      request: { ...API.unionState.ServicesTradeTopFiveExportRus },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Россия',
      filter: 'Импорт',
      request: { ...API.unionState.ServicesTradeTopFiveImportRus },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Беларусь',
      filter: 'Экспорт',
      request: { ...API.unionState.ServicesTradeTopFiveExportBel },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Беларусь',
      filter: 'Импорт',
      request: { ...API.unionState.ServicesTradeTopFiveImportBel },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
  ],

  ServicesTradeYearly: [
    {
      title: 'Экспорт',
      request: { ...API.unionState.ServicesTradeExport },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Импорт',
      request: { ...API.unionState.ServicesTradeImport },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Оборот',
      request: { ...API.unionState.ServicesTradeTurnover },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
  ],

  ServicesTradeYearlyWithWorld: [
    {
      title: 'Россия',
      filter: 'Экспорт',
      request: { ...API.unionState.ServicesTradeExportWorldRus },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Россия',
      filter: 'Импорт',
      request: { ...API.unionState.ServicesTradeImportWorldRus },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Россия',
      filter: 'Оборот',
      request: { ...API.unionState.ServicesTradeTurnoverWorldRus },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Беларусь',
      filter: 'Экспорт',
      request: { ...API.unionState.ServicesTradeExportWorldBel },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Беларусь',
      filter: 'Импорт',
      request: { ...API.unionState.ServicesTradeImportWorldBel },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Беларусь',
      filter: 'Оборот',
      request: { ...API.unionState.ServicesTradeTurnoverWorldBel },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
  ],

  GDPQuarterlyUS: [
    {
      title: 'Беларусь',
      request: { ...API.unionState.GDPByQuarterUSBel },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Россия',
      request: { ...API.unionState.GDPByQuarterUSRus },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  GDPYearlyUS: [
    {
      title: 'Беларусь',
      request: { ...API.unionState.GDPByYearUSBel },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Россия',
      request: { ...API.unionState.GDPByYearUSRus },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  AgricultureQuarterlyUS: [
    {
      title: 'Беларусь',
      request: { ...API.unionState.AgricultureQuarterlyBel },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Россия',
      request: { ...API.unionState.AgricultureQuarterlyRus },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  AgricultureYearlyUS: [
    {
      title: 'Беларусь',
      request: { ...API.unionState.AgricultureYearlyBel },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Россия',
      request: { ...API.unionState.AgricultureYearlyRus },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  IndustrialQuarterlyUS: [
    {
      title: 'Беларусь',
      request: { ...API.unionState.IndustrialByQuarterUSBel },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Россия',
      request: { ...API.unionState.IndustrialByQuarterUSRus },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  IndustrialYearlyUS: [
    {
      title: 'Беларусь',
      request: { ...API.unionState.IndustrialByYearUSBel },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Россия',
      request: { ...API.unionState.IndustrialByYearUSRus },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  EconomicEffectsUS: [
    {
      title: '',
      chart: 'HBarChart',
      request: { ...API.unionState.EconomicEffectsUS },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
  ],
  QualityAssessmentUS: [
    {
      title: '',
      chart: 'HBarChart',
      request: { ...API.unionState.QualityAssessmentUS },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
  ],

  CommoditiesMonthlyBelstat: [
    {
      title: 'Экспорт',
      request: { ...API.unionState.CommoditiesTradeExport },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Импорт',
      request: { ...API.unionState.CommoditiesTradeImport },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Оборот',
      request: { ...API.unionState.CommoditiesTradeTurnover },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  CommoditiesTradeYearlyUS: [
    {
      title: 'Экспорт',
      request: { ...API.unionState.CommoditiesTradeYearlyExport },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Импорт',
      request: { ...API.unionState.CommoditiesTradeYearlyImport },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Оборот',
      request: { ...API.unionState.CommoditiesTradeYearlyTurnover },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  CommoditiesУearlyСomplementarityRusBel: [
    {
      title: '',
      chart: 'SplineChartOneY',
      request: { ...API.unionState.CommoditiesУearlyСomplementarityRusBel },
      chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
    },
  ],

  CommoditiesTradeYearlyGroupsExportUS: [
    {
      title: 'Доли',
      chart: 'HBarChart',
      request: { ...API.unionState.CommoditiesTradeYearlyGroupsExportVolume },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
    {
      title: 'Объем',
      chart: 'HBarChart',
      request: { ...API.unionState.CommoditiesTradeYearlyGroupsExportShare },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
  ],

  CommoditiesTradeYearlyGroupsImportUS: [
    {
      title: 'Доли',
      chart: 'HBarChart',
      request: { ...API.unionState.CommoditiesTradeYearlyGroupsImportVolume },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
    {
      title: 'Объем',
      chart: 'HBarChart',
      request: { ...API.unionState.CommoditiesTradeYearlyGroupsImportShare },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
  ],

  ServicesTradeYearlyGroupsUS: [
    {
      title: 'Экспорт',
      filter: 'Доли',
      chart: 'HBarChart',
      request: { ...API.unionState.ServicesTradeYearlyGroupsExportVolume },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
    {
      title: 'Экспорт',
      filter: 'Объем',
      chart: 'HBarChart',
      request: { ...API.unionState.ServicesTradeYearlyGroupsExportShare },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
    {
      title: 'Импорт',
      filter: 'Доли',
      chart: 'HBarChart',
      request: { ...API.unionState.ServicesTradeYearlyGroupsImportVolume },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
    {
      title: 'Импорт',
      filter: 'Объем',
      chart: 'HBarChart',
      request: { ...API.unionState.ServicesTradeYearlyGroupsImportShare },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
  ],

  Top5PartnersExportUS: [
    {
      title: 'Беларусь',
      request: { ...API.unionState.Top5PartnersExportBel },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset3 },
    },
    {
      title: 'Россия',
      request: { ...API.unionState.Top5PartnersExportRus },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset3 },
    },
  ],

  Top5PartnersImportUS: [
    {
      title: 'Беларусь',
      request: { ...API.unionState.Top5PartnersImportBel },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset3 },
    },
    {
      title: 'Россия',
      request: { ...API.unionState.Top5PartnersImportRus },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset3 },
    },
  ],

  InvestmentsYearlyBelstat: [
    {
      title: 'В страну',
      request: { ...API.unionState.InvestmentsYearlyBelstatIn },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset2 },
    },
    {
      title: 'Из страны',
      request: { ...API.unionState.InvestmentsYearlyBelstatOut },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset2 },
    },
  ],

  InvestmentsYearlyCbr: [
    {
      title: 'В страну',
      request: { ...API.unionState.InvestmentsYearlyCbrTo },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset2 },
    },
    {
      title: 'Из страны',
      request: { ...API.unionState.InvestmentsYearlyCbrFrom },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset2 },
    },
  ],

  InvestmentsYearlyCbrWithBel: [
    {
      title: 'В страну',
      request: { ...API.unionState.InvestmentsYearlyCbrWithBelTo },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset2 },
    },
    {
      title: 'Из страны',
      request: { ...API.unionState.InvestmentsYearlyCbrWithBelFrom },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset2 },
    },
  ],

  ExportPotential: {
    CIS: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialCIS },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
    EAEU: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialEAEU },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
    Azerbaijan: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialAze },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
    Armenia: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialArm },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
    Belarus: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialBel },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
    Tajikistan: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialTaj },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
    Kazakhstan: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialKaz },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
    Kyrgyzstan: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialKyr },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
    Moldova: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialMol },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
    Turkmenistan: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialTur },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
    Uzbekistan: [
      {
        title: '',
        chart: 'HBarChart',
        request: { ...API.unionState.ExportPotentialUzb },
        chartOptions: { ...ChartOptions.HBarChart.preset11 },
      },
    ],
  },
};
