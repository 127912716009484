import React, { ReactNode, useContext } from 'react';
import AnimateHeight from 'react-animate-height';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { Icon, IconType } from 'components/Icon';

import { ReactComponent as ArrowForwardIcon } from 'assets/icons/caret-right.svg';

import SidebarContext from 'context/SidebarContext';
import styles from '../Sidebar.module.scss';

const SidebarNav: React.FC<{
  locationIncludes: string;
  title: string;
  iconType?: IconType;
  createSidebarSubsections: ReactNode;
  checkboxChecked: { [key: string]: boolean };
  setCheckboxChecked: any;
}> = ({
  locationIncludes,
  title,
  createSidebarSubsections,
  checkboxChecked,
  setCheckboxChecked,
  iconType,
}) => {
  const { showSidebar, setShowSidebar } = useContext(SidebarContext);
  const location = useLocation();
  const checkboxFunc = () => {
    const obj = checkboxChecked;
    setShowSidebar(true);
    obj[locationIncludes] = !obj[locationIncludes];
    setCheckboxChecked({ ...obj });
  };

  return (
    <div
      className={`${
        location.pathname.includes(locationIncludes)
          ? `${styles.sidebar_nav_item} ${styles.sidebar_nav_item_active} ${styles.sidebar_nav_item_nested}`
          : `${styles.sidebar_nav_item} ${styles.sidebar_nav_item_nested}`
      }`}
    >
      <div
        className={`${
          checkboxChecked[locationIncludes]
            ? `${styles.sidebar_nav_link} ${styles.sidebar_nav_link_open}`
            : `${styles.sidebar_nav_link}`
        }`}
        onClick={checkboxFunc}
        onKeyPress={checkboxFunc}
        role="button"
        tabIndex={0}
      >
        {iconType && <Icon iconType={iconType as any} className={styles.sidebar_item_icon} />}
        <div className={styles.sidebar_item_content}>
          {title}
          <ArrowForwardIcon className={classnames(styles.sidebar_nav_icon, styles.md_icon)} />
        </div>
      </div>
      {/* @ts-ignore */}
      <AnimateHeight
        duration={500}
        height={showSidebar && checkboxChecked[locationIncludes] ? 'auto' : 0}
      >
        <nav className={styles.sidebar_nav} aria-label={title}>
          <div className={classnames(styles.sidebar_nav_list, styles.sidebar_nav_list_left_border)}>
            {createSidebarSubsections}
          </div>
        </nav>
      </AnimateHeight>
    </div>
  );
};

export default SidebarNav;
