const covid = {
  covidCases: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases' },
  },
  covidVaccinations: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations' },
  },
  covidCasesArm: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Армения' },
  },
  covidVaccinationsArm: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Армения' },
  },
  covidCasesBel: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Беларусь' },
  },
  covidVaccinationsBel: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Беларусь' },
  },
  covidCasesKaz: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Казахстан' },
  },
  covidVaccinationsKaz: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Казахстан' },
  },
  covidCasesKyr: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Кыргызстан' },
  },
  covidVaccinationsKyr: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Кыргызстан' },
  },
  covidCasesRus: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Россия' },
  },
  covidVaccinationsRus: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Россия' },
  },
  covidCasesAze: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Азербайджан' },
  },
  covidVaccinationsAze: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Азербайджан' },
  },
  covidCasesMol: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Молдова' },
  },
  covidVaccinationsMol: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Молдова' },
  },
  covidCasesTaj: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Таджикистан' },
  },
  covidVaccinationsTaj: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Таджикистан' },
  },
  covidCasesUzb: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Узбекистан' },
  },
  covidVaccinationsUzb: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Узбекистан' },
  },
  covidCasesUkr: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Украина' },
  },
  covidVaccinationsUkr: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Украина' },
  },
  covidCasesGeo: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'cases', region: 'Грузия' },
  },
  covidVaccinationsGeo: {
    method: 'GET',
    url: 'CIS/COVID/',
    params: { variable: 'vaccinations', region: 'Грузия' },
  },
};

export default covid;
