import { getMainRouters, getSideProjectRouters, RouterKey } from '../routers';

declare const process: {
  env: {
    REACT_APP_ROUTERS: RouterKey;
  };
};

export const sidebarContentOptions = (isMainDir: boolean) => {
  const obj = {};
  const currentRouters = isMainDir
    ? getMainRouters(process.env.REACT_APP_ROUTERS)
    : getSideProjectRouters(process.env.REACT_APP_ROUTERS);
  for (let i = 0; i < currentRouters.length; i++) {
    if (currentRouters[i].items) {
      let path = currentRouters[i].path.slice(1).split('/');
      let current = obj;
      while (path.length > 1) {
        const [head, ...tail] = path;
        path = tail;
        if (current[head] === undefined) {
          current[head] = {};
        }
        current = current[head];
      }
      current[path[0]] = {
        path: currentRouters[i].path,
        title: currentRouters[i].title,
        items: currentRouters[i].items,
      };
    }
  }
  return obj;
};
