import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  MonetaryBankRate: {
    Belarus: [
      {
        title: '',
        request: { ...API.consumerPriceIndex.monetaryBankRate },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
  },
  CPI: {
    Russia: [
      {
        title: 'Актуальные',
        request: { ...API.consumerPriceIndex.cpiQuarterRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.consumerPriceIndex.cpiYearRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Belarus: [
      {
        title: 'Актуальные',
        request: { ...API.consumerPriceIndex.cpiQuarterBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.consumerPriceIndex.cpiYearBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Armenia: [
      {
        title: 'Актуальные',
        request: { ...API.consumerPriceIndex.cpiQuarterArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.consumerPriceIndex.cpiYearArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Актуальные',
        request: { ...API.consumerPriceIndex.cpiQuarterKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.consumerPriceIndex.cpiYearKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Актуальные',
        request: { ...API.consumerPriceIndex.cpiQuarterKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.consumerPriceIndex.cpiYearKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Moldova: [
      {
        title: 'Актуальные',
        request: { ...API.consumerPriceIndex.cpiQuarterMol },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.consumerPriceIndex.cpiYearMol },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
  },
};
