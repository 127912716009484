import { useContext } from 'react';
import Context from 'context/CountryContext';
import CountryButtons from 'components/CountryButtons';
import DynamicChart from 'components/DynamicChart';
import { countries } from 'configs/countries';
import { ChartConfigs } from 'configs/pages/SelectedCountries/KeyMacroIndicators';
import styles from '../Page.module.scss';

const countrySet = ['Russia', 'Belarus', 'Armenia', 'Kyrgyzstan', 'Kazakhstan'];

const countrySetChartGDPGrowth = [
  'Russia',
  'Belarus',
  'Armenia',
  'Kyrgyzstan',
  'Kazakhstan',
  'Azerbaijan',
  'Moldova',
  'Tajikistan',
  'Uzbekistan',
  'Tajikistan',
  'Uzbekistan',
  'Turkmenistan',
  'Ukraine',
];

const KeyMacroIndicatorsSC = () => {
  const { currentCountry } = useContext(Context);

  return (
    <>
      <h1>Ключевые макроэкономические показатели</h1>
      <CountryButtons
        countries={[
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
          countries.Azerbaijan,
          countries.Georgia,
          countries.Moldova,
          countries.Tajikistan,
          countries.Turkmenistan,
          countries.Uzbekistan,
          countries.Ukraine,
          countries.Abkhazia,
          countries.SouthOssetia,
        ]}
      />

      {(currentCountry === 'Moldova' ||
        currentCountry === 'Tajikistan' ||
        currentCountry === 'Uzbekistan' ||
        currentCountry === 'Ukraine' ||
        currentCountry === 'Georgia') && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.GDPYearlyQuarterly[currentCountry]} />
        </div>
      )}

      {(countrySet.includes(currentCountry) || currentCountry === 'Azerbaijan') && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.GDPdynamics[currentCountry]} />
        </div>
      )}

      {(countrySet.includes(currentCountry) || currentCountry === 'Moldova') && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.ChartGDPbyAct[currentCountry]} />
        </div>
      )}

      {countrySetChartGDPGrowth.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.GDPGrowth[currentCountry]} />
        </div>
      )}

      {(countrySet.includes(currentCountry) ||
        currentCountry === 'Moldova' ||
        currentCountry === 'Azerbaijan') && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.ProductionIndustrial[currentCountry]} />
        </div>
      )}

      {(currentCountry === 'Tajikistan' ||
        currentCountry === 'Uzbekistan' ||
        currentCountry === 'Ukraine') && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.IndustrialQuarterYear[currentCountry]} />
        </div>
      )}

      {countrySet.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.AgricultureQuarterlyYearly[currentCountry]} />
        </div>
      )}

      {/* {currentCountry !== 'Uzbekistan' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.GNIPrices[currentCountry]} />
        </div>
      )} */}
      {/* Графики Молдова */}
      {/* {currentCountry === 'Moldova' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.GDPYearlyMol} />
        </div>
      )} */}

      {/* Графики Грузия */}

      {currentCountry === 'Georgia' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.ProductionIndustrialGeo} />
        </div>
      )}

      {/* Графики Абхазия */}

      {currentCountry === 'Abkhazia' && (
        <>
          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.YearlyGdpAbh} />
          </div>

          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.YearlyAgricultureAbh} />
          </div>

          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.YearlyIndustrialAbh} />
          </div>
        </>
      )}

      {/* Графики Южная Осетия */}

      {currentCountry === 'SouthOssetia' && (
        <>
          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.YearlyGdpSO} />
          </div>

          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.YearlyAgricultureSO} />
          </div>

          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.YearlyIndustrialSO} />
          </div>
        </>
      )}
    </>
  );
};

export default KeyMacroIndicatorsSC;
