import { useState, useContext } from 'react';
import SidebarContext from 'context/SidebarContext';
import AuthUser from 'context/AuthUser';
import Sidebar from 'components/Sidebar/Sidebar';
import Backdrop from 'components/Sidebar/Backdrop';
import Header from 'components/Header';
import Modal from 'ui/Modal/Modal';
import Login from './Modal/Login';
import Logout from './Modal/Logout';

const SidebarAndHeaderWrapper = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const { isAuthUser, openLogin, setOpenLogin } = useContext(AuthUser);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SidebarContext.Provider value={{ showSidebar, setShowSidebar }}>
      <Header openModal={() => setOpenLogin(true)} />
      <Sidebar />
      <Backdrop />
      <Modal isModalVisible={openLogin} onClose={() => setOpenLogin(false)}>
        {!isAuthUser ? <Login /> : <Logout />}
      </Modal>
    </SidebarContext.Provider>
  );
};

export default SidebarAndHeaderWrapper;
