import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/UnionState/CommonUnionState';
import styles from '../../Page.module.scss';

// import styles from './CommonStylesUnionState.module.css';

const MacroeconomicIndicatorsUnionState: React.FC = () => {
  const itemBlockStyle: any = {
    marginBottom: '50px',
    padding: '30px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08)',
  };

  return (
    <div>
      <h1>Макроэкономические показатели</h1>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.GDPYearlyUS} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.GDPQuarterlyUS} />
      </div>

      <div style={itemBlockStyle}>
        <p>
          Текущий год был сложным для экономик Союзного государства, особенно для Беларуси. По
          предварительным данным Белстата, объем валового внутреннего продукта в стране за 11
          месяцев 2022 г. сократился на 4,7% относительно аналогичного периода предыдущего года.
          Согласно мнению ряда экспертных организаций, с учетом сложившейся динамики реалистичным по
          итогам 2022 г. представляется спад республиканского ВВП на 4,5–5%. В осеннем (октябрьском)
          прогнозе МВФ фигурирует показатель в -7%. Более реалистичен прогноз от ЕАБР: сокращение
          реального ВВП Республики Беларусь на 4,6% в 2022 г. и рост на 0,3% в 2023 г.
        </p>
        <p>
          России, несмотря на жесткое санкционное давление недружественных стран, удалось смягчить
          его последствия. По последним оценкам Минэкономразвития РФ, российский ВВП в 2022 г.
          сократится на 2,9%, а в 2023 году сокращение составит 0,8%, хотя несколько месяцев назад
          (в конце лета) ожидалось снижение на 4,2% и 2,7%, соответственно.
        </p>
        <p>
          В 2023 г. эксперты МВФ прогнозируют в Беларуси пусть совсем небольшой – всего на 0,2%, –
          но положительный рост ВВП, а в России – дальнейшее его сокращение на 2,3%.
        </p>
      </div>

      {/* <div style={itemBlockStyle}>
        <p style={{ fontWeight: `bold` }}>Структура ВВП (графики)</p>
        <p>1. Годовая динамика</p>
        <p>2. За последний доступный квартал (накопительным итогом)</p>
      </div>

      <div style={itemBlockStyle}>
        <p>Краткий комментарий относительно структурных изменений и их причин</p>
      </div>

      <div style={itemBlockStyle}>
        <p style={{ fontWeight: `bold` }}>Динамика промышленного производства (графики)</p>
        <p>1. Годовая динамика</p>
        <p>2. За последний доступный квартал (накопительным итогом)</p>
      </div> */}

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.IndustrialYearlyUS} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.IndustrialQuarterlyUS} />
      </div>

      <div style={itemBlockStyle}>
        <p>
          Сокращение промышленного производства в Беларуси по итогам 3 квартала 2022 г. составило
          6,1%. Наибольший спад наблюдается в обрабатывающей промышленности (-6,8%). Остаются слабо
          загружены белорусские нефтеперерабатывающие заводы (объемы производства сократились в 2
          раза), их продукция была ориентирована на рынки ЕС, Великобритании и Украины. Следом за
          сокращение обрабатывающей промышленности снижается выработка электроэнергии, газа, воды –
          на 1,6% за январь-сентябрь.
        </p>
        <p>
          За аналогичный период промышленное производство в России увеличилось на 0,4%. Основной
          негативный вклад в динамику вносят сокращение производства продукции машиностроения (-7,1%
          г/г), легкая промышленность (-3,7%), деревообрабатывающий комплекс (-1,5%) и производство
          кокса и нефтепродуктов (-0,6%).
        </p>
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.AgricultureQuarterlyUS} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.AgricultureYearlyUS} />
      </div>
    </div>
  );
};

export default MacroeconomicIndicatorsUnionState;
