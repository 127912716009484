import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  EconomicGrowth: {
    EAEU: [
      {
        title: '',
        chart: 'MBarLineChartTwoY',
        request: { ...API.GDP.growthEAEU },
        chartOptions: { ...ChartOptions.MBarLineChartTwoY.preset3 },
      },
    ],
    Russia: [
      {
        title: '',
        chart: 'MBarLineChartTwoY',
        request: { ...API.GDP.growthRus },
        chartOptions: { ...ChartOptions.MBarLineChartTwoY.preset3 },
      },
    ],
    Belarus: [
      {
        title: '',
        chart: 'MBarLineChartTwoY',
        request: { ...API.GDP.growthBel },
        chartOptions: { ...ChartOptions.MBarLineChartTwoY.preset3 },
      },
    ],
    Armenia: [
      {
        title: '',
        chart: 'MBarLineChartTwoY',
        request: { ...API.GDP.growthArm },
        chartOptions: { ...ChartOptions.MBarLineChartTwoY.preset3 },
      },
    ],
    Kyrgyzstan: [
      {
        title: '',
        chart: 'MBarLineChartTwoY',
        request: { ...API.GDP.growthKyr },
        chartOptions: { ...ChartOptions.MBarLineChartTwoY.preset3 },
      },
    ],
    Kazakhstan: [
      {
        title: '',
        chart: 'MBarLineChartTwoY',
        request: { ...API.GDP.growthKaz },
        chartOptions: { ...ChartOptions.MBarLineChartTwoY.preset3 },
      },
    ],
  },
  GrowthMutualTrade: {
    EAEU: [
      {
        title: '',
        chart: 'MBarLineChartTwoY',
        request: { ...API.GDP.mutualTrade },
        chartOptions: { ...ChartOptions.MBarLineChartTwoY.preset2 },
      },
    ],
  },
};
