import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/EAEU/TradeInServices';

import styles from '../Page.module.scss';

const TradeInServicesDynamics: React.FC = () => {
  return (
    <>
      <h1>Торговля услугами</h1>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.DynamicsOfServices} />
      </div>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.ServiceStructure} />
      </div>
      {/* <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.DynamicsOfGeneralServices} />
      </div> */}
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.VolumeTradeServices} />
      </div>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.StructureMutualTradeServices} />
      </div>
    </>
  );
};

export default TradeInServicesDynamics;
