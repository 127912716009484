import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  Barriers: [
    {
      title: 'По типам',
      chart: 'BarChart',
      request: { ...API.obstaclesToIntegration.barriersTypeStrQC },
      chartOptions: { ...ChartOptions.BarChart.preset8 },
    },
    {
      title: 'По странам',
      chart: 'BarChart',
      request: { ...API.obstaclesToIntegration.barriersTypeStrRegion },
      chartOptions: { ...ChartOptions.BarChart.preset8 },
    },
    {
      title: 'По предметной области',
      chart: 'BarChart',
      request: { ...API.obstaclesToIntegration.barriersDomainStrQC },
      chartOptions: { ...ChartOptions.BarChart.preset8 },
    },
  ],
};
