declare const process: {
  env: {
    REACT_APP_URL: string;
  };
};

const BASE_URL = {
  dev: 'https://integration1.vavt.ru/api',
  // dev: 'http://10.8.0.8:8526/api',
  prod: 'https://integration.vavt.ru/api',
  gov: 'https://eaeu.economy.gov.ru/api',
};

export const URL = `${BASE_URL[process.env.REACT_APP_URL]}/charts/`;
export const ADMIN_URL = `${BASE_URL[process.env.REACT_APP_URL]}/admin/`;
