const news = {
  newsEPAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'Восточное партнерство' },
  },

  newsEPArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'Восточное партнерство' },
  },

  newsEPBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'Восточное партнерство' },
  },

  newsEPGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'Восточное партнерство' },
  },

  newsEPKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'Восточное партнерство' },
  },

  newsEPKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'Восточное партнерство' },
  },

  newsEPMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'Восточное партнерство' },
  },

  newsEPTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'Восточное партнерство' },
  },

  newsEPUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'Восточное партнерство' },
  },

  newsEPUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'Восточное партнерство' },
  },
  newsEPUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'Восточное партнерство' },
  },

  newsEAEUAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'ЕАЭС' },
  },

  newsEAEUArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'ЕАЭС' },
  },

  newsEAEUBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'ЕАЭС' },
  },

  newsEAEUGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'ЕАЭС' },
  },

  newsEAEUKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'ЕАЭС' },
  },

  newsEAEUKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'ЕАЭС' },
  },

  newsEAEUMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'ЕАЭС' },
  },

  newsEAEUTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'ЕАЭС' },
  },

  newsEAEUUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'ЕАЭС' },
  },

  newsEAEUUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'ЕАЭС' },
  },
  newsEAEUUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'ЕАЭС' },
  },

  newsEUAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'ЕС' },
  },
  newsEUAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'ЕС' },
  },
  newsEUAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'ЕС' },
  },

  newsEUArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'ЕС' },
  },
  newsEUArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'ЕС' },
  },
  newsEUArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'ЕС' },
  },

  newsEUBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'ЕС' },
  },
  newsEUBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'ЕС' },
  },
  newsEUBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'ЕС' },
  },

  newsEUGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'ЕС' },
  },
  newsEUGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'ЕС' },
  },
  newsEUGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'ЕС' },
  },

  newsEUKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'ЕС' },
  },
  newsEUKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'ЕС' },
  },
  newsEUKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'ЕС' },
  },

  newsEUKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'ЕС' },
  },
  newsEUKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'ЕС' },
  },
  newsEUKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'ЕС' },
  },

  newsEUMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'ЕС' },
  },
  newsEUMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'ЕС' },
  },
  newsEUMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'ЕС' },
  },

  newsEUTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'ЕС' },
  },
  newsEUTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'ЕС' },
  },
  newsEUTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'ЕС' },
  },

  newsEUUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'ЕС' },
  },
  newsEUUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'ЕС' },
  },
  newsEUUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'ЕС' },
  },

  newsEUUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'ЕС' },
  },
  newsEUUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'ЕС' },
  },
  newsEUUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'ЕС' },
  },

  newsChinaAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'Китай' },
  },
  newsChinaAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'Китай' },
  },
  newsChinaAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'Китай' },
  },

  newsChinaArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'Китай' },
  },
  newsChinaArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'Китай' },
  },
  newsChinaArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'Китай' },
  },

  newsChinaBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'Китай' },
  },
  newsChinaBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'Китай' },
  },
  newsChinaBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'Китай' },
  },

  newsChinaGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'Китай' },
  },
  newsChinaGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'Китай' },
  },
  newsChinaGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'Китай' },
  },

  newsChinaKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'Китай' },
  },
  newsChinaKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'Китай' },
  },
  newsChinaKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'Китай' },
  },

  newsChinaKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'Китай' },
  },
  newsChinaKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'Китай' },
  },
  newsChinaKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'Китай' },
  },

  newsChinaMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'Китай' },
  },
  newsChinaMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'Китай' },
  },
  newsChinaMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'Китай' },
  },

  newsChinaTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'Китай' },
  },
  newsChinaTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'Китай' },
  },
  newsChinaTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'Китай' },
  },

  newsChinaUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'Китай' },
  },
  newsChinaUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'Китай' },
  },
  newsChinaUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'Китай' },
  },

  newsChinaUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'Китай' },
  },
  newsChinaUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'Китай' },
  },
  newsChinaUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'Китай' },
  },

  newsNATOAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'НАТО' },
  },
  newsNATOAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'НАТО' },
  },
  newsNATOAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'НАТО' },
  },

  newsNATOArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'НАТО' },
  },
  newsNATOArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'НАТО' },
  },
  newsNATOArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'НАТО' },
  },

  newsNATOBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'НАТО' },
  },
  newsNATOBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'НАТО' },
  },
  newsNATOBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'НАТО' },
  },

  newsNATOGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'НАТО' },
  },
  newsNATOGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'НАТО' },
  },
  newsNATOGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'НАТО' },
  },

  newsNATOKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'НАТО' },
  },
  newsNATOKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'НАТО' },
  },
  newsNATOKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'НАТО' },
  },

  newsNATOKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'НАТО' },
  },
  newsNATOKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'НАТО' },
  },
  newsNATOKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'НАТО' },
  },

  newsNATOMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'НАТО' },
  },
  newsNATOMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'НАТО' },
  },
  newsNATOMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'НАТО' },
  },

  newsNATOTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'НАТО' },
  },
  newsNATOTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'НАТО' },
  },
  newsNATOTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'НАТО' },
  },

  newsNATOUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'НАТО' },
  },
  newsNATOUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'НАТО' },
  },
  newsNATOUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'НАТО' },
  },

  newsNATOUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'НАТО' },
  },
  newsNATOUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'НАТО' },
  },
  newsNATOUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'НАТО' },
  },

  newsCSTOAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'ОДКБ' },
  },

  newsCSTOArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'ОДКБ' },
  },

  newsCSTOBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'ОДКБ' },
  },

  newsCSTOGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'ОДКБ' },
  },

  newsCSTOKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'ОДКБ' },
  },

  newsCSTOKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'ОДКБ' },
  },

  newsCSTOMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'ОДКБ' },
  },

  newsCSTOTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'ОДКБ' },
  },

  newsCSTOUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'ОДКБ' },
  },

  newsCSTOUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'ОДКБ' },
  },
  newsCSTOUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'ОДКБ' },
  },
  newsBRIAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'ОПОП Шелковый путь' },
  },

  newsBRIArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'ОПОП Шелковый путь' },
  },

  newsBRIBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'ОПОП Шелковый путь' },
  },

  newsBRIGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'ОПОП Шелковый путь' },
  },

  newsBRIKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'ОПОП Шелковый путь' },
  },

  newsBRIKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'ОПОП Шелковый путь' },
  },

  newsBRIMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'ОПОП Шелковый путь' },
  },

  newsBRITajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRITajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRITajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'ОПОП Шелковый путь' },
  },

  newsBRIUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'ОПОП Шелковый путь' },
  },

  newsBRIUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'ОПОП Шелковый путь' },
  },
  newsBRIUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'ОПОП Шелковый путь' },
  },

  newsOICAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },

  newsOICArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },

  newsOICBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },

  newsOICGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },

  newsOICKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },

  newsOICKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },

  newsOICMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },

  newsOICTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },

  newsOICUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },

  newsOICUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },
  newsOICUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'Организация исламского сотрудничества (ОИС)' },
  },

  newsOTCAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'Организация тюркских государств' },
  },

  newsOTCArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'Организация тюркских государств' },
  },

  newsOTCBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'Организация тюркских государств' },
  },

  newsOTCGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'Организация тюркских государств' },
  },

  newsOTCKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'Организация тюркских государств' },
  },

  newsOTCKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'Организация тюркских государств' },
  },

  newsOTCMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'Организация тюркских государств' },
  },

  newsOTCTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'Организация тюркских государств' },
  },

  newsOTCUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'Организация тюркских государств' },
  },

  newsOTCUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'Организация тюркских государств' },
  },
  newsOTCUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'Организация тюркских государств' },
  },

  newsBSECAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'ОЧЭС' },
  },

  newsBSECArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'ОЧЭС' },
  },

  newsBSECBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'ОЧЭС' },
  },

  newsBSECGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'ОЧЭС' },
  },

  newsBSECKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'ОЧЭС' },
  },

  newsBSECKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'ОЧЭС' },
  },

  newsBSECMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'ОЧЭС' },
  },

  newsBSECTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'ОЧЭС' },
  },

  newsBSECUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'ОЧЭС' },
  },

  newsBSECUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'ОЧЭС' },
  },
  newsBSECUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'ОЧЭС' },
  },

  newsRussiaAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'Россия' },
  },
  newsRussiaAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'Россия' },
  },
  newsRussiaAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'Россия' },
  },

  newsRussiaArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'Россия' },
  },
  newsRussiaArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'Россия' },
  },
  newsRussiaArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'Россия' },
  },

  newsRussiaBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'Россия' },
  },
  newsRussiaBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'Россия' },
  },
  newsRussiaBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'Россия' },
  },

  newsRussiaGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'Россия' },
  },
  newsRussiaGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'Россия' },
  },
  newsRussiaGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'Россия' },
  },

  newsRussiaKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'Россия' },
  },
  newsRussiaKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'Россия' },
  },
  newsRussiaKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'Россия' },
  },

  newsRussiaKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'Россия' },
  },
  newsRussiaKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'Россия' },
  },
  newsRussiaKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'Россия' },
  },

  newsRussiaMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'Россия' },
  },
  newsRussiaMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'Россия' },
  },
  newsRussiaMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'Россия' },
  },

  newsRussiaTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'Россия' },
  },
  newsRussiaTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'Россия' },
  },
  newsRussiaTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'Россия' },
  },

  newsRussiaUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'Россия' },
  },
  newsRussiaUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'Россия' },
  },
  newsRussiaUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'Россия' },
  },

  newsRussiaUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'Россия' },
  },
  newsRussiaUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'Россия' },
  },
  newsRussiaUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'Россия' },
  },

  newsCISAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'СНГ' },
  },
  newsCISAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'СНГ' },
  },
  newsCISAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'СНГ' },
  },

  newsCISArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'СНГ' },
  },
  newsCISArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'СНГ' },
  },
  newsCISArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'СНГ' },
  },

  newsCISBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'СНГ' },
  },
  newsCISBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'СНГ' },
  },
  newsCISBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'СНГ' },
  },

  newsCISGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'СНГ' },
  },
  newsCISGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'СНГ' },
  },
  newsCISGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'СНГ' },
  },

  newsCISKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'СНГ' },
  },
  newsCISKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'СНГ' },
  },
  newsCISKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'СНГ' },
  },

  newsCISKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'СНГ' },
  },
  newsCISKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'СНГ' },
  },
  newsCISKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'СНГ' },
  },

  newsCISMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'СНГ' },
  },
  newsCISMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'СНГ' },
  },
  newsCISMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'СНГ' },
  },

  newsCISTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'СНГ' },
  },
  newsCISTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'СНГ' },
  },
  newsCISTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'СНГ' },
  },

  newsCISUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'СНГ' },
  },
  newsCISUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'СНГ' },
  },
  newsCISUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'СНГ' },
  },

  newsCISUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'СНГ' },
  },
  newsCISUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'СНГ' },
  },
  newsCISUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'СНГ' },
  },

  newsRBAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },

  newsRBArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },

  newsRBBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },

  newsRBGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },

  newsRBKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },

  newsRBKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },

  newsRBMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },

  newsRBTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },

  newsRBUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },

  newsRBUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },
  newsRBUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'Союзное государство РБ и РФ' },
  },

  newsUSAAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'США' },
  },
  newsUSAAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'США' },
  },
  newsUSAAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'США' },
  },

  newsUSAArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'США' },
  },
  newsUSAArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'США' },
  },
  newsUSAArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'США' },
  },

  newsUSABelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'США' },
  },
  newsUSABelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'США' },
  },
  newsUSABelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'США' },
  },

  newsUSAGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'США' },
  },
  newsUSAGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'США' },
  },
  newsUSAGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'США' },
  },

  newsUSAKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'США' },
  },
  newsUSAKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'США' },
  },
  newsUSAKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'США' },
  },

  newsUSAKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'США' },
  },
  newsUSAKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'США' },
  },
  newsUSAKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'США' },
  },

  newsUSAMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'США' },
  },
  newsUSAMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'США' },
  },
  newsUSAMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'США' },
  },

  newsUSATajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'США' },
  },
  newsUSATajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'США' },
  },
  newsUSATajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'США' },
  },

  newsUSAUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'США' },
  },
  newsUSAUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'США' },
  },
  newsUSAUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'США' },
  },

  newsUSAUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'США' },
  },
  newsUSAUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'США' },
  },
  newsUSAUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'США' },
  },

  newsTurkeyAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'Турция' },
  },
  newsTurkeyAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'Турция' },
  },
  newsTurkeyAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'Турция' },
  },

  newsTurkeyArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'Турция' },
  },
  newsTurkeyArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'Турция' },
  },
  newsTurkeyArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'Турция' },
  },

  newsTurkeyBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'Турция' },
  },
  newsTurkeyBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'Турция' },
  },
  newsTurkeyBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'Турция' },
  },

  newsTurkeyGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'Турция' },
  },
  newsTurkeyGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'Турция' },
  },
  newsTurkeyGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'Турция' },
  },

  newsTurkeyKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'Турция' },
  },
  newsTurkeyKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'Турция' },
  },
  newsTurkeyKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'Турция' },
  },

  newsTurkeyKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'Турция' },
  },
  newsTurkeyKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'Турция' },
  },
  newsTurkeyKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'Турция' },
  },

  newsTurkeyMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'Турция' },
  },
  newsTurkeyMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'Турция' },
  },
  newsTurkeyMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'Турция' },
  },

  newsTurkeyTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'Турция' },
  },
  newsTurkeyTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'Турция' },
  },
  newsTurkeyTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'Турция' },
  },

  newsTurkeyUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'Турция' },
  },
  newsTurkeyUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'Турция' },
  },
  newsTurkeyUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'Турция' },
  },

  newsTurkeyUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'Турция' },
  },
  newsTurkeyUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'Турция' },
  },
  newsTurkeyUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'Турция' },
  },

  newsSCOAzeSpline: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/spline/',
    params: { variable: 'ШОС' },
  },
  newsSCOAzeBars: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/bars/',
    params: { variable: 'ШОС' },
  },
  newsSCOAzeQuantity: {
    method: 'GET',
    url: 'sentiment/Azerbaijan/quantity/',
    params: { variable: 'ШОС' },
  },

  newsSCOArmSpline: {
    method: 'GET',
    url: 'sentiment/Armenia/spline/',
    params: { variable: 'ШОС' },
  },
  newsSCOArmBars: {
    method: 'GET',
    url: 'sentiment/Armenia/bars/',
    params: { variable: 'ШОС' },
  },
  newsSCOArmQuantity: {
    method: 'GET',
    url: 'sentiment/Armenia/quantity/',
    params: { variable: 'ШОС' },
  },

  newsSCOBelSpline: {
    method: 'GET',
    url: 'sentiment/Belarus/spline/',
    params: { variable: 'ШОС' },
  },
  newsSCOBelBars: {
    method: 'GET',
    url: 'sentiment/Belarus/bars/',
    params: { variable: 'ШОС' },
  },
  newsSCOBelQuantity: {
    method: 'GET',
    url: 'sentiment/Belarus/quantity/',
    params: { variable: 'ШОС' },
  },

  newsSCOGeoSpline: {
    method: 'GET',
    url: 'sentiment/Georgia/spline/',
    params: { variable: 'ШОС' },
  },
  newsSCOGeoBars: {
    method: 'GET',
    url: 'sentiment/Georgia/bars/',
    params: { variable: 'ШОС' },
  },
  newsSCOGeoQuantity: {
    method: 'GET',
    url: 'sentiment/Georgia/quantity/',
    params: { variable: 'ШОС' },
  },

  newsSCOKazSpline: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/spline/',
    params: { variable: 'ШОС' },
  },
  newsSCOKazBars: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/bars/',
    params: { variable: 'ШОС' },
  },
  newsSCOKazQuantity: {
    method: 'GET',
    url: 'sentiment/Kazakhstan/quantity/',
    params: { variable: 'ШОС' },
  },

  newsSCOKyrSpline: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/spline/',
    params: { variable: 'ШОС' },
  },
  newsSCOKyrBars: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/bars/',
    params: { variable: 'ШОС' },
  },
  newsSCOKyrQuantity: {
    method: 'GET',
    url: 'sentiment/Kyrgyzstan/quantity/',
    params: { variable: 'ШОС' },
  },

  newsSCOMolSpline: {
    method: 'GET',
    url: 'sentiment/Moldova/spline/',
    params: { variable: 'ШОС' },
  },
  newsSCOMolBars: {
    method: 'GET',
    url: 'sentiment/Moldova/bars/',
    params: { variable: 'ШОС' },
  },
  newsSCOMolQuantity: {
    method: 'GET',
    url: 'sentiment/Moldova/quantity/',
    params: { variable: 'ШОС' },
  },

  newsSCOTajSpline: {
    method: 'GET',
    url: 'sentiment/Tajikistan/spline/',
    params: { variable: 'ШОС' },
  },
  newsSCOTajBars: {
    method: 'GET',
    url: 'sentiment/Tajikistan/bars/',
    params: { variable: 'ШОС' },
  },
  newsSCOTajQuantity: {
    method: 'GET',
    url: 'sentiment/Tajikistan/quantity/',
    params: { variable: 'ШОС' },
  },

  newsSCOUzbSpline: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/spline/',
    params: { variable: 'ШОС' },
  },
  newsSCOUzbBars: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/bars/',
    params: { variable: 'ШОС' },
  },
  newsSCOUzbQuantity: {
    method: 'GET',
    url: 'sentiment/Uzbekistan/quantity/',
    params: { variable: 'ШОС' },
  },

  newsSCOUkrSpline: {
    method: 'GET',
    url: 'sentiment/Ukraine/spline/',
    params: { variable: 'ШОС' },
  },
  newsSCOUkrBars: {
    method: 'GET',
    url: 'sentiment/Ukraine/bars/',
    params: { variable: 'ШОС' },
  },
  newsSCOUkrQuantity: {
    method: 'GET',
    url: 'sentiment/Ukraine/quantity/',
    params: { variable: 'ШОС' },
  },

  newsQuantityArm: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Армения' },
  },
  newsQuantityAze: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Азербайджан' },
  },
  newsQuantityBel: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Беларусь' },
  },
  newsQuantityGeo: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Грузия' },
  },
  newsQuantityKaz: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Казахстан' },
  },
  newsQuantityKyr: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Кыргызстан' },
  },
  newsQuantityMol: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Молдова' },
  },
  newsQuantityRus: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Россия' },
  },
  newsQuantityTaj: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Таджикистан' },
  },
  newsQuantityUkr: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Украина' },
  },
  newsQuantityUzb: {
    method: 'GET',
    url: 'sentiment/news_quantity/',
    params: { region: 'Узбекистан' },
  },
};

export default news;
