import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  GDPdynamics: {
    EAEU: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPvolumeByQuarterEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Russia: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPvolumeByQuarterRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Belarus: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPvolumeByQuarterBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Armenia: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPvolumeByQuarterArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPvolumeByQuarterKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPvolumeByQuarterKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
  },
  AnnualGDP: {
    EAEU: [
      {
        title: '',
        request: { ...API.GDP.volumeByYearExchangeRate },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset3 },
      },
    ],
  },
  ProductionIndustrial: {
    EAEU: [
      {
        title: 'Актуальные',
        request: { ...API.industrialProduction.productionQuarterEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
    Russia: [
      {
        title: 'Актуальные',
        request: { ...API.industrialProduction.productionQuarterRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
    Belarus: [
      {
        title: 'Актуальные',
        request: { ...API.industrialProduction.productionQuarterBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
    Armenia: [
      {
        title: 'Актуальные',
        request: { ...API.industrialProduction.productionQuarterArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Актуальные',
        request: { ...API.industrialProduction.productionQuarterKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Актуальные',
        request: { ...API.industrialProduction.productionQuarterKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
  },

  AgricultureQuarterlyYearly: {
    EAEU: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.AgricultureQuarterlyEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyEAEU },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Armenia: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.AgricultureQuarterlyArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Belarus: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.AgricultureQuarterlyBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Russia: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.AgricultureQuarterlyRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.AgricultureQuarterlyKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.AgricultureQuarterlyKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
  },
};
