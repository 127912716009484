import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ReactComponent as LockPassWordIcon } from 'assets/icons/lock-password.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email-login.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key-login.svg';
import Input from 'ui/Input/Input';
import Button from 'ui/Button/Button';
import { ADMIN_URL } from 'services/URL';
import AuthUser from 'context/AuthUser';
import styles from './Login.module.scss';

const Login: React.FC<{}> = () => {
  const { setIsAuthUser, setOpenLogin } = useContext(AuthUser);

  const [username, setUsername] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [verificationCode, setVerificationCode] = useState(undefined);
  const [verificationStatus, setVerificationStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    password: '',
    email: '',
    verificationCode: '',
    other: '',
  });
  const [loader, setLoader] = useState(false);
  const [validation, setValidation] = useState(false);

  useEffect(() => {
    setErrorMessage({ password: '', email: '', verificationCode: '', other: '' });
    if (username === '' || password === '' || verificationCode === '') {
      setValidation(true);
    }
  }, [username, password, verificationCode]);

  const loginResponse = async (e) => {
    e.preventDefault();
    setLoader(true);
    await axios
      .request({
        method: 'POST',
        baseURL: ADMIN_URL,
        url: 'auth/login/step_1',
        data: { username, password },
      })
      .then((response) => {
        if (response.status === 200) {
          setIsAuthUser(true);
          setOpenLogin(false);
          setLoader(false);
        }
        if (response.status === 201) {
          setVerificationStatus(true);
          setLoader(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setErrorMessage({ ...errorMessage, password: error.response.data.detail });
        } else if (error?.response?.status === 404) {
          setErrorMessage({ ...errorMessage, email: error.response.data.detail });
        } else {
          setErrorMessage({ ...errorMessage, other: 'Что-то пошло не так' });
        }

        setLoader(false);
      });
  };

  const verificationResponse = async () => {
    setLoader(true);
    await axios
      .request({
        method: 'POST',
        baseURL: ADMIN_URL,
        url: 'auth/login/step_2',
        data: { verificationCode },
      })
      .then((response) => {
        if (response.status === 200) {
          setIsAuthUser(true);
          setOpenLogin(false);
          setLoader(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setErrorMessage({ ...errorMessage, verificationCode: error.response.data.detail });
        } else if (error?.response?.status === 403) {
          setErrorMessage({ ...errorMessage, verificationCode: error.response.data.detail });
        } else {
          setErrorMessage({ ...errorMessage, other: 'Что-то пошло не так' });
        }

        setLoader(false);
      });
  };

  return (
    <>
      {!verificationStatus ? (
        <div>
          <div className={styles.modal_title}>Войти в систему</div>
          <div className={styles.input_title}>Имя пользователя:</div>
          <form onSubmit={loginResponse}>
            <Input
              value={username === undefined ? '' : username}
              placeholder="Введите адрес электронной почты"
              onChange={(value) => setUsername(value)}
              type="text"
              icon={EmailIcon}
              error={errorMessage.email || (validation && username === '' ? 'Введите email' : '')}
            />
            <div className={styles.input_title}>Пароль:</div>
            <Input
              value={password === undefined ? '' : password}
              placeholder="Введите пароль"
              onChange={(value) => setPassword(value)}
              type="password"
              icon={LockPassWordIcon}
              error={
                errorMessage.password || (validation && password === '' ? 'Введите пароль' : '')
              }
            />
            <div className={styles.button_wrapper}>
              <Button
                title="Войти"
                type="submit"
                loader={loader}
                disabled={loader || !username || !password}
                error={errorMessage.other}
              />
            </div>
          </form>
        </div>
      ) : (
        <div>
          <div className={styles.modal_title}>Войти в систему</div>
          <div className={styles.input_title}>Код для верификации отправлен на Ваш email:</div>
          <Input
            value={verificationCode === undefined ? '' : verificationCode}
            placeholder="Введите верификационный код"
            onChange={(value) => setVerificationCode(value)}
            type="text"
            icon={KeyIcon}
            error={
              errorMessage.verificationCode ||
              (validation && verificationCode === '' ? 'Введите верификационный код' : '')
            }
          />
          <div className={styles.button_wrapper}>
            <Button
              title="Войти"
              loader={loader}
              onClick={verificationResponse}
              disabled={loader || !verificationCode}
            />
          </div>
        </div>
      )}
      ,
    </>
  );
};

export default Login;
