import React from 'react';
import styles from './Page.module.scss';

const AboutProject: React.FC = () => {
  return (
    <div className={styles.page_text}>
      <h1>О проекте</h1>
      <div>
        <p>
          Экономический анализ интеграции в Евразийском экономическом союзе (ЕАЭС) проводится
          <br />
          <a href="http://www.vavt.ru/imef" target="_blank" rel="noreferrer">
            Институтом международной экономики и финансов
          </a>
          <br />
          <a href="http://www.vavt.ru/science/site/LSP6ED90B" target="_blank" rel="noreferrer">
            Институтом развития интеграционных процессов
          </a>
        </p>

        <p>
          При поддержке:
          <br />
          <a href="http://www.vavt.ru/science/site/cda" target="_blank" rel="noreferrer">
            Центра анализа данных Всероссийской академии внешней торговли Министерства
            экономического развития Российской Федерации.
          </a>
        </p>

        <p>Комплексный экономический анализ проводится с целью оценить:</p>
        <ul>
          <li>Различные экономические эффекты интеграции для каждой страны-участницы ЕАЭС,</li>
          <li>Влияние интеграции в ЕАЭС на состояние торговли стран,</li>
          <li>А также эффекты влияния мер тарифного регулирования на товарные потоки ЕАЭС.</li>
        </ul>
      </div>
    </div>
  );
};

export default AboutProject;
