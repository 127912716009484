import React, { useEffect, useState, useContext } from 'react';
// import { Link } from 'react-router-dom';
import Context from 'context/SidebarContext';
// import MainContext from 'context/MainContext';
import AuthUser from 'context/AuthUser';
import MenuIcon from '@material-ui/icons/Menu';
import classnames from 'classnames';
import axios from 'axios';
import AuthLoader from 'ui/AuthLoader/AuthLoader';

import { Tooltip } from 'frontend-react-library/lib/ui/Tooltip';
import { ReactComponent as LoginIMG } from 'assets/icons/login-icon.svg';
import { ReactComponent as LogoutIMG } from 'assets/icons/logout-icon.svg';
// import { ReactComponent as EAEUMainIcon } from 'assets/icons/nav-eaeu.svg';
// import { ReactComponent as RussianHeraldryIcon } from 'assets/icons/russian-heraldry.svg';
// import RusBelFlagImg from 'assets/img/rusBelFlags.png';
// import EAEULogoImg from 'assets/img/EAEUEmblem.png';

import styles from './Header.module.scss';
import SearchBar from './SearchBar';

const Header: React.FC<{ openModal: Function }> = ({ openModal }) => {
  const [headerShow, setHeaderShow] = useState(true);
  const { setShowSidebar } = useContext(Context);
  const { isAuthUser } = useContext(AuthUser);
  const { isAuthVAVTUser, setIsAuthVAVTUser } = useContext(AuthUser);
  // const { isMainDir, setIsMainDir } = useContext(MainContext);
  const [isLoading, setIsLoading] = useState(false);

  // отображение/скрытие хедера при прокрутке окна
  useEffect(() => {
    let scrollPrev = 0;
    const scrollHandler = (e: any) => {
      const scrolled = e.target.documentElement.scrollTop;
      if (scrolled > 400 && scrolled > scrollPrev) {
        setHeaderShow(false);
      } else {
        setHeaderShow(true);
      }
      scrollPrev = scrolled;
    };
    document.addEventListener('scroll', scrollHandler);

    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const logout = async () => {
    setIsLoading(true);
    await axios
      .request({
        method: 'GET',
        baseURL: '',
        url: 'https://integration.vavt.ru/auth/api/v1/logout',
        withCredentials: true,
      })
      .then((response) => {
        if (response?.status === 200) {
          setIsAuthVAVTUser(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        window.location.reload();
      });
  };

  if (isLoading === true) {
    return <AuthLoader />;
  }

  return (
    <header
      className={`${headerShow ? styles.header : classnames(styles.header, styles.headerHidden)}`}
    >
      <div
        className={classnames(styles.headerButton, styles.headerIcon)}
        onClick={() => setShowSidebar(true)}
        onKeyPress={() => setShowSidebar(true)}
        role="button"
        aria-label="Header button"
        tabIndex={0}
      >
        <MenuIcon />
      </div>

      {/* скрыто от показа на открытом портале */}

      {/* {process.env.REACT_APP_ROUTERS === 'full_routers' && (
        <div>
          {!isMainDir ? (
            <div>
              <Tooltip text="Перейти в Интеграционный портал" position="center">
                <Link to="/">
                  <img
                    className={styles.dirImg}
                    src={EAEULogoImg}
                    alt="EAEU img"
                    onClick={() => setIsMainDir(!isMainDir)}
                    aria-hidden="true"
                  />
                </Link>
              </Tooltip>
              <img className={styles.dirMainImgDisabled} src={RusBelFlagImg} alt="rus-bel img" />
            </div>
          ) : (
            <div>
              <img className={styles.dirImgDisabled} src={EAEULogoImg} alt="EAEU img" />

              <Tooltip text="Перейти в Портал Союзного государства" position="center">
                <Link to="/about_union_state">
                  <img
                    className={styles.dirMainImg}
                    src={RusBelFlagImg}
                    alt="rus-bel img"
                    onClick={() => setIsMainDir(!isMainDir)}
                    aria-hidden="true"
                  />
                </Link>
              </Tooltip>
            </div>
          )}
        </div>
      )} */}

      <SearchBar />

      {process.env.REACT_APP_ROUTERS === 'full_routers' &&
        process.env.REACT_APP_URL === 'dev' &&
        (!isAuthUser ? (
          <Tooltip text="Войти" position="right">
            <LoginIMG className={styles.loginImg} onClick={() => openModal()} />
          </Tooltip>
        ) : (
          <Tooltip text="Выйти" position="right">
            <LogoutIMG className={styles.loginImg} onClick={() => openModal()} />
          </Tooltip>
        ))}

      {
        process.env.REACT_APP_ROUTERS === 'full_routers' &&
          process.env.REACT_APP_URL === 'prod' &&
          isAuthVAVTUser && (
            <Tooltip text="Выйти" position="right">
              <LogoutIMG className={styles.loginImg} onClick={() => logout()} />
            </Tooltip>
          )

        // (!isAuthVAVTUser ? (
        //   <Tooltip text="Войти" position="right">
        //     <LoginIMG className={styles.loginImg} onClick={() => openModal()} />
        //   </Tooltip>
        // ) : (
        //   <Tooltip text="Выйти" position="right">
        //     <LogoutIMG className={styles.loginImg} onClick={() => openModal()} />
        //   </Tooltip>
        // ))
      }
    </header>
  );
};

export default Header;
