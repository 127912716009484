import React, { useState, useContext, useEffect } from 'react';
import Context from 'context/CountryContext';

import classnames from 'classnames';
import styles from './CountryButtons.module.scss';

const CountryButtons: React.FC<{
  countries: Array<{ name: string; flag: string; code: string }>;
}> = ({ countries }) => {
  const [activeButton, setActiveButton] = useState(countries[0].name);
  const { setCurrentCountry } = useContext(Context);

  useEffect(() => {
    setActiveButton(countries[0].name);
    setCurrentCountry(countries[0].code);

    return () => {
      setCurrentCountry('EAEU');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeCountry = (country) => {
    setActiveButton(country.name);
    setCurrentCountry(country.code);
  };

  const returnCountryButtons = countries.map((country) => {
    return (
      <button
        key={country.name}
        type="button"
        className={
          activeButton === country.name
            ? classnames(styles.country_button, styles.active)
            : styles.country_button
        }
        onClick={() => onChangeCountry(country)}
      >
        <span className={styles.country_name}>
          <img alt="country-flag" src={country.flag} />
          {country.name}
        </span>
      </button>
    );
  });

  return <div className={styles.country_nav_buttons}>{returnCountryButtons}</div>;
};

export default CountryButtons;
