import React, { useState } from 'react';
import { ReactComponent as ShowPasswordIMG } from 'assets/icons/show-password.svg';
import { ReactComponent as HidePasswordIMG } from 'assets/icons/hide-password.svg';

import classnames from 'classnames';
import styles from './Input.module.scss';

const Input: React.FC<{
  value: string | undefined;
  placeholder: string;
  type?: string;
  icon?: any;
  error?: string;
  onChange: Function;
}> = ({ value, placeholder, onChange, icon, type = 'text', error }) => {
  const [inputType, setInputType] = useState(type);
  const Icon = icon;

  const onChangeInputType = () => {
    if (inputType === 'text') {
      setInputType('password');
    }
    if (inputType === 'password') {
      setInputType('text');
    }
  };

  return (
    <div
      className={
        error ? classnames(styles.input_wrapper, styles.input_wrapper_error) : styles.input_wrapper
      }
    >
      {icon && (
        <div>
          <Icon className={styles.input_icon} />
        </div>
      )}
      <input
        value={value}
        placeholder={placeholder}
        type={inputType}
        className={
          type === 'password' ? classnames(styles.input, styles.input_password) : styles.input
        }
        onChange={(e) => onChange(e.target.value)}
      />
      {type === 'password' && (
        <button type="button" onClick={onChangeInputType} className={styles.button_eye}>
          {inputType === 'text' && <HidePasswordIMG />}
          {inputType === 'password' && <ShowPasswordIMG />}
        </button>
      )}
      {error && <div className={styles.error_message}>{error}</div>}
    </div>
  );
};

export default Input;
