import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  DynamicsEAEU: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportEAEU },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportEAEU },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
  ],
  Dynamics: {
    Russia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Belarus: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
  },
  TradeDetailed: {
    EAEU: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportEAEU },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportEAEU },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
    ],
    Russia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
    ],
    Belarus: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset5 },
      },
    ],
  },

  ServicesTradeGrouped: {
    EAEU: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesTradeGroupedExportCountries },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesTradeGroupedImportCountries },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      },
    ],
  },
  TradeOfServices: {
    EAEU: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsEAEU },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
    Russia: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsRus },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
    Armenia: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsArm },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
    Belarus: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsBel },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
    Kazakhstan: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsKaz },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
    Kyrgyzstan: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsKyr },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
  },
  Structure: {
    EAEU: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolActByPrevYears },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset10 },
      },
    ],
  },
};
