import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  Dynamics: [
    // {
    //   title: 'Взаимная торговля',
    //   filter: 'Актуальные',
    //   request: { ...API.tradeInGoods.volumeDynamicsEAEUTradeLastEAEU },
    //   chart: 'SplineBarChart',
    //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    // },
    // {
    //   title: 'Экспорт',
    //   filter: 'Актуальные',
    //   request: {
    //     ...API.tradeInGoods.tradeGoodsSummaryExportLastEAEU,
    //   },
    //   chart: 'SplineBarChart',
    //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    // },
    // {
    //   title: 'Импорт',
    //   filter: 'Актуальные',
    //   request: {
    //     ...API.tradeInGoods.tradeGoodsSummaryImportLastEAEU,
    //   },
    //   chart: 'SplineBarChart',
    //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    // },
    // {
    //   title: 'Оборот',
    //   filter: 'Актуальные',
    //   request: {
    //     ...API.tradeInGoods.tradeGoodsSummaryTurnoverLastEAEU,
    //   },
    //   chart: 'SplineBarChart',
    //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    // },
    {
      title: 'Взаимная торговля',
      // filter: 'Исторические',
      request: {
        ...API.tradeInGoods.volumeDynamicsEAEUTradePrevEAEU,
      },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Экспорт',
      // filter: 'Исторические',
      request: { ...API.tradeInGoods.tradeGoodsSummaryExportPrevEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Импорт',
      // filter: 'Исторические',
      request: { ...API.tradeInGoods.tradeGoodsSummaryImportPrevEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Оборот',
      // filter: 'Исторические',
      request: { ...API.tradeInGoods.tradeGoodsSummaryTurnoverPrevEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
  ],

  ShareMemberStatesEAEU: [
    {
      title: 'Актуальные',
      request: { ...API.tradeInGoods.shareEAEUTrade },
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset1 },
    },
    {
      title: 'Исторические',
      request: { ...API.tradeInGoods.chartShare },
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset1 },
    },
  ],

  MutualTradeGrowth: [
    {
      title: '',
      request: { ...API.tradeInGoods.volumeGroupsCommodityLastYearEAEU },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
  ],

  Top10ByValueVolume: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.tradeExportLastYearEAEU },
      filter: 'За 2023 год',
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.tradeImportLastYearEAEU },
      filter: 'За 2023 год',
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.tradeTurnoverLastYearEAEU },
      filter: 'За 2023 год',
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.tradeExportPrevEAEU },
      filter: 'По годам',
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.tradeImportPrevEAEU },
      filter: 'По годам',
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.tradeTurnoverPrevEAEU },
      filter: 'По годам',
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
  ],

  ProductGroups: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.productGroupsExportShareEAEU },
      filter: 'Доли',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.productGroupsImportShareEAEU },
      filter: 'Доли',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Торговля',
      request: { ...API.tradeInGoods.productGroupsTradeShareEAEU },
      filter: 'Доли',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.productGroupsExportVolumeEAEU },
      filter: 'Объем',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.productGroupsImportVolumeEAEU },
      filter: 'Объем',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Торговля',
      request: { ...API.tradeInGoods.productGroupsTradeVolumeEAEU },
      filter: 'Объем',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
  ],

  CommoditiesTradeDynamicEAEU: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyExportEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyImportEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.commoditiesTradeDynamicYearlyTurnoverEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  Top10ByValueVolumeNationalEAEU: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.tradeExportYearlyEAEU },
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.tradeImportYearlyEAEU },
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.tradeTurnoverYearlyEAEU },
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
  ],

  ProductTradeGroupsShareEAEU: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.productTradeGroupsExportShareEAEU },
      filter: 'Доли',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.productTradeGroupsImportShareEAEU },
      filter: 'Доли',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.productTradeGroupsTurnoverShareEAEU },
      filter: 'Доли',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Экспорт',
      request: { ...API.tradeInGoods.productTradeGroupsExportVolumeEAEU },
      filter: 'Объем',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInGoods.productTradeGroupsImportVolumeEAEU },
      filter: 'Объем',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },

    {
      title: 'Оборот',
      request: { ...API.tradeInGoods.productTradeGroupsTurnoverVolumeEAEU },
      filter: 'Объем',
      chart: 'HBarChart',
      chartOptions: { ...ChartOptions.HBarChart.preset8 },
    },
  ],

  commoditiesTradeYearlyWithEAEU: [
    {
      title: '',
      request: { ...API.tradeInGoods.commoditiesTradeYearlyWithEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  WithEAEUStructLastYearEAEU: [
    {
      title: '',
      request: { ...API.tradeInGoods.WithEAEUStructLastYearEAEU },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset6 },
    },
  ],
};
