import React from 'react';
import ChartError from './ChartError';
import ChartLoader from './ChartLoader';

interface StatusInterface {
  data: any;
  error: any;
  loading: boolean;
}

const ChartStatus: React.FC<{
  status: StatusInterface;
  children: React.ReactElement;
  repeatRequest: Function;
}> = ({ status, children, repeatRequest }) => {
  if (!status.loading) {
    if (status.data) {
      return children;
    }
    return <ChartError status={status} repeatRequest={repeatRequest} />;
  }
  return <ChartLoader />;
};

export default ChartStatus;
