import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/UnionState/CommonUnionState';
import DownloadPDF from 'assets/img/pdf.svg';

import assessingEconomicEffectsMethodology from './assessingEconomicEffectsMethodology.pdf';

import styles from './CommonStylesUnionStatePublic.module.css';

const EconomicEffectsProgramsUnionState: React.FC = () => {
  const itemBlockStyle: any = {
    marginBottom: '50px',
    padding: '30px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08)',
  };

  return (
    <div className={styles.page_text}>
      <h1>Экономические эффекты и ход реализации Союзных программ</h1>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.EconomicEffectsUS} />
      </div>

      <div style={itemBlockStyle}>
        <p>
          В случае полной реализации 28 Союзных программ будут наблюдаться следующие эффекты для
          экономик России и Беларуси (от уровня 2021 г.):
        </p>
        <p style={{ fontWeight: `bold` }}>• Для Беларуси:</p>
        <p>– Дополнительный рост реального ВВП Беларуси составит 1,81% или 1,23 млрд долл.;</p>
        <p>– При таком росте ВВП безработица (по методологии МОТ) в стране снизится на 0,4 п.п.;</p>
        <p>– Экспорт товаров на рынок России увеличится на 3% или 495 млн долл.;</p>
        <p>– Экспорт услуг на рынок России увеличится на 4,5% или 70 млн долл.;</p>
        <p>– Рост денежных переводов из России в Беларусь вырастет на 4,5% или 15 млн дол.;</p>
        <p>– Индекс потребительских цен сократиться на 0,5 п.п.;</p>
        <p style={{ fontWeight: `bold` }}>• Для России:</p>
        <p>– Дополнительный рост реального ВВП России составит 0,054% или 0,96 млрд долл.;</p>
        <p>
          – При таком росте ВВП безработица (по методологии МОТ) в стране снизится на 0,05 п.п.;
        </p>
        <p>– Экспорт товаров на рынок Беларуси вырастет на 2,2% или 520 млн долл.;</p>
        <p>– Экспорт услуг на рынок Беларуси увеличится на 2,5% или 40 млн долл.;</p>
        <p>– Рост денежных переводов из Беларуси в Россию вырастет на 4% или 13 млн дол.;</p>
        <p>– Индекс потребительских цен сократиться на 0,05 п.п.</p>
      </div>

      <div style={itemBlockStyle}>
        <p>
          Согласно результатам расчетов, наибольший относительный выигрыш для Республики Беларусь
          будет обеспечен Союзными программами № 21 (единые правила госзаказа), №11 (гармонизация
          налогового и таможенного законодательства) и №13-14 (интеграции информационных систем
          транспортного контроля и унификация регулирования транспортного рынка). Для России –
          программами № 20 (формирование единой промышленной политики), №19 (формирование единой
          аграрной политики) и №13-14.
        </p>
      </div>

      <div className={styles.page_section}>
        <div className={styles.sub_section}>
          <div className={styles.tile}>
            <div className={styles.tile_title}>Методология оценки экономических эффектов</div>
            <a
              href={assessingEconomicEffectsMethodology}
              download="Методология оценки экономических эффектов.pdf"
              className={styles.downloadPDF_link}
            >
              <span className={styles.downloadPDF_link_img}>
                <img src={DownloadPDF} alt="pdf download" />
              </span>
              <span>Скачать данные</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EconomicEffectsProgramsUnionState;
