import React from 'react';
import cl from './AuthLoader.module.scss';

const AuthLoader = () => {
  return (
    <div className={cl.loaderWrapper}>
      <div className={cl.loader} />
    </div>
  );
};

export default AuthLoader;
