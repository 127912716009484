import CountryButtons from 'components/CountryButtons';
import { useContext } from 'react';
import Context from 'context/CountryContext';
import { countries } from 'configs/countries';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/SelectedCountries/MonetaryPolicy';
import styles from '../Page.module.scss';

const MonetaryPolicySC = () => {
  const { currentCountry } = useContext(Context);

  return (
    <>
      <h1>Денежно-кредитная политика</h1>

      <CountryButtons
        countries={[
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
          countries.Moldova,
        ]}
      />

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.CPI[currentCountry]} />
      </div>

      {currentCountry === 'Belarus' && (
        <div className={styles.page_section}>
          <div className={styles.sub_section}>
            <DynamicChart toggles={ChartConfigs.MonetaryBankRate[currentCountry]} />
          </div>

          <div className={styles.notice}>
            <p>
              Среднегодовая ставка рефинансирования в Беларуси в настоящее время установлена на
              уровне 9,25%, что выше чем в Украине, Молдове и России. Повышение было связано с
              ростом потребительских цен. В сентябре в Беларуси был зафиксирован наибольший рост цен
              как на продовольственные (+7,8% в сравнении с декабрем 2020 г.), так и
              непродовольственные товары среди стран ЕАЭС (+8,5%).
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default MonetaryPolicySC;
