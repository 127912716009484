import React from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import SidebarContent from './SidebarContent';
import SidebarLink from './SidebarLink';

import styles from '../Sidebar.module.scss';

const ReturnSidebarItem: React.FC<{ obj: any; content: any }> = ({ obj, content }) => {
  const location = useLocation();
  return obj.items ? (
    <div
      className={`${
        location.pathname === obj.path
          ? classnames(styles.sidebar_nav_item, styles.sidebar_nav_item_active)
          : `${styles.sidebar_nav_item}`
      }`}
    >
      {location.pathname === obj.path ? (
        <SidebarContent content={content} />
      ) : (
        <SidebarLink
          to={obj.path}
          title={obj.title}
          className={`${
            location.pathname === obj.path
              ? classnames(styles.sidebar_nav_link, styles.sidebar_nav_link_active)
              : `${styles.sidebar_nav_link}`
          }`}
        />
      )}
    </div>
  ) : (
    <div
      className={`${
        location.pathname === obj.path
          ? classnames(styles.sidebar_nav_item, styles.sidebar_nav_item_active)
          : `${styles.sidebar_nav_item}`
      }`}
    >
      <SidebarLink
        to={obj.path}
        title={obj.title}
        icon={obj.icon}
        className={`${
          location.pathname === obj.path
            ? classnames(styles.sidebar_nav_link, styles.sidebar_nav_link_active)
            : `${styles.sidebar_nav_link}`
        }`}
      />
    </div>
  );
};
export default ReturnSidebarItem;
