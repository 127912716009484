import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  chartMigrantsRusFromEAEU: [
    {
      title: '',
      chart: 'SplineChartOneY',
      request: { ...API.migrationFlows.migrationVolumeByYear },
      chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
    },
  ],
  chartMigrationThisYear: [
    {
      title: '',
      chart: 'HBarChart',
      request: { ...API.migrationFlows.migrationGrowthByQuarterLastYear },
      chartOptions: { ...ChartOptions.HBarChart.preset3 },
    },
  ],
};
