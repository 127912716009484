const consumerPriceIndex = {
  cpiQuarter: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_CPI/',
  },
  cpiYear: {
    method: 'GET',
    url: 'EAEU/macro/yearly_CPI/',
  },
  cpiQuarterArm: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_CPI/',
    params: { region: 'Армения' },
  },
  cpiYearArm: {
    method: 'GET',
    url: 'EAEU/macro/yearly_CPI/',
    params: { region: 'Армения' },
  },
  cpiQuarterBel: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_CPI/',
    params: { region: 'Беларусь' },
  },
  cpiYearBel: {
    method: 'GET',
    url: 'EAEU/macro/yearly_CPI/',
    params: { region: 'Беларусь' },
  },
  cpiQuarterKaz: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_CPI/',
    params: { region: 'Казахстан' },
  },
  cpiYearKaz: {
    method: 'GET',
    url: 'EAEU/macro/yearly_CPI/',
    params: { region: 'Казахстан' },
  },
  cpiQuarterKyr: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_CPI/',
    params: { region: 'Кыргызстан' },
  },
  cpiYearKyr: {
    method: 'GET',
    url: 'EAEU/macro/yearly_CPI/',
    params: { region: 'Кыргызстан' },
  },
  cpiQuarterRus: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_CPI/',
    params: { region: 'Россия' },
  },
  cpiYearRus: {
    method: 'GET',
    url: 'EAEU/macro/yearly_CPI/',
    params: { region: 'Россия' },
  },
  cpiQuarterMol: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_CPI/',
    params: { region: 'Молдова' },
  },
  cpiYearMol: {
    method: 'GET',
    url: 'EAEU/macro/yearly_CPI/',
    params: { region: 'Молдова' },
  },
  monetaryBankRate: {
    method: 'GET',
    url: 'Belarus/macro/yearly_base_rate/',
  },
};

export default consumerPriceIndex;
