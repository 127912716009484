import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  Dynamics: {
    Russia: [
      {
        title: 'Экспорт',
        request: {
          ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportUnionStateRus,
        },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: {
          ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportUnionStateRus,
        },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsTurnoverRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Belarus: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsTurnoverBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsTurnoverArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsTurnoverKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsTurnoverKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },

  TradeVolumeGrowthByQuarterAze: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportAze },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportAze },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsAze },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],

  DynamicsGrowthWithRusAze: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterExportWithRusAze },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterImportWithRusAze },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterTurnoverWithRusAze },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
  ],

  DynamicsGrowthWithRusTur: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterExportWithRusTur },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterImportWithRusTur },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterTurnoverWithRusTur },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
  ],

  DynamicsGrowthWithRusUzb: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterExportWithRusUzb },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterImportWithRusUzb },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterTurnoverWithRusUzb },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
  ],

  DynamicsGrowthWithRusGeo: [
    {
      title: 'Экспорт',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterExportWithRusGeo },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Импорт',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterImportWithRusGeo },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
    {
      title: 'Оборот',
      request: { ...API.tradeInServices.servicesTradeGrowthQuarterTurnoverWithRusGeo },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
  ],

  TradeDetailed: {
    Russia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Belarus: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Armenia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    // Ukraine: [
    //   {
    //     title: 'Экспорт',
    //     request: { ...API.tradeInServices.serviceTradeDetailedExportUkr },
    //     chart: 'HBarChart',
    //     chartOptions: { ...ChartOptions.HBarChart.preset9 },
    //   },
    //   {
    //     title: 'Импорт',
    //     request: { ...API.tradeInServices.serviceTradeDetailedImportUkr },
    //     chart: 'HBarChart',
    //     chartOptions: { ...ChartOptions.HBarChart.preset9 },
    //   },
    // ],
  },
  TradeOfServices: {
    Russia: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsRus },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
    Armenia: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsArm },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
    Belarus: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsBel },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
    Kazakhstan: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsKaz },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
    Kyrgyzstan: [
      {
        title: '',
        request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsKyr },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset1 },
      },
    ],
  },

  TradeDetailedExportImport: {
    Azerbaijan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportAze },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportAze },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportMol },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportMol },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Tajikistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportTaj },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportTaj },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportUzb },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportUzb },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Georgia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedExportGeo },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedImportGeo },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
  },

  TradeDetailedWithRus: {
    Azerbaijan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusExportAze },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusImportAze },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusExportMol },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusImportMol },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Tajikistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusExportTaj },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusImportTaj },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Turkmenistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusExportTur },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusImportTur },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusExportUzb },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusImportUzb },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],

    Ukraine: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusExportUkr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusImportUkr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
    Georgia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusExportGeo },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.serviceTradeDetailedWithRusImportGeo },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset9 },
      },
    ],
  },

  DynamicsGrowthWithRus: {
    Azerbaijan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterExportWithRusAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterImportWithRusAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterTurnoverWithRusAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Turkmenistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterExportWithRusTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterImportWithRusTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterTurnoverWithRusTur },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterExportWithRusUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterImportWithRusUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterTurnoverWithRusUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Ukraine: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterExportWithRusUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterImportWithRusUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterTurnoverWithRusUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Georgia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterExportWithRusGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterImportWithRusGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesTradeGrowthQuarterTurnoverWithRusGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithRusExportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithRusImportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesQuarterlyWithRusTurnoverMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Tajikistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithRusExportTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithRusImportTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesQuarterlyWithRusTurnoverTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },

  DynamicsGrowthWithWorld: {
    Moldova: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldExportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldImportMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldTurnoverMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Tajikistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldExportTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldImportTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldTurnoverTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldExportUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldImportUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldTurnoverUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Ukraine: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldExportUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldImportUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldTurnoverUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Georgia: [
      {
        title: 'Экспорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldExportGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Импорт',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldImportGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
      {
        title: 'Оборот',
        request: { ...API.tradeInServices.servicesQuarterlyWithWorldTurnoverGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },
};
