import React, { useContext } from 'react';
import CountryButtons from 'components/CountryButtons';
import { countries } from 'configs/countries';
import Context from 'context/CountryContext';
import DownloadPdfTonality from 'components/DownloadPdfTonality';

import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/NewsTonality/News';

import styles from './TonalityPage.module.scss';

import sources from './источники_тональность.pdf';

const USA: React.FC<{}> = () => {
  const { currentCountry } = useContext(Context);

  const countrySet = [
    'Armenia',
    'Belarus',
    'Kazakhstan',
    'Kyrgyzstan',
    'Azerbaijan',
    'Georgia',
    'Moldova',
    'Tajikistan',
    'Uzbekistan',
    'Ukraine',
  ];

  return (
    <div className={styles.page_text}>
      <h1>США</h1>

      <CountryButtons
        countries={[
          countries.Armenia,
          countries.Belarus,
          countries.Kyrgyzstan,
          countries.Kazakhstan,
          countries.Azerbaijan,
          countries.Georgia,
          countries.Moldova,
          countries.Tajikistan,
          countries.Uzbekistan,
          countries.Ukraine,
        ]}
      />

      <div className={styles.wrapperStyles}>
        {countrySet.includes(currentCountry) && (
          <>
            <div className={styles.page_section}>
              <DynamicChart toggles={ChartConfigs.News.USA.Spline[currentCountry]} />
            </div>
            <div className={styles.page_section}>
              <DynamicChart toggles={ChartConfigs.News.USA.Bars[currentCountry]} />
            </div>
            <div className={styles.page_section}>
              <DynamicChart toggles={ChartConfigs.News.USA.Quantity[currentCountry]} />
            </div>

            <div className={styles.wrapperSeparator} />

            <div className={styles.page_section}>
              <DynamicChart toggles={ChartConfigs.News.Quantity.Bars[currentCountry]} />
            </div>
          </>
        )}
      </div>

      <DownloadPdfTonality
        filePdf={sources}
        title="Источники новостей по тональности"
        download="источники_тональности.pdf"
      />
    </div>
  );
};

export default USA;
