import { useContext } from 'react';
import Context from 'context/CountryContext';
import CountryButtons from 'components/CountryButtons';
import DynamicChart from 'components/DynamicChart';
import { countries } from 'configs/countries';
import { ChartConfigs } from 'configs/pages/SelectedCountries/Covid19';
import styles from '../Page.module.scss';

const Covid19SC = () => {
  const { currentCountry } = useContext(Context);

  return (
    <>
      <h1>Заболеваемость COVID-19</h1>

      <CountryButtons
        countries={[
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
          countries.Azerbaijan,
          countries.Georgia,
          countries.Moldova,
          countries.Tajikistan,
          countries.Uzbekistan,
          countries.Ukraine,
        ]}
      />

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.Covid19[currentCountry]} />
      </div>
    </>
  );
};

export default Covid19SC;
