import React, { useContext, useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
// eslint-disable-next-line
import _ from 'lodash';
import { Field } from 'ui/Input/Field';
import AuthUser from 'context/AuthUser';
import axios, { AxiosError } from 'axios';
import AuthLoader from 'ui/AuthLoader/AuthLoader';
import VavtLogo from '../../assets/icons/logoVavt.svg';
import styles from './AuthPageVAVT.module.scss';
import { IS_OPEN_ROUTERS_ENV } from 'configs/routers';

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

// types.ts
export enum SignUpFieldKey {
  Email = 'email',
  Password = 'password',
  Surname = 'surname',
  Name = 'name',
  Patronymic = 'patronymic',
  PlaceOfWork = 'place_of_work',
  Position = 'position',
}

export enum LoginFieldKey {
  Email = 'email',
  Password = 'password',
}

type IField = {
  type: 'text' | 'password';
  placeholder: string;
  error?: string;
  isSubmitted: boolean;
  value: string;
  isDirty: boolean;
};

type SignUpFields = {
  [key in SignUpFieldKey]: IField;
};

type LoginFields = {
  [key in LoginFieldKey]: IField;
};

// constants.ts
const SIGN_UP_FORM_FIELDS: SignUpFields = {
  email: {
    type: 'text',
    placeholder: 'Почта',
    isSubmitted: false,
    error: '',
    value: '',
    isDirty: false,
  },
  password: {
    type: 'password',
    placeholder: 'Пароль',
    isSubmitted: false,
    error: '',
    value: '',
    isDirty: false,
  },
  surname: {
    type: 'text',
    placeholder: 'Фамилия',
    isSubmitted: false,
    error: '',
    value: '',
    isDirty: false,
  },
  name: {
    type: 'text',
    placeholder: 'Имя',
    isSubmitted: false,
    error: '',
    value: '',
    isDirty: false,
  },
  patronymic: {
    type: 'text',
    placeholder: 'Отчество',
    isSubmitted: false,
    error: '',
    value: '',
    isDirty: false,
  },
  place_of_work: {
    type: 'text',
    placeholder: 'Место работы',
    isSubmitted: false,
    error: '',
    value: '',
    isDirty: false,
  },
  position: {
    type: 'text',
    placeholder: 'Должность',
    isSubmitted: false,
    error: '',
    value: '',
    isDirty: false,
  },
};

// constants.ts
const LOG_IN_FORM_FIELDS: LoginFields = {
  email: {
    type: 'text',
    placeholder: 'Почта',
    isSubmitted: false,
    error: '',
    value: '',
    isDirty: false,
  },
  password: {
    type: 'password',
    placeholder: 'Пароль',
    isSubmitted: false,
    error: '',
    value: '',
    isDirty: false,
  },
};

interface BackendErrorResponse {
  detail: Array<{
    loc: [string, SignUpFieldKey | LoginFieldKey] | [];
    msg: string;
    type: string;
  }>;
}

function isCommonError(
  loc: [string, SignUpFieldKey | LoginFieldKey] | []
): loc is [string, SignUpFieldKey | LoginFieldKey] {
  return Boolean(loc.length);
}

export const SignUpPage: React.FC = () => {
  const { commonError, setCommonError, isAuthVAVTUser, isAuthPerforming } = useContext(AuthUser);
  const [signUpFormData, setSignUpFormData] = useState(SIGN_UP_FORM_FIELDS);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [disabled, setIsDisabled] = useState(false); // todo
  const history = useHistory();

  const setInSignUpForm = <T,>(
    fieldKey: SignUpFieldKey | LoginFieldKey,
    pathKey: keyof IField,
    value: T
  ) => {
    setSignUpFormData((prevData) => ({
      ...prevData,
      [fieldKey]: {
        ...prevData[fieldKey],
        [pathKey]: value,
        isSubmitted: false,
      },
    }));
  };

  const handleSignUpFieldChange = useCallback(
    (fieldKey: SignUpFieldKey | LoginFieldKey, value: string) => {
      setInSignUpForm(fieldKey, 'value', value);
    },
    []
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsDisabled(true);

    try {
      const response = await axios.request({
        method: 'POST',
        baseURL: '',
        url: 'https://integration.vavt.ru/auth/api/v1/register',
        // url: 'http://localhost:7654/register',
        data: {
          email: signUpFormData.email.value || null,
          password: signUpFormData.password.value || null,
          surname: signUpFormData.surname.value || null,
          name: signUpFormData.name.value || null,
          place_of_work: signUpFormData.place_of_work.value || null,
          position: signUpFormData.position.value || null,
        },
      });

      if (response.status === 201) {
        setConfirmMessage('Письмо с подтверждением регистрации отправлено на указанный email');
        setCommonError('');
      }
    } catch (errorSubmit) {
      const err = errorSubmit as AxiosError;
      const errorResponse = err.response?.data as BackendErrorResponse;

      if (err.response?.status === 500) {
        setCommonError('Что-то пошло не так');
        setIsDisabled(false);
      }
      // eslint-disable-next-line
      if (err.response?.status === 400 || err.response?.status === 422) {
        let newData = signUpFormData;

        for (let i = 0; i < errorResponse.detail.length; i++) {
          const { loc } = errorResponse.detail[i];

          if (!isCommonError(loc)) {
            setCommonError(errorResponse.detail[i].msg);
            break;
          }

          // eslint-disable-next-line
          const [_, fieldKey] = loc;

          newData = {
            ...newData,
            [fieldKey]: {
              ...newData[fieldKey],
              error: errorResponse.detail[i].msg,
              isSubmitted: true,
            },
          };
        }

        setSignUpFormData(newData);
        setIsDisabled(false);
      }
    }
  };

  const handleBack = () => {
    setCommonError('');
    history.push('/login');
  };

  useEffect(() => {
    return () => {
      setCommonError('');
    };
  }, []);

  if ((!isAuthPerforming && isAuthVAVTUser) || IS_OPEN_ROUTERS_ENV) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <div className={classnames(styles.container, styles.wrapper)}>
        <main className={classnames(styles.main, styles.mainRegister)}>
          <img className={styles.logoRegister} src={VavtLogo} alt="logo" />

          <form onSubmit={handleSubmit} className={styles.formStyle}>
            <div className={styles.confirm_message}>{confirmMessage}</div>
            {Object.entries(signUpFormData).map((entry) => {
              const [fieldKey, field] = entry;

              return (
                <Field
                  key={fieldKey}
                  fieldKey={fieldKey as SignUpFieldKey}
                  value={field.value}
                  type={field.type}
                  placeholder={field.placeholder}
                  error={field.isSubmitted && field.error ? field.error : ''}
                  onFieldSet={handleSignUpFieldChange}
                  // icon={EmailIcon}
                />
              );
            })}
            {/* <div className={styles.error_message}>{errorMessage.other}</div> */}
            {commonError && <div className={styles.error_message}>{commonError}</div>}

            <div className={styles.button_wrapper}>
              <button
                type="submit"
                className={classnames(styles.button, styles.additionalRegister)}
                disabled={disabled}
              >
                Отправить запрос
              </button>
            </div>
          </form>

          <button
            type="button"
            onClick={handleBack}
            className={classnames(styles.button, styles.additionalRegister)}
            disabled={disabled}
          >
            Вернуться назад
          </button>
        </main>
      </div>
    </div>
  );
};

export const MainAuthPage: React.FC = () => {
  const {
    setIsAuthVAVTUser,
    commonError,
    setCommonError,
    link,
    otherError,
    isAuthVAVTUser,
    isAuthPerforming,
  } = useContext(AuthUser);
  const queryParams = useQueryParams();
  const history = useHistory();

  const [logInFormData, setLogInFormData] = useState(LOG_IN_FORM_FIELDS);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const setInLoginForm = <T,>(
    fieldKey: SignUpFieldKey | LoginFieldKey,
    pathKey: keyof IField,
    value: T
  ) => {
    setLogInFormData((prevData) => ({
      ...prevData,
      [fieldKey]: {
        ...prevData[fieldKey],
        [pathKey]: value,
      },
    }));
  };

  const handleLoginFieldChange = useCallback(
    (fieldKey: SignUpFieldKey | LoginFieldKey, value: string) => {
      setInLoginForm(fieldKey, 'value', value);
    },
    []
  );

  const handler = () => {
    setDisabled(true);
    window.location.replace(link);
  };

  const mainAuthResponse = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const resp = await axios.request({
        method: 'POST',
        baseURL: '',
        url: 'https://integration.vavt.ru/auth/api/v1/login',
        // url: 'http://localhost:7654/login',
        data: {
          email: logInFormData.email.value || null,
          password: logInFormData.password.value || null,
        },
        withCredentials: true,
      });

      if (resp.status === 200) {
        setIsAuthVAVTUser(true);
        setIsLoading(false);
        setCommonError('');
        history.push('/');
      }
    } catch (errorMainLogin) {
      const err = errorMainLogin as AxiosError;
      const errorResponse = err.response?.data as BackendErrorResponse;

      if (
        err.response?.status === 400 ||
        err.response?.status === 403 ||
        err.response?.status === 422
      ) {
        let newData = logInFormData;

        for (let i = 0; i < errorResponse.detail.length; i++) {
          const { loc } = errorResponse.detail[i];

          if (!isCommonError(loc)) {
            setCommonError(errorResponse.detail[i].msg);
            setIsConfirmed(false);
            break;
          }

          // eslint-disable-next-line
          const [_, fieldKey] = loc;

          newData = {
            ...newData,
            [fieldKey]: {
              ...newData[fieldKey],
              error: errorResponse.detail[i].msg,
            },
          };
        }
        setLogInFormData(newData);
        setIsConfirmed(false);
      }

      if (err.response?.status === 500) {
        setCommonError('Что-то пошло не так');
        setIsConfirmed(false);
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (queryParams.get('confirmed')) {
      setIsConfirmed(true);
    }
  }, [queryParams]);

  useEffect(() => {
    return () => {
      setCommonError('');
    };
  }, []);

  if (isLoading === true) {
    return <AuthLoader />;
  }

  if ((!isAuthPerforming && isAuthVAVTUser) || IS_OPEN_ROUTERS_ENV) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <div className={classnames(styles.container, styles.wrapper)}>
        <main className={styles.main}>
          <img className={styles.logo} src={VavtLogo} alt="logo" />
          <form onSubmit={mainAuthResponse} className={styles.formStyle}>
            {Object.entries(logInFormData).map((entry) => {
              const [fieldKey, field] = entry;

              return (
                <Field
                  key={fieldKey}
                  fieldKey={fieldKey as LoginFieldKey}
                  value={field.value}
                  type={field.type}
                  placeholder={field.placeholder}
                  error={field.error}
                  onFieldSet={handleLoginFieldChange}
                />
              );
            })}
            {/* <InputVavtAuth
                value={email === undefined ? '' : email}
                placeholder="Введите адрес электронной почты"
                onChange={(value) => setEmail(value)}
                type="text"
                icon={EmailIcon}
              />

              <InputVavtAuth
                value={password === undefined ? '' : password}
                placeholder="Введите пароль"
                onChange={(value) => setPassword(value)}
                type="password"
                icon={LockPassWordIcon}
              /> */}
            {/* <div className={styles.error_message}>{errorMessage.otherLogin}</div> */}
            {commonError && <div className={styles.error_message}>{commonError}</div>}
            {isConfirmed && (
              <div className={styles.confirm_message}>
                Е-mail успешно подтвержден. <br />
                Наш модератор проверит данные и подтвердит создание новой учетной записи. <br />
                <br />
                Вам на почту придет соответствующее уведомление и Вы сможете войти на портал.
              </div>
            )}
            <div className={styles.button_wrapper}>
              <button
                type="submit"
                onClick={mainAuthResponse}
                disabled={!logInFormData.password.value || !logInFormData.email.value}
                className={classnames(styles.button)}
              >
                Войти
              </button>
            </div>
          </form>

          <h2 className={styles.separator}>
            <span>ИЛИ</span>
          </h2>

          <button
            type="button"
            onClick={() => handler()}
            className={classnames(styles.button, styles.additional)}
            disabled={disabled}
          >
            Войти через аккаунт ВАВТ
          </button>
          {otherError && <div className={styles.error_message}>{otherError}</div>}
          <button
            type="button"
            onClick={() => history.push('/sign-up')}
            className={classnames(styles.button, styles.additional)}
            disabled={disabled}
          >
            Отправить запрос на регистрацию
          </button>
        </main>
      </div>
    </div>
  );
};
