import covid from './modules/API_covid';
import unemployment from './modules/API_unemployment';
import obstaclesToIntegration from './modules/API_obstaclesToIntegration';
import realMoneyIncomeIndex from './modules/API_realMoneyIncomeIndex';
import investment from './modules/API_investment';
import GDP from './modules/API_GDP';
import migrationFlows from './modules/API_migrationFlows';
import tradeInServices from './modules/API_tradeInServices';
import consumerPriceIndex from './modules/API_consumerPriceIndex';
import industrialProduction from './modules/API_industrialProduction';
import stateDebt from './modules/API_stateDebt';
import crossBorderTransOfIndivid from './modules/API_crossBorderTransOfIndivid';
import GNI from './modules/API_GNI';
import tradeInGoods from './modules/API_tradeInGoods';
import news from './modules/API_news';
import unionState from './modules/API_unionState';
import transport from './modules/API_transport';

export const API = {
  covid,
  unemployment,
  obstaclesToIntegration,
  realMoneyIncomeIndex,
  investment,
  GDP,
  migrationFlows,
  tradeInServices,
  consumerPriceIndex,
  industrialProduction,
  stateDebt,
  crossBorderTransOfIndivid,
  GNI,
  tradeInGoods,
  news,
  unionState,
  transport,
};
