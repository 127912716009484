import { IconType } from 'components/Icon';
import * as React from 'react';

import { getMainRouters, getSideProjectRouters, RouterKey } from '../routers';

// integration_profiles/trade_dynamics/armenia

// Типизировать рекурсивность (избавиться от any, лень писать).
// Ооочень желательно, чтобы key был не string, а одним из элементов enum.
// Соответственно, все роуты должны быть вынесены в константы и образованы в енум.

interface Item {
  path: string;
  title: string;
  nested_items?: Array<{ path: string; title: string }>;
}

export interface SidebarItemType {
  head: string;
  title: string;
  icon?: any;
  mainIcon?: Array<React.ComponentType>;
  iconType?: IconType;
  items?: Array<Item>;
  subsections: {
    [key: string]: {
      head: string;
      path: string;
      title: string;
      subsections: any;
      items: Array<Item>;
    };
  };
}

export interface SidebarOptions {
  [key: string]: SidebarItemType;
}

declare const process: {
  env: {
    REACT_APP_ROUTERS: RouterKey;
  };
};

export const sidebarOptions = (isMainDir: boolean): SidebarOptions => {
  const obj = {};
  const currentRouters = isMainDir
    ? getMainRouters(process.env.REACT_APP_ROUTERS)
    : getSideProjectRouters(process.env.REACT_APP_ROUTERS);

  for (let i = 0; i < currentRouters.length; i++) {
    let path =
      currentRouters[i].path.length !== 1
        ? currentRouters[i].path.slice(1).split('/')
        : currentRouters[i].path;
    let current = obj;
    const titleDir = currentRouters[i].mainDir.split('/');

    const iconsArray = currentRouters[i].mainIcon;

    while (path.length > 1) {
      const [head, ...tail] = path;
      path = tail;
      if (current[head] === undefined) {
        current[head] = {
          title: titleDir[0],
          head,
          mainIcon: iconsArray?.length ? iconsArray![0] : undefined,
          iconType: currentRouters[i].iconType,
          subsections: {},
        };
      }
      iconsArray?.splice(0, 1);
      titleDir.splice(0, 1);
      current = current[head].subsections;
    }
    current[path[0]] = {
      path: currentRouters[i].path,
      icon: currentRouters[i]?.icon,
      head: currentRouters[i].path.slice(1).split('/')[
        currentRouters[i].path.slice(1).split('/').length - 1
      ],
      title: currentRouters[i].title,
      items: currentRouters[i].items,
    };
  }

  return obj;
};
