import React from 'react';
import { Link } from 'react-router-dom';
import PageNotFoundIMG from 'assets/img/error-404.png';
import styles from './PageNotFound.module.scss';

const PageNotFound: React.FC = () => {
  return (
    <div className={styles.page_not_found_wrapper}>
      <img src={PageNotFoundIMG} alt="Страница не найдена!" />
      <p>
        СТРАНИЦА НЕ НАЙДЕНА <br /> ОШИБКА 404
      </p>
      <Link to="/" className={styles.page_not_fount_link_to_home}>
        вернуться на главную страницу
      </Link>
    </div>
  );
};

export default PageNotFound;
