import React, { useState, useContext } from 'react';
import axios from 'axios';
import AuthUser from 'context/AuthUser';
import Button from 'ui/Button/Button';
import { ADMIN_URL } from 'services/URL';
import styles from './Logout.module.scss';

const Logout: React.FC<{}> = () => {
  const { setIsAuthUser, setOpenLogin } = useContext(AuthUser);
  const [loader, setLoader] = useState(false);

  const logout = async () => {
    setLoader(true);
    await axios
      .request({
        method: 'POST',
        baseURL: ADMIN_URL,
        url: 'auth/logout',
      })
      .then((response) => {
        if (response?.status === 200) {
          setIsAuthUser(false);
          setOpenLogin(false);
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
        window.location.reload();
      });
  };

  const closeModal = () => {
    setOpenLogin(false);
  };

  return (
    <>
      <div className={styles.modal_title}>Выйти из системы</div>
      <div className={styles.buttons_wrapper}>
        <Button title="Да" onClick={logout} loader={loader} disabled={loader} />
        <Button title="Нет" onClick={closeModal} />
      </div>
    </>
  );
};

export default Logout;
