import * as React from 'react';

// MAIN ICONS
import { ReactComponent as EAEUMainIcon } from 'assets/icons/nav-eaeu.svg';
import { ReactComponent as CountryCardsIcon } from 'assets/icons/nav-sng.svg';
import { ReactComponent as PublicIcon } from 'assets/icons/nav-world.svg';
import { ReactComponent as TonalityIcon } from 'assets/icons/bar-chart-polyline.svg';

// ICONS
// import { ReactComponent as AboutProjectIcon } from 'assets/icons/nav-about.svg';
// import { ReactComponent as SourcesIcon } from 'assets/icons/nav-sources.svg';

export type IconType = 'eaeu' | 'countries' | 'public' | 'tonality';

const ICONS_MAP = {
  eaeu: EAEUMainIcon,
  countries: CountryCardsIcon,
  public: PublicIcon,
  tonality: TonalityIcon,
};

export const Icon: React.FC<{ iconType: IconType; className?: string }> = ({
  iconType,
  className,
}) => {
  const SVGIcon = ICONS_MAP[iconType];

  return <SVGIcon className={className} />;
};
