import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  Covid: [
    {
      title: 'Заболеваемость',
      chart: 'SplineChartOneY',
      request: { ...API.covid.covidCases },
      chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
    },
    {
      title: 'Вакцинация',
      chart: 'SplineChartOneY',
      request: { ...API.covid.covidVaccinations },
      chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
    },
  ],
};
