import React, { useContext } from 'react';
import CountryButtons from 'components/CountryButtons';
import { countries } from 'configs/countries';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/public/TradeInServices';
import Context from 'context/CountryContext';
import styles from '../Page.module.scss';

const PublicTradeInServices: React.FC = () => {
  const { currentCountry } = useContext(Context);

  return (
    <>
      <h1 id="_1">Торговля услугами</h1>
      <CountryButtons
        countries={[
          countries.EAEU,
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
        ]}
      />

      {/* Графики ниже разделены на ЕАЭС и остальные страны поскольку был баг при отрисовке двух разных типов графиков в одной компоненте */}
      {currentCountry === 'EAEU' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.DynamicsEAEU} />
        </div>
      )}

      {(currentCountry === 'Armenia' ||
        currentCountry === 'Belarus' ||
        currentCountry === 'Kazakhstan' ||
        currentCountry === 'Kyrgyzstan' ||
        currentCountry === 'Russia') && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.Dynamics[currentCountry]} />
        </div>
      )}

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.TradeDetailed[currentCountry]} />
      </div>

      {/* {currentCountry === 'EAEU' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.ServicesTradeGrouped[currentCountry]} />
        </div>
      )} */}
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.TradeOfServices[currentCountry]} />
      </div>
      {currentCountry === 'EAEU' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.Structure[currentCountry]} />
        </div>
      )}
    </>
  );
};

export default PublicTradeInServices;
