import AboutProject from 'pages/AboutProject';
import Sources from 'pages/SourcesPage/Sources';
import PublicPage from 'pages/PublicPages/PublicPage';
import PublicEconomicEffects from 'pages/PublicPages/EconomicEffects';
import MacroeconomicIndicators from 'pages/PublicPages/MacroeconomicIndicators';
import TradeInGoods from 'pages/PublicPages/TradeInGoods';
import PublicTradeInServices from 'pages/PublicPages/TradeInServices';
import PublicInvestment from 'pages/PublicPages/Investment';
import Covid19PP from 'pages/PublicPages/Covid19';
import KeyMacroIndicators from 'pages/EAEU/KeyMacroIndicators';
import EAEUCovid19 from 'pages/EAEU/EAEUCovid19';
import TradeInGoodsDynamics from 'pages/EAEU/TradeInGoodsDynamics';
import TradeInServices from 'pages/EAEU/TradeInServices';
import Investment from 'pages/EAEU/Investment';
import MonetaryPolicy from 'pages/EAEU/MonetaryPolicy';
import LaborAndIncome from 'pages/EAEU/LaborAndIncome';
import MigrationFlows from 'pages/EAEU/MigrationFlows';
// import TransfersOfIndividuals from 'pages/EAEU/TransfersOfIndividuals';
import MonitoringObstacles from 'pages/EAEU/MonitoringObstacles';
import EconomicEffectsEAEU from 'pages/EAEU/EconomicEffectsEAEU';

// ICONS
import { ReactComponent as AboutProjectIcon } from 'assets/icons/nav-about.svg';
import { ReactComponent as SourcesIcon } from 'assets/icons/nav-sources.svg';
import { RouteConfig } from 'configs/types';
import KeyMacroIndicators_SC from 'pages/SelectedCountries/KeyMacroIndicators';
import Covid19_SC from 'pages/SelectedCountries/Covid19';
import TradeInGoods_SC from 'pages/SelectedCountries/TradeInGoods';
import ExportPotential_SC from 'pages/SelectedCountries/ExportPotential';
import TradeInServices_SC from 'pages/SelectedCountries/TradeInServices';
import Investment_SC from 'pages/SelectedCountries/Investment';
import MonetaryPolicy_SC from 'pages/SelectedCountries/MonetaryPolicy';
import StateBudgetDebt_SC from 'pages/SelectedCountries/StateBudgetDebt';
import LaborAndIncome_SC from 'pages/SelectedCountries/LaborAndIncome';
import Transport_SC from 'pages/SelectedCountries/Transport';
// import TransfersOfIndividuals_SC from 'pages/SelectedCountries/TransfersOfIndividuals';
import EasternPartnership from 'pages/TonalityPages/EasternPartnership';
// import GUAM from 'pages/TonalityPages/GUAM';
import EAEU from 'pages/TonalityPages/EAEU';
import EU from 'pages/TonalityPages/EU';
import China from 'pages/TonalityPages/China';
import NATO from 'pages/TonalityPages/NATO';
import CSTO from 'pages/TonalityPages/ODKB';
import BRI from 'pages/TonalityPages/BRI';
import OIS from 'pages/TonalityPages/OIS';
import OTS from 'pages/TonalityPages/OTS';
import BSEC from 'pages/TonalityPages/BSEC';
// import PS from 'pages/TonalityPages/PS';
import Russia from 'pages/TonalityPages/Russia';
import CIS from 'pages/TonalityPages/CIS';
import RB from 'pages/TonalityPages/RB';
import USA from 'pages/TonalityPages/USA';
import Turkey from 'pages/TonalityPages/Turkey';
import SCO from 'pages/TonalityPages/SCO';

/*
    > path - путь для роутинга;
    > title - заголовок ссылки в сайдбаре;
    > search_title - заголовок по которому осуществляется поиск в компоненте SearchBar. 
        Если search_title отсутствует, поиск осуществляется по полю title
    > mainDir - путь родительских вкладок. Записывается в виде: dir1/dir2/dir3/;
    > component - компонент страницы;
    > items - массив объектов оглавления раздела:
        >> path - ссылка соответствующая id элемента (заголовка, подзаголовка) на странице;
        >> title - заголовок ссылки.
        >> nested_items - вложенные разделы элемента оглавления (объекта) items;
*/

export const FULL_ROUTERS: RouteConfig[] = [
  {
    path: '/',
    title: 'О проекте',
    icon: AboutProjectIcon,
    mainDir: '/',
    documentTitle: 'ЕАЭС - О Проекте',
    component: AboutProject,
  },
  {
    path: '/EAEU/key_macro_indicators',
    title: 'Ключевые макроэкономические показатели',
    mainDir: 'ЕАЭС/',
    iconType: 'eaeu',
    search_title: 'Ключевые макроэкономические показатели (ЕАЭС)',
    documentTitle: 'ЕАЭС - Ключевые макроэкономические показатели',
    component: KeyMacroIndicators,
  },
  {
    path: '/EAEU/covid19',
    title: 'Эпидемиологическая динамика (COVID-19)',
    search_title: 'Эпидемиологическая динамика (COVID-19) (ЕАЭС)',
    documentTitle: 'ЕАЭС - Заболеваемость Covid-19',
    mainDir: 'ЕАЭС/',
    component: EAEUCovid19,
  },
  {
    path: '/EAEU/trade_in_goods_dynamics',
    title: 'Торговля товарами',
    mainDir: 'ЕАЭС/',
    search_title: 'Торговля товарами (ЕАЭС)',
    documentTitle: 'ЕАЭС - Торговля товарами',
    component: TradeInGoodsDynamics,
  },
  {
    path: '/EAEU/trade_in_services',
    title: 'Торговля услугами',
    mainDir: 'ЕАЭС/',
    search_title: 'Торговля услугами (ЕАЭС)',
    documentTitle: 'ЕАЭС - Торговля услугами',
    component: TradeInServices,
  },
  {
    path: '/EAEU/foreign_investment_dynamics',
    title: 'Инвестиции',
    mainDir: 'ЕАЭС/',
    search_title: 'Инвестиции (ЕАЭС)',
    documentTitle: 'ЕАЭС - Инвестиции',
    component: Investment,
  },
  {
    path: '/EAEU/monetary_policy',
    title: 'Денежно-кредитная политика',
    mainDir: 'ЕАЭС/',
    search_title: 'Денежно-кредитная политика (ЕАЭС)',
    documentTitle: 'ЕАЭС - Денежно-кредитная политика',
    component: MonetaryPolicy,
  },
  {
    path: '/EAEU/labor_and_income',
    title: 'Труд и доходы населения',
    mainDir: 'ЕАЭС/',
    search_title: 'Труд и доходы населения (ЕАЭС)',
    documentTitle: 'ЕАЭС - Труд и доходы населения',
    component: LaborAndIncome,
  },
  {
    path: '/EAEU/migration_flows',
    title: 'Миграционные потоки',
    mainDir: 'ЕАЭС/',
    search_title: 'Миграционные потоки (ЕАЭС)',
    documentTitle: 'ЕАЭС - Миграционные потоки',
    component: MigrationFlows,
  },
  // {
  //   path: '/EAEU/transfers_of_individuals',
  //   title: 'Трансграничные переводы физических лиц',
  //   mainDir: 'ЕАЭС/',
  //   search_title: 'Трансграничные переводы физических лиц (ЕАЭС)',
  //   documentTitle: 'ЕАЭС - Трансграничные переводы физических лиц',
  //   component: TransfersOfIndividuals,
  // },
  {
    path: '/EAEU/monitoring_obstacles',
    title: 'Мониторинг препятствий',
    mainDir: 'ЕАЭС/',
    search_title: 'Мониторинг препятствий (ЕАЭС)',
    documentTitle: 'ЕАЭС - Мониторинг препятствий',
    component: MonitoringObstacles,
  },
  {
    path: '/EAEU/economic_effects_of_integration',
    title: 'Экономические эффекты интеграции',
    mainDir: 'ЕАЭС/',
    search_title: 'Экономические эффекты интеграции (ЕАЭС)',
    documentTitle: 'ЕАЭС - Экономические эффекты интеграции',
    component: EconomicEffectsEAEU,
  },
  {
    path: '/selected_countries/key_macro_indicators',
    title: 'Ключевые макроэкономические показатели',
    iconType: 'countries',
    mainDir: 'Страновые разделы',
    search_title: 'Ключевые макроэкономические показатели (Страны СНГ)',
    documentTitle: 'Страны СНГ - Ключевые макроэкономические показатели',
    component: KeyMacroIndicators_SC,
  },
  {
    path: '/selected_countries/covid19',
    title: 'Эпидемиологическая динамика (COVID-19)',
    mainDir: 'Страновые разделы',
    search_title: 'Covid 19 (Страны СНГ)',
    documentTitle: 'Страны СНГ - Заболеваемость Covid-19',
    component: Covid19_SC,
  },
  {
    path: '/selected_countries/trade_in_goods',
    title: 'Торговля товарами',
    mainDir: 'Страновые разделы',
    search_title: 'Торговля товарами (Страны СНГ)',
    documentTitle: 'Страны СНГ - Торговля товарами',
    component: TradeInGoods_SC,
  },
  {
    path: '/selected_countries/export_potential',
    title: 'Экспортный потенциал',
    mainDir: 'Страновые разделы',
    search_title: 'Экспортный потенциал (Страны СНГ)',
    documentTitle: 'Страны СНГ - Экспортный потенциал',
    component: ExportPotential_SC,
  },
  {
    path: '/selected_countries/trade_in_services',
    title: 'Торговля услугами',
    mainDir: 'Страновые разделы',
    search_title: 'Торговля услугами (Страны СНГ)',
    documentTitle: 'Страны СНГ - Торговля услугами',
    component: TradeInServices_SC,
  },
  {
    path: '/selected_countries/investment',
    title: 'Инвестиции',
    mainDir: 'Страновые разделы',
    search_title: 'Инвестиции (Страны СНГ)',
    documentTitle: 'Страны СНГ - Инвестиции',
    component: Investment_SC,
  },
  {
    path: '/selected_countries/monetary_policy',
    title: 'Денежно-кредитная политика',
    mainDir: 'Страновые разделы',
    search_title: 'Денежно-кредитная политика (Страны СНГ)',
    documentTitle: 'Страны СНГ - Денежно-кредитная политика',
    component: MonetaryPolicy_SC,
  },
  {
    path: '/selected_countries/state_budget_debt',
    title: 'Государственный бюджет и долг',
    mainDir: 'Страновые разделы',
    search_title: 'Государственный бюджет и долг (Страны СНГ)',
    documentTitle: 'Страны СНГ - Государственный бюджет и долг',
    component: StateBudgetDebt_SC,
  },
  {
    path: '/selected_countries/labor_and_income',
    title: 'Труд и доходы населения',
    mainDir: 'Страновые разделы',
    search_title: 'Труд и доходы населения (Страны СНГ)',
    documentTitle: 'Страны СНГ - Труд и доходы населения',
    component: LaborAndIncome_SC,
  },
  {
    path: '/selected_countries/transport',
    title: 'Транспорт',
    mainDir: 'Страновые разделы',
    search_title: 'Транспорт (Страны СНГ)',
    documentTitle: 'Страны СНГ - Транспорт',
    component: Transport_SC,
  },
  // {
  //   path: '/selected_countries/transfers_of_individuals',
  //   title: 'Трансграничные переводы физических лиц',
  //   mainDir: 'Страновые разделы',
  //   search_title: 'Трансграничные переводы физических лиц (Страны СНГ)',
  //   documentTitle: 'Страны СНГ - Трансграничные переводы физических лиц',
  //   component: TransfersOfIndividuals_SC,
  // },
  {
    path: '/sources',
    title: 'Источники',
    icon: SourcesIcon,
    mainDir: '/',
    documentTitle: 'ЕАЭС - Источники',
    component: Sources,
  },
  {
    path: '/tonality/eastern_partnership',
    title: 'Восточное партнерство',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей Восточное партнерство',
    documentTitle: 'Восточное партнерство',
    iconType: 'tonality',
    component: EasternPartnership,
  },
  // Временно срыт раздел так как мало данных
  // {
  //   path: '/tonality/GUAM',
  //   title: 'ГУАМ',
  //   mainDir: 'Тональность новостей/',
  //   search_title: 'Тональность новостей ГУАМ',
  //   documentTitle: 'ГУАМ',
  //   component: GUAM,
  // },
  {
    path: '/tonality/EAEU',
    title: 'ЕАЭС',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей ЕАЭС',
    documentTitle: 'ЕАЭС',
    component: EAEU,
  },
  {
    path: '/tonality/EU',
    title: 'ЕС',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей ЕС',
    documentTitle: 'ЕС',
    component: EU,
  },
  {
    path: '/tonality/china',
    title: 'Китай',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей Китай',
    documentTitle: 'Китай',
    component: China,
  },
  {
    path: '/tonality/NATO',
    title: 'НАТО',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей НАТО',
    documentTitle: 'НАТО',
    component: NATO,
  },
  {
    path: '/tonality/CSTO',
    title: 'ОДКБ',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей ОДКБ',
    documentTitle: 'ОДКБ',
    component: CSTO,
  },
  {
    path: '/tonality/BRI',
    title: 'ОПОП Шелковый путь',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей ОПОП Шелковый путь',
    documentTitle: 'ОПОП Шелковый путь',
    component: BRI,
  },
  {
    path: '/tonality/OIC',
    title: 'Организация исламского сотрудничества',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей Организация исламского сотрудничества',
    documentTitle: 'Организация исламского сотрудничества',
    component: OIS,
  },
  {
    path: '/tonality/turkic_council',
    title: 'Организация Тюркских Государств',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей Организация Тюркских Государств',
    documentTitle: 'Организация Тюркских Государств',
    component: OTS,
  },
  {
    path: '/tonality/BSEC',
    title: 'ОЧЭС',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей ОЧЭС',
    documentTitle: 'ОЧЭС',
    component: BSEC,
  },
  // Временно срыт раздел так как мало данных
  // {
  //   path: '/tonality/сaspian_cooperation',
  //   title: 'Прикаспийское сотрудничество',
  //   mainDir: 'Тональность новостей/',
  //   search_title: 'Тональность новостей Прикаспийское сотрудничество',
  //   documentTitle: 'Прикаспийское сотрудничество',
  //   component: PS,
  // },
  {
    path: '/tonality/russia',
    title: 'Россия',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей Россия',
    documentTitle: 'Россия',
    component: Russia,
  },
  {
    path: '/tonality/CIS',
    title: 'СНГ',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей СНГ',
    documentTitle: 'СНГ',
    component: CIS,
  },
  {
    path: '/tonality/union_state_russia_belarus',
    title: 'Союзное государство РБ и РФ',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей Союзное государство РБ и РФ',
    documentTitle: 'Союзное государство РБ и РФ',
    component: RB,
  },
  {
    path: '/tonality/USA',
    title: 'США',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей США',
    documentTitle: 'США',
    component: USA,
  },
  {
    path: '/tonality/turkey',
    title: 'Турция',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей Турция',
    documentTitle: 'Турция',
    component: Turkey,
  },
  {
    path: '/tonality/SCO',
    title: 'ШОС',
    mainDir: 'Тональность новостей/',
    search_title: 'Тональность новостей ШОС',
    documentTitle: 'ШОС',
    component: SCO,
  },
  {
    path: '/public/economic_monitoring',
    title: 'Евразийская интеграция в цифрах: экономический мониторинг',
    search_title: 'Евразийская интеграция в цифрах (Открытый портал)',
    documentTitle: 'ЕАЭС - Главная',
    mainDir: 'Открытый портал/',
    iconType: 'public',
    component: PublicPage,
  },

  {
    path: '/public/macroeconomic_indicators',
    title: 'Макроэкономические показатели',
    mainDir: 'Открытый портал/',
    search_title: 'Макроэкономические показатели (Открытый портал)',
    documentTitle: 'ЕАЭС - Макроэкономические показатели (Открытый портал)',
    component: MacroeconomicIndicators,
  },
  {
    path: '/public/covid19',
    title: 'Эпидемиологическая динамика (COVID-19)',
    mainDir: 'Открытый портал/',
    search_title: 'Заболеваемость Covid-19 (Открытый портал)',
    documentTitle: 'ЕАЭС - Заболеваемость Covid-19',
    component: Covid19PP,
  },
  {
    path: '/public/trade_in_goods',
    title: 'Торговля товарами',
    mainDir: 'Открытый портал/Торговля товарами',
    search_title: 'Торговля товарами (Открытый портал)',
    documentTitle: 'ЕАЭС - Торговля товарами',
    component: TradeInGoods,
  },
  {
    path: '/public/trade_in_services',
    title: 'Торговля услугами',
    mainDir: 'Открытый портал/',
    search_title: 'Торговля услугами (Открытый портал)',
    documentTitle: 'ЕАЭС -Торговля услугами ',
    component: PublicTradeInServices,
  },
  {
    path: '/public/investment',
    title: 'Инвестиции',
    mainDir: 'Открытый портал/',
    search_title: 'Международные инвестиции (Открытый портал)',
    documentTitle: 'ЕАЭС - Международные инвестиции',
    component: PublicInvestment,
  },
  {
    path: '/public/economic_effects',
    title: 'Экономические эффекты интеграции',
    mainDir: 'Открытый портал/',
    search_title: 'Экономические эффекты интеграции (Открытый портал)',
    documentTitle: 'ЕАЭС - Экономические эффекты интеграции',
    component: PublicEconomicEffects,
  },
];
