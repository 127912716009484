// import ProjectMainDir from 'context/ProjectMainDir';
import React, { useContext } from 'react';
import { MainAuthPage, SignUpPage } from 'pages/AuthPageVAVT/AuthPageVAVT';
import AuthUser from 'context/AuthUser';
import { AuthVerifyRoute } from 'components/authVerifyRoute';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getRoutersConfig, IS_OPEN_ROUTERS_ENV, RouterKey } from './configs/routers';
import PageNotFound from './pages/PageNotFound';
import classnames from 'classnames';

import styles from 'styles/App.module.scss';
import RusBelFlagImg from 'assets/img/rusBelFlags.png';
import SidebarAndHeaderWrapper from 'components/SidebarAndHeaderWrapper';
import MainContext from 'context/MainContext';

declare const process: {
  env: {
    REACT_APP_ROUTERS: RouterKey;
  };
};

// todo: Routers --> Components/Wrapper
const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isMainDir, background } = useContext(MainContext);

  return (
    <>
      <SidebarAndHeaderWrapper />

      <div
        className={styles.container}
        style={{
          background: `
              linear-gradient(
                  90deg, 
                  ${background?.bgColor} 
                  70%, 
                  ${background?.bgGradientColor}
              )
          `,
        }}
      >
        <div className={styles.content}>
          <div className={classnames(styles.content_inner, styles.md_typeset)}>{children}</div>
        </div>

        {isMainDir ? (
          <div className={styles.imageContainer}>
            <img
              className={
                background?.loading
                  ? classnames(styles.imageEmblem, styles.imageHide)
                  : classnames(styles.imageEmblem, styles.imageShow)
              }
              src={background?.img}
              alt="current-country"
            />
          </div>
        ) : (
          <div className={styles.imageContainerUnionState}>
            <img
              className={
                background?.loading
                  ? classnames(styles.imageEmblem, styles.imageHide)
                  : classnames(styles.imageEmblem, styles.imageShow)
              }
              src={RusBelFlagImg}
              alt="current-country"
            />
          </div>
        )}
      </div>
    </>
  );
};

const ProtectedConsumer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthVAVTUser, isAuthPerforming } = useContext(AuthUser);

  if (!isAuthPerforming && !isAuthVAVTUser && !IS_OPEN_ROUTERS_ENV) {
    return <Redirect to="/login" />;
  }

  return <Wrapper>{children}</Wrapper>;
};

const Routers: React.FC = () => {
  const routerConfig = getRoutersConfig(process.env.REACT_APP_ROUTERS);

  return (
    //  @ts-ignore
    <Switch>
      {routerConfig.map((router) => {
        return (
          <Route
            key={router.path}
            exact
            path={router.path}
            // @ts-ignore
            render={() => (
              <ProtectedConsumer>{React.createElement(router.component)}</ProtectedConsumer>
            )}
          />
        );
      })}
      {/* @ts-ignore */}
      <Route path="/verify/:token" component={AuthVerifyRoute} />
      {/* @ts-ignore */}
      <Route exact path="/login" component={MainAuthPage} />
      {/* @ts-ignore */}
      <Route exact path="/sign-up" component={SignUpPage} />
      {/* @ts-ignore */}
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default Routers;
