import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/EAEU/TradeInGoodsDynamics';

import styles from '../Page.module.scss';

const TradeInGoodsDynamics: React.FC = () => {
  return (
    <>
      <h1 id="_1">Торговля товарами</h1>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.CommoditiesTradeDynamicEAEU} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.Top10ByValueVolumeNationalEAEU} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart
          toggles={ChartConfigs.ProductTradeGroupsShareEAEU}
          filters={['Доли', 'Объем']}
        />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.commoditiesTradeYearlyWithEAEU} />
      </div>

      {/* график ниже скрыт по просьбе ИРИП от 14.08.2023 к показу в этот день */}

      {/* <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.WithEAEUStructLastYearEAEU} />
      </div> */}

      {/* скрыто в связи с заменой на нац источники  */}
      {/* <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.Dynamics} />
      </div> */}
      {/* график ниже скрыт по просьбе ИРИП от 09.08.2023 */}
      {/* <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.ShareMemberStatesEAEU} />
      </div> */}
      {/* скрыто в связи с заменой на нац источники  */}
      {/* <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.MutualTradeGrowth} />
      </div>
      <div className={styles.page_section}>
        <DynamicChart
          toggles={ChartConfigs.Top10ByValueVolume}
          filters={['За 2023 год', 'По годам']}
        />
      </div>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.ProductGroups} filters={['Доли', 'Объем']} />
      </div> */}

      {/* <div className={styles.page_section}>
        <div className={styles.notice}>
          <ul>
            <li>
              Как во внешней торговле ЕАЭС с третьими странами, так и во взаимной доминируют
              минеральные продукты. Вместе с тем доля рассматриваемой группы товаров более
              значительна в торговле ЕАЭС с внешними партнерами.
            </li>
            <li>
              Из других важных отличий стоит упомянуть продовольственную группу товаров. Доля
              продовольствия во взаимной торговле стран ЕАЭС составляет более 18% в товарной
              структуре, в то время как с внешними партнерами ее доля равна 10%.
            </li>
          </ul>
        </div>
      </div> */}
    </>
  );
};

export default TradeInGoodsDynamics;
