import React from 'react';
import Loader from 'ui/Loader/Loader';

import classnames from 'classnames';
import styles from './Button.module.scss';

const Button: React.FC<{
  title: string;
  loader?: boolean;
  error?: string;
  onClick?: Function;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}> = ({ title, loader, error = '', onClick, disabled, type = 'button' }) => {
  return (
    <div className={styles.button_wrapper}>
      <button
        className={error !== '' ? classnames(styles.button, styles.button_error) : styles.button}
        // eslint-disable-next-line react/button-has-type
        type={type}
        disabled={disabled}
        onClick={typeof onClick === 'function' ? () => onClick() : undefined}
      >
        {loader ? <Loader /> : title}
      </button>
      <div className={styles.error_message}>{error}</div>
    </div>
  );
};

export default Button;
