import React, { useContext, useEffect } from 'react';
import CountryContext from 'context/CountryContext';
import DynamicChart from 'components/DynamicChart';

import { ChartConfigs } from 'configs/pages/public/Covid19';

import styles from '../Page.module.scss';

const Covid19PP: React.FC = () => {
  const { setCurrentCountry } = useContext(CountryContext);

  useEffect(() => {
    setCurrentCountry('COVID19');
    return () => {
      setCurrentCountry('EAEU');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <h1 id="covid-19">Заболеваемость COVID-19</h1>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.Covid} />
      </div>
    </div>
  );
};

export default Covid19PP;
