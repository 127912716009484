import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  Structure: [
    {
      title: '',
      request: { ...API.investment.structureInvestments },
      chart: 'MBarLineChartTwoY',
      chartOptions: { ...ChartOptions.MBarLineChartTwoY.preset2 },
    },
  ],
  Volume: [
    {
      title: '',
      request: { ...API.investment.accruedMutualFDIVolumeByYearEAEU },
      chart: 'HMBarChart',
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
  ],
};
