import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/EAEU/KeyMacroIndicators';

import styles from '../Page.module.scss';

const KeyMacroIndicators: React.FC = () => {
  return (
    <>
      <h1 id="_1">Ключевые макроэкономические показатели</h1>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.GDPdynamics} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.GDPstructure} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.GDPrealDyn} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.GDPtotalDyn} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.AgricultureQuarterlyYearlyEAEU} />
      </div>
    </>
  );
};

export default KeyMacroIndicators;
