import React, { useEffect, useState, useContext } from 'react';
import SidebarContext from 'context/SidebarContext';

import classnames from 'classnames';
import styles from '../Sidebar.module.scss';

const SidebarContent: React.FC<{ content: any }> = ({ content }) => {
  const { setShowSidebar } = useContext(SidebarContext);
  const [sectionRefs, setSectionRefs] = useState<Array<any>>([]);
  const [visibleSection, setVisibleSection] = useState();
  // const [styleUse, setStyle] = useState(
  //   classnames(styles.sidebar_nav_link, styles.sidebar_nav_link_active)
  // );

  useEffect(() => {
    content.items.forEach((item) => {
      const obj: { ref: HTMLElement | null; section: null | string } = { ref: null, section: null };
      obj.section = item.path;
      obj.ref = document.getElementById(item.path);
      setSectionRefs((s) => [...s, obj]);
      if (item?.nested_items) {
        item.nested_items.forEach((nested_item) => {
          const Obj: { ref: HTMLElement | null; section: null | string } = {
            ref: null,
            section: null,
          };
          Obj.section = nested_item.path;
          Obj.ref = document.getElementById(nested_item.path);
          setSectionRefs((s) => [...s, Obj]);
        });
      }
    });
  }, [content]);

  useEffect(() => {
    const getDimensions = (ele) => {
      const { height } = ele.getBoundingClientRect();
      const { offsetTop } = ele;
      const offsetBottom = offsetTop + height;

      return {
        height,
        offsetTop,
        offsetBottom,
      };
    };

    const handleScroll = () => {
      const scrollPosition = window.scrollY + 48;
      const selected = sectionRefs.find(({ ref }) => {
        const ele = ref;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
        return null;
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionRefs]);

  const scrollTo = (id) => {
    const elem = document.getElementById(id);
    elem?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setShowSidebar(false);
  };

  // const openContent = () => {
  //   setStyle(
  //     classnames(
  //       styles.sidebar_nav_link,
  //       styles.sidebar_nav_link_open,
  //       styles.sidebar_nav_link_active
  //     )
  //   );
  // };
  // const closeContent = () => {
  //   setStyle(classnames(styles.sidebar_nav_link, styles.sidebar_nav_link_active));
  // };

  const createSidebarItems = content.items.map((item) => {
    if (item?.nested_items) {
      return (
        <li className={styles.sidebar_nav_item} key={item.path}>
          <span
            className={`${
              visibleSection === item.path
                ? classnames(
                    styles.sidebar_nav_link,
                    styles.sidebar_nav_link_open,
                    styles.sidebar_nav_link_active
                  )
                : classnames(styles.sidebar_nav_link, styles.sidebar_nav_link_open)
            }`}
            onClick={() => scrollTo(item.path)}
            onKeyPress={() => scrollTo(item.path)}
            role="button"
            tabIndex={0}
          >
            {item.title}
          </span>
          <nav className={classnames(styles.sidebar_nav, styles.sidebar_nav_list_left_border)}>
            <ul className={styles.sidebar_nav_list}>
              {item.nested_items.map((nested_item) => {
                return (
                  <li className={styles.sidebar_nav_item} key={nested_item.path}>
                    <span
                      className={`${
                        visibleSection === nested_item.path
                          ? classnames(styles.sidebar_nav_link, styles.sidebar_nav_link_active)
                          : `${styles.sidebar_nav_link}`
                      }`}
                      onClick={() => scrollTo(nested_item.path)}
                      onKeyPress={() => scrollTo(nested_item.path)}
                      role="button"
                      tabIndex={0}
                    >
                      {nested_item.title}
                    </span>
                  </li>
                );
              })}
            </ul>
          </nav>
        </li>
      );
    }
    return (
      <li className={styles.sidebar_nav_item} key={item.path}>
        <span
          className={`${
            visibleSection === item.path
              ? classnames(styles.sidebar_nav_link, styles.sidebar_nav_link_active)
              : `${styles.sidebar_nav_link}`
          }`}
          onClick={() => scrollTo(item.path)}
          onKeyPress={() => scrollTo(item.path)}
          role="button"
          tabIndex={0}
        >
          {item.title}
        </span>
      </li>
    );
  });
  return (
    content && (
      <>
        {/* <label className={styleUse} htmlFor="__toc" onClick={openContent}>
          {content.title}
        </label> */}
        <span
          className={classnames(styles.sidebar_nav_link, styles.sidebar_nav_link_active)}
          style={{ cursor: 'default' }}
        >
          {content.title}
        </span>
        <nav className={classnames(styles.sidebar_nav, styles.sidebar_nav_secondary)}>
          {/* <label className={styles.sidebar_nav_title} htmlFor="__toc" onClick={closeContent}>
            Содержание
          </label> */}
          <ul className={styles.sidebar_nav_list}>{createSidebarItems}</ul>
        </nav>
      </>
    )
  );
};

export default SidebarContent;
