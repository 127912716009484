const unemployment = {
  unemploymentQuarterEAEU: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_unemployment/',
  },
  unemploymentYearlyEAEU: {
    method: 'GET',
    url: 'EAEU/macro/yearly_unemployment/',
  },
  unemploymentQuarterBel: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_unemployment/',
    params: { region: 'Беларусь' },
  },
  unemploymentYearlyBel: {
    method: 'GET',
    url: 'EAEU/macro/yearly_unemployment/',
    params: { region: 'Беларусь' },
  },
  unemploymentQuarterKaz: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_unemployment/',
    params: { region: 'Казахстан' },
  },
  unemploymentYearlyKaz: {
    method: 'GET',
    url: 'EAEU/macro/yearly_unemployment/',
    params: { region: 'Казахстан' },
  },
  unemploymentQuarterKyr: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_unemployment/',
    params: { region: 'Кыргызстан' },
  },
  unemploymentYearlyKyr: {
    method: 'GET',
    url: 'EAEU/macro/yearly_unemployment/',
    params: { region: 'Кыргызстан' },
  },
  unemploymentQuarterRus: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_unemployment/',
    params: { region: 'Россия' },
  },
  unemploymentYearlyRus: {
    method: 'GET',
    url: 'EAEU/macro/yearly_unemployment/',
    params: { region: 'Россия' },
  },
  unemploymentQuarterArm: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_unemployment/',
    params: { region: 'Армения' },
  },
  unemploymentYearlyArm: {
    method: 'GET',
    url: 'EAEU/macro/yearly_unemployment/',
    params: { region: 'Армения' },
  },
};

export default unemployment;
