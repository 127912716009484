const GNI = {
  GNIPricesUSDArm: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Армения', currency: 'USD' },
  },
  GNIPricesUSDBel: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Беларусь', currency: 'USD' },
  },
  GNIPricesUSDKaz: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Казахстан', currency: 'USD' },
  },
  GNIPricesUSDKyr: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Кыргызстан', currency: 'USD' },
  },
  GNIPricesUSDRus: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Россия', currency: 'USD' },
  },
  GNIPricesUSDAze: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Азербайджан', currency: 'USD' },
  },
  GNIPricesUSDMol: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Молдова', currency: 'USD' },
  },
  GNIPricesUSDTaj: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Таджикистан', currency: 'USD' },
  },
  GNIPricesUSDUzb: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Узбекистан', currency: 'USD' },
  },
  GNIPricesUSDUkr: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Украина', currency: 'USD' },
  },
  GNIPricesUSDGeo: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Грузия', currency: 'USD' },
  },
  GNIPricesLocalArm: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Армения', currency: 'local' },
  },
  GNIPricesLocalBel: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Беларусь', currency: 'local' },
  },
  GNIPricesLocalKaz: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Казахстан', currency: 'local' },
  },
  GNIPricesLocalKyr: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Кыргызстан', currency: 'local' },
  },
  GNIPricesLocalRus: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Россия', currency: 'local' },
  },
  GNIPricesLocalAze: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Азербайджан', currency: 'local' },
  },
  GNIPricesLocalMol: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Молдова', currency: 'local' },
  },
  GNIPricesLocalTaj: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Таджикистан', currency: 'local' },
  },
  GNIPricesLocalUzb: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Узбекистан', currency: 'local' },
  },
  GNIPricesLocalUkr: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Украина', currency: 'local' },
  },
  GNIPricesLocalGeo: {
    method: 'GET',
    url: 'CIS/macro/yearly_GNI_prices/',
    params: { region: 'Грузия', currency: 'local' },
  },
};

export default GNI;
