import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'ui/Button/Button';
import Input from 'ui/Input/Input';
import { ADMIN_URL } from 'services/URL';

import styles from './ChartEdit.module.scss';

const ChartEdit: React.FC<{
  data: any;
  name: string;
  refreshChart: Function;
  closeModal: Function;
}> = ({ data, refreshChart, closeModal }) => {
  const [inputData, setInputData] = useState({
    title: {
      title: 'Заголовок графика',
      placeholder: 'Введите заголовок графика',
      value: data?.data?.title?.text,
    },
    comment: {
      title: 'Комментарий',
      placeholder: 'Введите комментарий',
      value: data?.data?.metadata?.comment || '',
    },
    sources: {
      title: 'Источник',
      placeholder: 'Введите источник',
      value: data?.data?.metadata?.sources || '',
    },
    updateDateInternational: {
      title: 'Дата обновления согласно международному источнику',
      placeholder: 'Введите дату YYYY-MM-DD',
      value: data?.data?.metadata?.updateDateInternational || '',
    },
    updateDateNational: {
      title: 'Дата обновления согласно национальному источнику',
      placeholder: 'Введите дату YYYY-MM-DD',
      value: data?.data?.metadata?.updateDateNational || '',
    },
    updateDateVAVT: {
      title: 'Дата обновления согласно оценкам ВАВТ',
      placeholder: 'Введите дату YYYY-MM-DD',
      value: data?.data?.metadata?.updateDateVAVT || '',
    },
  });

  const [change, setChange] = useState(true);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const trimText = (str) => {
    return str.replace(/ +/g, ' ').trim();
  };

  useEffect(() => {
    const metadataCheck = (dat, inpdat) => {
      const changes = {
        title: false,
        comment: false,
        sources: false,
        updateDateInternational: false,
        updateDateNational: false,
        updateDateVAVT: false,
      };
      if (dat.title.text !== trimText(inpdat.title.value)) {
        changes.title = true;
      }
      const metadataKeys = Object.keys(inpdat).filter((key) => key !== 'title');
      for (let i = 0; i < metadataKeys.length; i++) {
        if (dat?.metadata?.[metadataKeys[i]] !== undefined) {
          changes[metadataKeys[i]] =
            dat.metadata[metadataKeys[i]] !== trimText(inpdat[metadataKeys[i]].value);
        } else {
          changes[metadataKeys[i]] = trimText(inpdat[metadataKeys[i]].value) !== '';
        }
      }
      const checkChanges = Object.values(changes).includes(true);
      return checkChanges;
    };
    setChange(metadataCheck(data.data, inputData));
  }, [inputData]);

  const updateChart = async () => {
    setLoader(true);
    const sendData = JSON.parse(JSON.stringify(inputData));
    const keys = Object.keys(sendData);

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] !== 'title' && trimText(sendData[keys[i]].value) === '') {
        sendData[keys[i]].value = null;
      } else {
        sendData[keys[i]].value = trimText(sendData[keys[i]].value);
      }
    }

    const { title, comment, sources, updateDateInternational, updateDateNational, updateDateVAVT } =
      sendData;

    await axios
      .request({
        method: 'PATCH',
        baseURL: ADMIN_URL,
        url: '/update_chart',
        data: {
          name: data?.data?.name,
          data: {
            metadata: {
              comment: comment.value,
              sources: sources.value,
              updateDateNational: updateDateNational.value,
              updateDateInternational: updateDateInternational.value,
              updateDateVAVT: updateDateVAVT.value,
            },
            title: {
              text: title.value,
            },
          },
        },
      })
      .then((response) => {
        if (response?.status === 201) {
          setLoader(false);
          refreshChart();
          closeModal();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setLoader(false);
          setErrorMessage('Пользователь не авторизован');
          window.location.reload();
        }
      });
  };

  const onChangeInputValue = (value, key) => {
    const params = { ...inputData };
    params[key].value = value;
    setInputData(params);
  };

  const returnInput = Object.keys(inputData).map((key) => {
    return (
      <div key={key}>
        <div className={styles.input_title}>{inputData[key].title}:</div>
        <Input
          value={inputData[key].value || ''}
          placeholder={inputData[key].placeholder}
          onChange={(val) => onChangeInputValue(val, key)}
          error={
            key === 'title' && trimText(inputData[key].value) === ''
              ? 'Заполните заголовок графика'
              : ''
          }
        />
      </div>
    );
  });

  return (
    <>
      <div className={styles.modal_title}>Редактирование графика</div>
      {returnInput}
      <div className={styles.button_wrapper}>
        <Button
          title={change ? 'Сохранить изменения' : 'Изменений нет'}
          onClick={updateChart}
          disabled={
            trimText(inputData.title.value) === '' || !change || errorMessage !== '' || loader
          }
          loader={loader}
          error={errorMessage}
        />
      </div>
    </>
  );
};

export default ChartEdit;
