import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  InvestmentsVolume: {
    Russia: [
      {
        title: '',
        request: { ...API.investment.accruedMutualFDIVolumeByYearRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset6 },
      },
    ],
    Belarus: [
      {
        title: '',
        request: { ...API.investment.accruedMutualFDIVolumeByYearBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset6 },
      },
    ],
    Armenia: [
      {
        title: '',
        request: { ...API.investment.accruedMutualFDIVolumeByYearArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset6 },
      },
    ],
    Kazakhstan: [
      {
        title: '',
        request: { ...API.investment.accruedMutualFDIVolumeByYearKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset6 },
      },
    ],
  },
  Investments: {
    Russia: [
      {
        title: '',
        request: { ...API.investment.investmentsToRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Armenia: [
      {
        title: '',
        request: { ...API.investment.investmentsToArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Belarus: [
      {
        title: '',
        request: { ...API.investment.investmentsToBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Kazakhstan: [
      {
        title: '',
        request: { ...API.investment.investmentsToKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Kyrgyzstan: [
      {
        title: '',
        request: { ...API.investment.investmentsToKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
  },

  DirectInvestments: {
    Azerbaijan: [
      {
        title: 'ПИИ из России в страну',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsFromRusToAzeLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsToRusFromAzeLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ из России в страну',
        filter: 'Исторические',
        request: { ...API.investment.investmentsFromRusToAzePrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Исторические',
        request: { ...API.investment.investmentsToRusFromAzePrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Tajikistan: [
      {
        title: 'ПИИ из России в страну',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsFromRusToTajLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsToRusFromTajLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ из России в страну',
        filter: 'Исторические',
        request: { ...API.investment.investmentsFromRusToTajPrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Исторические',
        request: { ...API.investment.investmentsToRusFromTajPrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Uzbekistan: [
      {
        title: 'ПИИ из России в страну',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsFromRusToUzbLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsToRusFromUzbLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ из России в страну',
        filter: 'Исторические',
        request: { ...API.investment.investmentsFromRusToUzbPrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Исторические',
        request: { ...API.investment.investmentsToRusFromUzbPrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Ukraine: [
      {
        title: 'ПИИ из России в страну',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsFromRusToUkrLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsToRusFromUkrLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ из России в страну',
        filter: 'Исторические',
        request: { ...API.investment.investmentsFromRusToUkrPrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Исторические',
        request: { ...API.investment.investmentsToRusFromUkrPrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Georgia: [
      {
        title: 'ПИИ из России в страну',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsFromRusToGeoLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsToRusFromGeoLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ из России в страну',
        filter: 'Исторические',
        request: { ...API.investment.investmentsFromRusToGeoPrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Исторические',
        request: { ...API.investment.investmentsToRusFromGeoPrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Moldova: [
      {
        title: 'ПИИ из России в страну',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsFromRusToMolLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Актуальные',
        request: { ...API.investment.investmentsToRusFromMolLast },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ из России в страну',
        filter: 'Исторические',
        request: { ...API.investment.investmentsFromRusToMolPrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
      {
        title: 'ПИИ в Россию из страны',
        filter: 'Исторические',
        request: { ...API.investment.investmentsToRusFromMolPrev },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset5 },
      },
    ],
    Turkmenistan: [],
  },

  InvestmentsStructureLastAze: [
    {
      title: 'В страну',
      request: { ...API.investment.investmentsStructureInLast },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Из страны',
      request: { ...API.investment.investmentsStructureOutLast },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
  ],

  // для Туркменистана график ниже отличается от остальных так как часть данных засекречена

  InvestmentsTur: [
    {
      title: 'Актуальные',
      request: { ...API.investment.investmentsToRusFromTurLast },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Исторические',
      request: { ...API.investment.investmentsToRusFromTurPrev },
      chart: 'BarChart',
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
  ],

  // Абхазия

  InvestmentsSocEconomAbh: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.investment.InvestmentsSocEconomAbh },
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  InvestmentsInvestProgAbh: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.investment.InvestmentsInvestProgAbh },
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  // Южная Осетия

  YearlyRuInvestmentSO: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.investment.YearlyRuInvestmentSO },
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],
};
