import { OPEN_ROUTERS } from './open_routers';
import { FULL_ROUTERS } from './full_routers';
import { SIDE_PROJECT_FULL_ROUTERS } from './side_project_full_routers';
import { RouteConfig } from './types';
import { SIDE_PROJECT_OPEN_ROUTERS } from './side_project_open_routers';

export type RouterKey = 'open_routers' | 'full_routers'; // вынести в типы

export const getSideProjectRouters = (routerKey: RouterKey): RouteConfig[] =>
  ({
    open_routers: SIDE_PROJECT_OPEN_ROUTERS,
    full_routers: SIDE_PROJECT_FULL_ROUTERS,
  }[routerKey]);

export const getMainRouters = (routerKey: RouterKey): RouteConfig[] =>
  ({
    open_routers: OPEN_ROUTERS,
    full_routers: FULL_ROUTERS,
  }[routerKey]);

export const getRoutersConfig = (routerKey: RouterKey): RouteConfig[] => [
  ...getMainRouters(routerKey),
  ...getSideProjectRouters(routerKey),
];

export const IS_OPEN_ROUTERS_ENV = process.env.REACT_APP_ROUTERS === 'open_routers';
