import CountryButtons from 'components/CountryButtons';
import { useContext } from 'react';
import Context from 'context/CountryContext';
import { countries } from 'configs/countries';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/SelectedCountries/StateBudgetDebt';
import styles from '../Page.module.scss';

const StateBudgetDebtSC: React.FC = () => {
  const { currentCountry } = useContext(Context);

  return (
    <>
      <h1>Государственный бюджет и долг</h1>

      <CountryButtons
        countries={[
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
        ]}
      />

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.stateBudget[currentCountry]} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.stateBudgetDynamics[currentCountry]} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.stateBudgetStructure[currentCountry]} />
      </div>
    </>
  );
};

export default StateBudgetDebtSC;
