import React, { useContext } from 'react';
import Context from 'context/SidebarContext';
import { Link } from 'react-router-dom';

import styles from '../Sidebar.module.scss';

const SidebarLink: React.FC<{
  to: string;
  title: string;
  className: string;
  icon?: any;
}> = ({ to, title, className, icon }) => {
  const { setShowSidebar } = useContext(Context);
  const Icon = icon;

  return (
    <Link to={to} className={className} onClick={() => setShowSidebar(false)}>
      {icon && <Icon className={styles.sidebar_item_icon} />}
      <div className={styles.sidebar_item_content}>{title}</div>
    </Link>
  );
};

export default SidebarLink;
