import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  VolumesAccumulatedMutualFDI: [
    {
      title: '',
      chart: 'HMBarChart',
      request: { ...API.investment.accruedMutualFDIVolumeByYearEAEU },
      chartOptions: { ...ChartOptions.HMBarChart.preset2 },
    },
  ],
  chartStructure: [
    {
      title: '',
      chart: 'MBarLineChartTwoY',
      request: { ...API.investment.structureInvestments },
      chartOptions: { ...ChartOptions.MBarLineChartTwoY.preset2 },
    },
  ],
  chartInvestmentsToCountry: [
    {
      title: 'Из страны в ЕАЭС',
      chart: 'SplineChartOneY',
      request: { ...API.investment.investmentsToCountry },
      chartOptions: { ...ChartOptions.SplineChartOneY.preset2 },
    },
    {
      title: 'Из ЕАЭС в другие гос-ва Союза',
      chart: 'SplineChartOneY',
      request: { ...API.investment.investmentsToEAEU },
      chartOptions: { ...ChartOptions.SplineChartOneY.preset3 },
    },
  ],
};
