import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/UnionState/CommonUnionState';
import styles from './CommonStylesUnionState.module.css';

const TradeInServicesUnionState: React.FC = () => {
  // const itemBlockStyle: any = {
  //   marginBottom: '50px',
  //   padding: '30px',
  //   backgroundColor: '#fff',
  //   borderRadius: '12px',
  //   boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08)',
  // };

  return (
    <div>
      <h1>Торговля услугами</h1>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.ServicesTradeYearly} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart
          toggles={ChartConfigs.ServicesTradeYearlyGroupsUS}
          filters={['Доли', 'Объем']}
        />
      </div>

      <div className={styles.page_section}>
        <DynamicChart
          toggles={ChartConfigs.ServicesTradeYearlyWithWorld}
          filters={['Экспорт', 'Импорт', 'Оборот']}
        />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.ServicesTradeTopFive} filters={['Экспорт', 'Импорт']} />
      </div>
    </div>
  );
};

export default TradeInServicesUnionState;
