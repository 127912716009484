import CountryButtons from 'components/CountryButtons';
import DynamicChart from 'components/DynamicChart';
import { countries } from 'configs/countries';
import { ChartConfigs } from 'configs/pages/SelectedCountries/Investments';
import { useContext } from 'react';
import Context from 'context/CountryContext';
import styles from '../Page.module.scss';

const InvestmentSC = () => {
  const { currentCountry } = useContext(Context);

  const countrySet = ['Russia', 'Belarus', 'Armenia', 'Kyrgyzstan', 'Kazakhstan'];

  // в сете ниже отсутствует Туркменистан
  const countrySetSecond = [
    'Azerbaijan',
    'Georgia',
    'Moldova',
    'Tajikistan',
    'Uzbekistan',
    'Ukraine',
  ];

  return (
    <>
      <h1>Инвестиции</h1>
      <CountryButtons
        countries={[
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
          countries.Azerbaijan,
          countries.Georgia,
          countries.Moldova,
          countries.Tajikistan,
          countries.Turkmenistan,
          countries.Uzbekistan,
          countries.Ukraine,
          countries.Abkhazia,
          countries.SouthOssetia,
        ]}
      />

      {countrySet.includes(currentCountry) &&
        currentCountry !== 'Kyrgyzstan' &&
        currentCountry !== 'Ukraine' && (
          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.InvestmentsVolume[currentCountry]} />
          </div>
        )}

      {currentCountry === 'Kazakhstan' && (
        <div className={styles.page_text}>
          <p> * - накопленный итог за текущий год</p>
        </div>
      )}

      {countrySet.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.Investments[currentCountry]} />
        </div>
      )}

      {countrySetSecond.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart
            toggles={ChartConfigs.DirectInvestments[currentCountry]}
            filters={['Актуальные', 'Исторические']}
          />
        </div>
      )}

      {/* Графики Азербайджан */}

      {currentCountry === 'Azerbaijan' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.InvestmentsStructureLastAze} />
        </div>
      )}

      {/* Графики Туркменистан */}

      {currentCountry === 'Turkmenistan' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.InvestmentsTur} />
        </div>
      )}

      {/* Графики Абхазия */}

      {currentCountry === 'Abkhazia' && (
        <>
          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.InvestmentsSocEconomAbh} />
          </div>

          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.InvestmentsInvestProgAbh} />
          </div>
        </>
      )}

      {/* Графики Южная Осетия */}

      {currentCountry === 'SouthOssetia' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.YearlyRuInvestmentSO} />
        </div>
      )}
    </>
  );
};

export default InvestmentSC;
