import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  stateBudget: {
    Russia: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.budgetRecentRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset5 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.budgetYearlyRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset5 },
      },
    ],
    Belarus: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.budgetRecentBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset5 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.budgetYearlyBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset5 },
      },
    ],
    Armenia: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.budgetRecentArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset5 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.budgetYearlyArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset5 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.budgetRecentKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset5 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.budgetYearlyKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset5 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.budgetRecentKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset5 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.budgetYearlyKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset5 },
      },
    ],
  },
  stateBudgetDynamics: {
    Russia: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.debtDynamicsRecentRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.debtDynamicsYearlyRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
    Belarus: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.debtDynamicsRecentBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.debtDynamicsYearlyBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
    Armenia: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.debtDynamicsRecentArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.debtDynamicsYearlyArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.debtDynamicsRecentKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.debtDynamicsYearlyKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.debtDynamicsRecentKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.debtDynamicsYearlyKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
      },
    ],
  },
  stateBudgetStructure: {
    Russia: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.debtStructureRecentRus },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.debtStructureYearlyRus },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset4 },
      },
    ],
    Belarus: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.debtStructureRecentBel },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.debtStructureYearlyBel },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset4 },
      },
    ],
    Armenia: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.debtStructureRecentArm },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.debtStructureYearlyArm },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset4 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.debtStructureRecentKyr },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.debtStructureYearlyKyr },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset4 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Актуальные',
        request: { ...API.stateDebt.debtStructureRecentKaz },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.stateDebt.debtStructureYearlyKaz },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset4 },
      },
    ],
  },
};
