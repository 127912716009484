import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/UnionState/CommonUnionState';
import DownloadPDF from 'assets/img/pdf.svg';
import styles from './CommonStylesUnionState.module.css';

import qualitativeAssessmentMethodology from './qualitativeAssessmentMethodology.pdf';
import assessingEconomicEffectsMethodology from './assessingEconomicEffectsMethodology.pdf';

const EconomicEffectsProgramsUnionState: React.FC = () => {
  const itemBlockStyle: any = {
    marginBottom: '50px',
    padding: '30px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08)',
  };

  return (
    <div className={styles.page_text}>
      <h1>Экономические эффекты и ход реализации Союзных программ</h1>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.EconomicEffectsUS} />
      </div>

      <div style={itemBlockStyle}>
        <p>
          В случае полной реализации 28 Союзных программ будут наблюдаться следующие эффекты для
          экономик России и Беларуси (от уровня 2021 г.):
        </p>
        <p style={{ fontWeight: `bold` }}>• Для Беларуси:</p>
        <p>– Дополнительный рост реального ВВП Беларуси составит 1,81% или 1,23 млрд долл.;</p>
        <p>– При таком росте ВВП безработица (по методологии МОТ) в стране снизится на 0,4 п.п.;</p>
        <p>– Экспорт товаров на рынок России увеличится на 3% или 495 млн долл.;</p>
        <p>– Экспорт услуг на рынок России увеличится на 4,5% или 70 млн долл.;</p>
        <p>– Рост денежных переводов из России в Беларусь вырастет на 4,5% или 15 млн дол.;</p>
        <p>– Индекс потребительских цен сократиться на 0,5 п.п.;</p>
        <p style={{ fontWeight: `bold` }}>• Для России:</p>
        <p>– Дополнительный рост реального ВВП России составит 0,054% или 0,96 млрд долл.;</p>
        <p>
          – При таком росте ВВП безработица (по методологии МОТ) в стране снизится на 0,05 п.п.;
        </p>
        <p>– Экспорт товаров на рынок Беларуси вырастет на 2,2% или 520 млн долл.;</p>
        <p>– Экспорт услуг на рынок Беларуси увеличится на 2,5% или 40 млн долл.;</p>
        <p>– Рост денежных переводов из Беларуси в Россию вырастет на 4% или 13 млн дол.;</p>
        <p>– Индекс потребительских цен сократиться на 0,05 п.п.</p>
      </div>

      <div style={itemBlockStyle}>
        <p>
          Согласно результатам расчетов, наибольший относительный выигрыш для Республики Беларусь
          будет обеспечен Союзными программами № 21 (единые правила госзаказа), №11 (гармонизация
          налогового и таможенного законодательства) и №13-14 (интеграции информационных систем
          транспортного контроля и унификация регулирования транспортного рынка). Для России –
          программами № 20 (формирование единой промышленной политики), №19 (формирование единой
          аграрной политики) и №13-14.
        </p>
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.QualityAssessmentUS} />
      </div>

      <div style={itemBlockStyle}>
        <p style={{ fontWeight: `bold` }}>
          Результаты качественной оценки выполнения Союзных программ показали, что по состоянию на
          28.11.2022 г. они выполнены на 58,4%. С учетом расставления весов, с точки зрения вклада
          программ в достижение экономических эффектов от их полной реализации, прогресс составил
          60%.{' '}
        </p>
        <p>Категоризация программ по степени реализации представлена ниже:</p>
        <p>
          – <b>Полностью выполненными считаются 2 Союзные программы:</b> Союзная программа по
          интеграции информационных систем государственных контролирующих органов по
          прослеживаемости товаров; Программа мер (Союзная программа) по гармонизации валютного
          регулирования и валютного контроля.
        </p>
        <p>
          – <b>Высокая степень реализации (от 70% и выше) была присвоена 9 программам:</b> Союзной
          программе по гармонизации в области обеспечения информационной безопасности в финансовой
          сфере (70%); Программе в области защиты прав потребителей финансовых услуг и инвесторов
          (70%); Программе по гармонизации налогового законодательства и сотрудничества в таможенной
          сфере (90%), а также программам по интеграции информационных систем государственных
          контролирующих органов системы ветеринарного и фитосанитарного контроля (75%), по
          реализации информационных систем транспортного контроля государственных контролирующих
          органов (85%), развитию атомной энергетики (80%), по формированию единой промышленной
          политики (70%), в области единых правил конкуренции (90%) и в бухгалтерской сфере (95%);
        </p>
        <p>
          – <b>Средняя степень реализации (от 30% до 69%) была присвоена 17 программам,</b> включая
          те, что направлены на формирование объединенных рынков газа, нефти и нефтепродуктов,
          электроэнергии, единой аграрной и промышленной политик;
        </p>
        <p>
          – <b>Относительно невысокая степень выполнения (ниже 30%).</b> Программ в данной категории
          не осталось.
        </p>
        <p>
          –{' '}
          <b>
            В большинстве случаев незначительный прогресс по последней группе программ вызван более
            длительными сроками реализации, которые пока еще не наступили.
          </b>
        </p>
      </div>

      <div className={styles.page_section}>
        <div className={styles.sub_section}>
          <div className={styles.tile}>
            <div className={styles.tile_title}>Методология оценки экономических эффектов</div>
            <a
              href={assessingEconomicEffectsMethodology}
              download="Методология оценки экономических эффектов.pdf"
              className={styles.downloadPDF_link}
            >
              <span className={styles.downloadPDF_link_img}>
                <img src={DownloadPDF} alt="pdf download" />
              </span>
              <span>Скачать данные</span>
            </a>
          </div>
        </div>
        <div className={styles.sub_section}>
          <div className={styles.tile}>
            <div className={styles.tile_title}>
              Методология качественной оценки реализации Союзных программ
            </div>
            <a
              href={qualitativeAssessmentMethodology}
              download="Качественная оценка (методология).pdf"
              className={styles.downloadPDF_link}
            >
              <span className={styles.downloadPDF_link_img}>
                <img src={DownloadPDF} alt="pdf download" />
              </span>
              <span>Скачать данные</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EconomicEffectsProgramsUnionState;
