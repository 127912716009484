import React, { useContext } from 'react';
import CountryButtons from 'components/CountryButtons';

import { countries } from 'configs/countries';
import Context from 'context/CountryContext';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/public/TradeInGoods';
import { Tooltip } from 'frontend-react-library/lib/ui/Tooltip';

import styles from '../Page.module.scss';

const TradeInGoods: React.FC = () => {
  const { currentCountry } = useContext(Context);

  return (
    <>
      <h1 id="_1">Торговля товарами</h1>

      {process.env.REACT_APP_ROUTERS === 'full_routers' && (
        <>
          {' '}
          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.commoditiesTradeYearlyWithEAEU} />
          </div>
          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.CommoditiesTradeDynamicEAEU} />
          </div>
          {currentCountry === 'EAEU' && (
            <div className={styles.page_section}>
              <div className={styles.notice}>
                <ul>
                  <li>
                    Как во внешней торговле{' '}
                    <Tooltip text="Евразийский экономический союз">
                      <span>ЕАЭС</span>
                    </Tooltip>{' '}
                    с третьими странами, так и во взаимной доминируют минеральные продукты. Вместе с
                    тем доля рассматриваемой группы товаров более значительна в торговле{' '}
                    <Tooltip text="Евразийский экономический союз">
                      <span>ЕАЭС</span>
                    </Tooltip>{' '}
                    с внешними партнерами.
                  </li>
                  <li>
                    Из других важных отличий стоит упомянуть продовольственную группу товаров. Доля
                    продовольствия во взаимной торговле стран{' '}
                    <Tooltip text="Евразийский экономический союз">
                      <span>ЕАЭС</span>
                    </Tooltip>{' '}
                    составляет более 18% в товарной структуре, в то время как с внешними партнерами
                    ее доля равна 10%.
                  </li>
                </ul>
              </div>
            </div>
          )}
        </>
      )}

      {process.env.REACT_APP_ROUTERS === 'open_routers' && (
        <>
          <CountryButtons
            countries={[
              countries.EAEU,
              countries.Armenia,
              countries.Belarus,
              countries.Kazakhstan,
              countries.Kyrgyzstan,
              countries.Russia,
            ]}
          />

          <div className={styles.page_section}>
            <DynamicChart
              toggles={ChartConfigs.Dynamics[currentCountry]}
              filters={['Актуальные', 'Исторические']}
            />
          </div>

          {currentCountry === 'EAEU' && (
            <div className={styles.page_section}>
              <DynamicChart toggles={ChartConfigs.ShareMemberStatesEAEU} />
            </div>
          )}

          <div className={styles.page_section}>
            <DynamicChart toggles={ChartConfigs.MutualTradeGrowth[currentCountry]} />
          </div>

          {currentCountry !== 'Russia' && (
            <div className={styles.page_section}>
              <DynamicChart
                toggles={ChartConfigs.Top10ByValueVolume[currentCountry]}
                filters={['За 2022 год', 'По годам']}
              />
            </div>
          )}

          {currentCountry === 'Russia' && (
            <div className={styles.page_section}>
              <DynamicChart toggles={ChartConfigs.Top10ByValueVolumeRus[currentCountry]} />
            </div>
          )}

          {currentCountry !== 'Kyrgyzstan' && (
            <div className={styles.page_section}>
              <DynamicChart
                toggles={ChartConfigs.ProductGroups[currentCountry]}
                filters={['Доли', 'Объем']}
              />
            </div>
          )}

          {currentCountry === 'Kyrgyzstan' && (
            <div className={styles.page_section}>
              <DynamicChart
                toggles={ChartConfigs.ProductTradeGroupsShareKyr}
                filters={['Доли', 'Объем']}
              />
            </div>
          )}

          {currentCountry === 'EAEU' && (
            <div className={styles.page_section}>
              <div className={styles.notice}>
                <ul>
                  <li>
                    Как во внешней торговле{' '}
                    <Tooltip text="Евразийский экономический союз">
                      <span>ЕАЭС</span>
                    </Tooltip>{' '}
                    с третьими странами, так и во взаимной доминируют минеральные продукты. Вместе с
                    тем доля рассматриваемой группы товаров более значительна в торговле{' '}
                    <Tooltip text="Евразийский экономический союз">
                      <span>ЕАЭС</span>
                    </Tooltip>{' '}
                    с внешними партнерами.
                  </li>
                  <li>
                    Из других важных отличий стоит упомянуть продовольственную группу товаров. Доля
                    продовольствия во взаимной торговле стран{' '}
                    <Tooltip text="Евразийский экономический союз">
                      <span>ЕАЭС</span>
                    </Tooltip>{' '}
                    составляет более 18% в товарной структуре, в то время как с внешними партнерами
                    ее доля равна 10%.
                  </li>
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TradeInGoods;
