import React, { useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import ChartErrorIMG from 'assets/img/chart-error.png';

import classnames from 'classnames';
import styles from './ChartLoader.module.scss';

interface StatusInterface {
  data: any;
  error: any;
  loading: boolean;
}

const ChartError: React.FC<{ status: StatusInterface; repeatRequest: Function }> = ({
  status,
  repeatRequest,
}) => {
  const [disabled, setDisabled] = useState(false);

  const handler = () => {
    setDisabled(true);
    repeatRequest(Math.random());
    setTimeout(() => setDisabled(false), 5000);
  };

  return (
    <div className={styles.chart_error_wrapper}>
      <Skeleton animation="wave" variant="rect" height={400} className={styles.chart_loader_rect} />
      <div className={styles.chart_error}>
        <img src={ChartErrorIMG} alt="error-img" />
        <div className={styles.error_message}>
          <p>Error: {status.error.request.status}</p>
          <p>Что-то пошло не так</p>
          <button
            type="button"
            onClick={() => handler()}
            className={
              !disabled
                ? styles.repeat_request
                : classnames(styles.repeat_request, styles.disabled_but)
            }
            disabled={disabled}
          >
            Повторить запрос
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChartError;
