import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  GDPdynamics: [
    // {
    //   title: 'Актуальные',
    //   chart: 'SplineBarChart',
    //   request: { ...API.GDP.GDPvolumeByQuarterEAEU },
    //   chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    // },
    {
      title: 'Исторические',
      chart: 'SplineBarChart',
      request: { ...API.GDP.GDPvolumeByYearEAEU },
      chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
    },
  ],
  GDPstructure: [
    {
      title: 'Актуальные',
      chart: 'BarChart',
      request: { ...API.GDP.GDPByActLastEAEU },
      chartOptions: { ...ChartOptions.BarChart.preset6 },
    },
    {
      title: 'Исторические',
      chart: 'HBarChart',
      request: { ...API.GDP.GDPByActEAEU },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
  ],
  GDPrealDyn: [
    {
      title: '',
      chart: 'SplineChartOneY',
      request: { ...API.GDP.DynamicsOfGDP },
      chartOptions: { ...ChartOptions.SplineChartOneY.preset1, markers: true },
    },
  ],
  GDPtotalDyn: [
    // {
    //   title: 'Актуальные',
    //   chart: 'SplineBarChart',
    //   request: { ...API.industrialProduction.productionQuarterEAEU },
    //   chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
    // },
    {
      title: 'Исторические',
      chart: 'SplineBarChart',
      request: { ...API.industrialProduction.productionYearlyEAEU },
      chartOptions: { ...ChartOptions.SplineBarChart.preset1 },
    },
  ],

  AgricultureQuarterlyYearlyEAEU: [
    // {
    //   title: 'Актуальные',
    //   request: { ...API.GDP.AgricultureQuarterlyEAEU },
    //   chart: 'SplineBarChart',
    //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    // },
    {
      title: 'Исторические',
      request: { ...API.GDP.AgricultureYearlyEAEU },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],
};
