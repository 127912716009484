import CountryButtons from 'components/CountryButtons';
import { useContext } from 'react';
import Context from 'context/CountryContext';
import { countries } from 'configs/countries';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/SelectedCountries/LaborAndIncome';
import styles from '../Page.module.scss';

const LaborAndIncomeSC = () => {
  const { currentCountry } = useContext(Context);

  return (
    <>
      <h1>Труд и доходы населения</h1>

      <CountryButtons
        countries={[
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
          countries.Abkhazia,
          countries.SouthOssetia,
        ]}
      />

      {(currentCountry === 'Armenia' ||
        currentCountry === 'Belarus' ||
        currentCountry === 'Kazakhstan' ||
        currentCountry === 'Kyrgyzstan' ||
        currentCountry === 'Russia') && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.Unemployment[currentCountry]} />
        </div>
      )}

      {/* Графики Абхазия */}

      {currentCountry === 'Abkhazia' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.YearlySalaryAbh} />
        </div>
      )}

      {/* Графики Южная Осетия */}

      {currentCountry === 'SouthOssetia' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.YearlySalarySO} />
        </div>
      )}
    </>
  );
};

export default LaborAndIncomeSC;
