import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/public/Investments';
// import { Tooltip } from 'frontend-react-library/lib/ui/Tooltip';
import styles from '../Page.module.scss';

const Investment: React.FC = () => {
  return (
    <>
      <h1 id="_1">Динамика прямых иностранных инвестиций</h1>

      {/* 

      <div className={styles.sub_section}>
        <DynamicChart toggles={ChartConfigs.Structure} />
      </div>

      <div className={styles.notice}>
        <ul>
          <li>
            Объемы взаимных потоков инвестиций в рамках{' '}
            <Tooltip text="Евразийский экономический союз">
              <span>ЕАЭС</span>
            </Tooltip>{' '}
            ежегодно составляют от 1 до 2 млрд долл. США. На фоне совокупного объема входящих{' '}
            <Tooltip text="Прямые иностранные инвестиции">
              <span>ПИИ</span>
            </Tooltip>{' '}
            стран-членов{' '}
            <Tooltip text="Евразийский экономический союз">
              <span>ЕАЭС</span>
            </Tooltip>{' '}
            эта цифра незначительна.
          </li>
          <li>
            Высокий уровень инвестиционного взаимодействия в рамках{' '}
            <Tooltip text="Евразийский экономический союз">
              <span>ЕАЭС</span>
            </Tooltip>{' '}
            характерен только для Армении и Беларуси.
          </li>
          <li>
            Доля накопленных в Армении{' '}
            <Tooltip text="Прямые иностранные инвестиции">
              <span>ПИИ</span>
            </Tooltip>{' '}
            из стран{' '}
            <Tooltip text="Евразийский экономический союз">
              <span>ЕАЭС</span>
            </Tooltip>{' '}
            превышает 40% в их общем объеме, в Беларуси – более 30%.
          </li>
          <li>
            Следом идет Кыргызстан, доля{' '}
            <Tooltip text="Евразийский экономический союз">
              <span>ЕАЭС</span>
            </Tooltip>{' '}
            в накопленных{' '}
            <Tooltip text="Прямые иностранные инвестиции">
              <span>ПИИ</span>
            </Tooltip>{' '}
            страны составляет более 15%. Большая часть инвестиций была сделана Россией, за первые
            два года членства Кыргызстан получил от России более 770 млн долл. США для обустройства
            таможенной границы.
          </li>
        </ul>
      </div> */}

      <div className={styles.sub_section}>
        <DynamicChart toggles={ChartConfigs.Volume} />
      </div>
    </>
  );
};

export default Investment;
