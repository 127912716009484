import { RouteConfig } from 'configs/types';
import AboutProjectUnionStatePublic from 'pages/UnionState/PublicPage/AboutProjectUnionStatePublic';
// import TradeInServicesUnionState from 'pages/UnionState/FullPage/TradeInServicesUnionState';
import MacroeconomicIndicatorsUnionState from 'pages/UnionState/PublicPage/MacroeconomicIndicatorsUnionState';
import EconomicEffectsProgramsUnionState from 'pages/UnionState/PublicPage/EconomicEffectsProgramsUnionState';
import TradeInGoodsUnionState from 'pages/UnionState/PublicPage/TradeInGoodsUnionState';
// import InvestmentUnionState from 'pages/UnionState/FullPage/InvestmentsUnionState';

// ICONS
import { ReactComponent as AboutProjectIcon } from 'assets/icons/nav-about.svg';
import { ReactComponent as EconomicEffectsProgramsIcon } from 'assets/icons/economic-effects-programs.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/dollar.svg';
import { ReactComponent as TradeGoodsIcon } from 'assets/icons/nav-trade-goods.svg';
// import { ReactComponent as TradeServicesIcon } from 'assets/icons/nav-trade-services.svg';
// import { ReactComponent as InvestmentIcon } from 'assets/icons/nav-investment.svg';

/*
    > path - путь для роутинга;
    > title - заголовок ссылки в сайдбаре;
    > search_title - заголовок по которому осуществляется поиск в компоненте SearchBar. 
        Если search_title отсутствует, поиск осуществляется по полю title
    > mainDir - путь родительских вкладок. Записывается в виде: dir1/dir2/dir3/;
    > component - компонент страницы;
    > items - массив объектов оглавления раздела:
        >> path - ссылка соответствующая id элемента (заголовка, подзаголовка) на странице;
        >> title - заголовок ссылки.
        >> nested_items - вложенные разделы элемента оглавления (объекта) items;
*/

export const SIDE_PROJECT_OPEN_ROUTERS: RouteConfig[] = [
  {
    path: '/about_union_state',
    title: 'О Союзном государстве',
    icon: AboutProjectIcon,
    mainDir: '/',
    documentTitle: 'О проекте',
    component: AboutProjectUnionStatePublic,
  },
  {
    path: '/union_state_economic_effects_programs',
    title: 'Экономические эффекты и ход реализации Союзных программ',
    icon: EconomicEffectsProgramsIcon,
    mainDir: '/',
    documentTitle: 'Союзное государство - Экономические эффекты',
    component: EconomicEffectsProgramsUnionState,
  },
  {
    path: '/union_state_macroeconomic_indicators',
    title: 'Макроэкономические показатели',
    icon: DollarIcon,
    mainDir: '/',
    documentTitle: 'Союзное государство - Макроэкономические показатели',
    component: MacroeconomicIndicatorsUnionState,
  },
  {
    path: '/union_state_trade_in_goods',
    title: 'Торговля товарами',
    icon: TradeGoodsIcon,
    mainDir: '/',
    documentTitle: 'Союзное государство - Торговля товарами',
    component: TradeInGoodsUnionState,
  },
  // {
  //   path: '/union_state_trade_in_services',
  //   title: 'Торговля услугами',
  //   icon: TradeServicesIcon,
  //   mainDir: '/',
  //   documentTitle: 'Союзное государство - Торговля услугами',
  //   component: TradeInServicesUnionState,
  // },
  // {
  //   path: '/union_state_investments',
  //   title: 'Инвестиции',
  //   icon: InvestmentIcon,
  //   mainDir: '/',
  //   documentTitle: 'Союзное государство - Инвестиции',
  //   component: InvestmentUnionState,
  // },
];
