import React, { ReactElement, useEffect } from 'react';
import { ReactComponent as CloseScreenIMG } from 'assets/icons/close.svg';
import styles from './Modal.module.scss';

const Modal: React.FC<{ isModalVisible: boolean; onClose: Function; children: ReactElement }> = ({
  isModalVisible,
  onClose,
  children,
}) => {
  useEffect(() => {
    if (isModalVisible) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    }
  }, [isModalVisible]);

  // c помощью useEffect цепляем обработчик к нажатию клавиш
  useEffect(() => {
    // создаем обработчик нажатия клавиши Esc
    const onKeydown = ({ key }) => {
      if (key === 'Escape') onClose();
    };
    document.addEventListener('keydown', onKeydown);
    return () => document.removeEventListener('keydown', onKeydown);
  }, []);

  // если компонент невидим, то не отображаем его
  if (!isModalVisible) return null;

  // или возвращаем верстку модального окна
  return (
    <div
      role="button"
      aria-label="modal"
      tabIndex={0}
      className={styles.modal}
      // onClick={() => onClose()}
      // onKeyPress={() => onClose()}
    >
      <div
        role="button"
        aria-label="modal"
        tabIndex={0}
        onKeyPress={(e) => e.stopPropagation()}
        className={styles.modal_dialog}
        onClick={(e) => e.stopPropagation()}
      >
        <span
          role="button"
          aria-label="modal"
          tabIndex={0}
          onClick={() => onClose()}
          onKeyPress={() => onClose()}
          className={styles.modal_close}
        >
          <CloseScreenIMG />
        </span>

        <div className={styles.modal_body}>
          <div className={styles.modal_content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
