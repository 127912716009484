import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  Unemployment: {
    Russia: [
      {
        title: 'Актуальные',
        request: { ...API.unemployment.unemploymentQuarterRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.unemployment.unemploymentYearlyRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Armenia: [
      {
        title: 'Актуальные',
        request: { ...API.unemployment.unemploymentQuarterArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.unemployment.unemploymentYearlyArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Belarus: [
      {
        title: 'Актуальные',
        request: { ...API.unemployment.unemploymentQuarterBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.unemployment.unemploymentYearlyBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Актуальные',
        request: { ...API.unemployment.unemploymentQuarterKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.unemployment.unemploymentYearlyKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Актуальные',
        request: { ...API.unemployment.unemploymentQuarterKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.unemployment.unemploymentYearlyKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
  },

  // Абхазия

  YearlySalaryAbh: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.investment.YearlySalaryAbh },
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],

  // Южная Осетия

  YearlySalarySO: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.investment.YearlySalarySO },
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],
};
