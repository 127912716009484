import React from 'react';
import cl from './AppLoader.module.scss';

export const AppLoader: React.FC<{ isLoading: Boolean; children: React.ReactNode }> = ({
  isLoading,
  children,
}) => {
  if (!isLoading) {
    // eslint-disable-next-line
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <div className={cl.wrapper}>
      <div className={cl.loaderWrapper}>
        <div className={cl.loader} />
      </div>
      {children}
    </div>
  );
};
