import React, { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { RouteConfig } from 'configs/types';
import ProjectMainDir from 'context/MainContext';
import { getMainRouters, getSideProjectRouters, RouterKey } from '../configs/routers';
import styles from './Searchbar.module.scss';

declare const process: {
  env: {
    REACT_APP_ROUTERS: RouterKey;
  };
};

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

const SearchBar: React.FC = () => {
  const [filteredData, setFilteredData] = useState<Array<RouteConfig>>([]);
  const [wordEntered, setWordEntered] = useState('');
  const [showData, setShowData] = useState(false);
  const { isMainDir } = useContext(ProjectMainDir);

  const routersConfig = useMemo(
    () =>
      isMainDir
        ? getMainRouters(process.env.REACT_APP_ROUTERS)
        : getSideProjectRouters(process.env.REACT_APP_ROUTERS),
    [isMainDir]
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isSearchBarFiltered = showData && filteredData.length !== 0;

  useOnClickOutside(wrapperRef, () => setShowData(false));

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchWord = event.target.value;

    setShowData(true);
    setWordEntered(searchWord);

    const newFilter = routersConfig.filter((value) => {
      return (
        value?.search_title?.toLowerCase().includes(searchWord.toLowerCase()) ||
        value.title.toLowerCase().includes(searchWord.toLowerCase())
      );
    });

    if (searchWord === '') {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
  };

  const clearInput = (flag: boolean) => {
    setFilteredData([]);
    setWordEntered('');

    if (flag) {
      inputRef.current?.focus();
    }

    if (!flag) {
      setShowData(false);
    }
  };

  return (
    <div className={styles.searchBar} ref={wrapperRef}>
      <div className={classnames(styles.searchBarInputs, { [styles.searchBarActive]: showData })}>
        <input
          type="text"
          placeholder="Поиск по разделам"
          value={wordEntered}
          onChange={handleFilter}
          ref={inputRef}
          onFocus={() => setShowData(true)}
          className={styles.searchBarInput}
        />
        {wordEntered.length !== 0 ? (
          <CloseIcon
            className={styles.searchIcon}
            style={{ cursor: 'pointer' }}
            onClick={() => clearInput(true)}
          />
        ) : (
          <SearchIcon className={styles.searchIcon} />
        )}
      </div>

      <div
        className={classnames(styles.searchBarDataResults, {
          [styles.showResult]: isSearchBarFiltered,
        })}
      >
        {filteredData.map((value) => {
          return (
            <Link
              className={styles.dataItem}
              key={value.path + value.title}
              to={value.path}
              onClick={() => clearInput(false)}
            >
              {/* @ts-ignore */}
              <p>{value?.search_title || value.title}</p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SearchBar;
