import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/EAEU/MonitoringObstacles';

import styles from '../Page.module.scss';

const MonitoringObstacles: React.FC = () => {
  return (
    <>
      <h1 id="_1">Мониторинг препятствий в ЕАЭС</h1>

      <div className={styles.page_section}>
        <div className={styles.sub_section}>
          <DynamicChart toggles={ChartConfigs.Barriers} />
        </div>
        <div className={styles.notice}>
          <p>
            За 2016-2023 (июль) гг. государства-члены ЕАЭС устранили 83 препятствия. Например:
            дополнительные требования Казахстана по отношению к ТР ТС 018/2011 по оснащению грузовых
            авто и авто для перевозки пассажиров; непредоставление национального режима при
            размещении заказов на выполнение предпроектных, проектных работ в Республике Беларусь.
          </p>
          <br />
          <p>
            По состоянию на сегодняшний день, действует 3 классифицированных барьера, до 2025 г. их
            предстоит устранить. Среди них:
          </p>
          <br />
          <li>
            Отсутствие возможности открытия филиала юридического лица государства-члена Союза в
            Республике Беларусь;
          </li>
          <br />
          <li>
            Введение Республикой Беларусь ограничений, предусматривающих выдачу ветеринарных
            разрешений и переоформление ветеринарных сертификатов для ввоза на территорию Республики
            Беларусь
          </li>
          <br />
          <li>
            Установление механизма «минимального уровня контрольных цен» на товары, ввозимые на
            территорию Кыргызской Республики, в том числе с территории государств-членов.”
          </li>
        </div>
      </div>
    </>
  );
};

export default MonitoringObstacles;
