const migrationFlows = {
  migrationGrowthByQuarterLastYear: {
    method: 'GET',
    url: 'Russia/macro/quarterly_migration_growth_from_EAEU/',
  },
  migrationVolumeByYear: {
    method: 'GET',
    url: 'Russia/macro/yearly_migration_from_EAEU/',
  },
};

export default migrationFlows;
