import DynamicChart from 'components/DynamicChart';
import styles from '../Page.module.scss';
import { ChartConfigs } from 'configs/pages/SelectedCountries/Transport';

const TransportSC = () => {
  return (
    <>
      <h1>
        Перспективные направления и маршруты для перенаправления российских товарных потоков и
        оценка потенциала увеличения грузооборота
      </h1>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.redirect_commodities_tradeRusCIS} />
      </div>
      <div className={styles.notice}>
        <p>
          Нереализованный экспортный потенциал России для перенаправления поставок товаров на рынки
          стран СНГ составляет 11,4 млн тонн. Наибольшие объемы могут быть направлены в Узбекистан –
          7,7 млн тонн, Казахстан – 5,4 млн тонн, Азербайджан – 2,0 млн тонн, Армению – 1,7 млн
          тонн.
        </p>
        <br />
        <p>
          Если рассматривать в контексте маршрутов, то при реализации указанного потенциала
          физический объем экспорта России может увеличиться:
          <li>в 1,5 раза в Азербайджан;</li>
          <li>
            более чем в 1,4 раза в Армению, в основном с задействованием пункта пропуска на
            российско-грузинской границе Верхний Ларс;
          </li>{' '}
          <li>
            почти на треть в Казахстан и через его территорию в Кыргызстан, Таджикистан,
            Туркменистан (частично) и Узбекистан.
          </li>
        </p>
      </div>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.redirect_commodities_tradeRusWorld} />
      </div>
      <div className={styles.notice}>
        <p>
          Наибольший экспортный потенциал России для перенаправления экспорта товаров на рынки
          третьих стран – в поставках в в Китай (90,7 млн тонн), Турцию (48,3 млн тонн), Индию (12,3
          млн тонн), Пакистан (4,4 млн тонн), Иран (4,2 млн тонн), Малайзию (3,9 млн тонн) и Вьетнам
          (3,9 млн тонн).{' '}
        </p>
        <br />
        <p>
          Всего нереализованный экспортный потенциал России в приоритетные третьи страны,
          определенные для НИР, составляет 175,2 млн тонн. Вместе с тем большая часть поставок в
          указанные страны осуществляется без задействования территорий стран СНГ. В этом случае
          более показательным является прогноз динамики роста, которая в случае реализации
          указанного потенциала, будет на уровне около 45%.
        </p>
      </div>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.forecast_trade_flowExport} />
      </div>
      <div className={styles.notice}>
        <p>
          Общий объем экспорта по указанным четырем коридорам может вырасти в 2030 г. в 1,2 раза (до
          864,7 млн тонн) по отношению к 2022 году.
        </p>
        <br />
        <p>
          Наибольший рост – в 3 раза (до 29,2 млн тонн) предполагается в экспорте по МТК «Север –
          Юг» (Западный маршрут – до 17,67 млн тонн, Восточный маршрут – до 3,41 млн тонн,
          Транскаспийский маршрут – до 8,16 млн тонн).
        </p>
        <br />
        <p>
          По Восточному направлению прогнозируется рост в 1,4 раза (до 310,1 млн тонн), по АЧБ – в
          1,3 (до 233,1 млн тонн).
        </p>
        <br />
        <p>
          По Западному направлению экспорт может незначительно сократиться – на 3% (до 292,3 млн
          тонн).
        </p>
      </div>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.forecast_trade_flowImport} />
      </div>
      <div className={styles.notice}>
        <p>
          Общий объем импорта по рассматриваемым четырем коридорам в 2030 г. может увеличиться в 1,2
          раза (до 110,6 млн тонн) по отношению к 2022 году.
        </p>
        <br />
        <p>
          Наибольший рост – в 2,5 раза (до 7,8 млн тонн) предполагается, как и при экспорте, по МТК
          «Север – Юг» (Западный маршрут – до 5,9 млн тонн, Восточный маршрут – до 0,2 млн тонн,
          Транскаспийский маршрут – до 1,7 млн тонн).
        </p>
        <br />
        <p>
          По Восточному направлению прогнозируется рост в 1,4 раза (до 71,2 млн тонн), по АЧБ – в
          1,3 (до 10,9 млн тонн).
        </p>
        <br />
        <p>По Западному направлению прогнозируется снижение на четверть (до 20,7 млн тонн).</p>
      </div>
    </>
  );
};

export default TransportSC;
