import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/EAEU/Investment';

import styles from '../Page.module.scss';

const Investment: React.FC = () => {
  return (
    <>
      <h1 id="_1">Инвестиции</h1>

      {/* <div className={styles.page_section}>
       
        <div className={styles.notice}>
          <ul>
            <li>
              Объемы взаимных потоков инвестиций в рамках ЕАЭС ежегодно составляют от 1 до 2 млрд
              долл. США. На фоне совокупного объема входящих ПИИ стран-членов ЕАЭС эта цифра
              незначительна.
            </li>
            <li>
              Высокий уровень инвестиционного взаимодействия в рамках ЕАЭС характерен только для
              Армении и Беларуси.
            </li>
            <li>
              Доля накопленных в Армении ПИИ из стран ЕАЭС превышает 40% в их общем объеме, в
              Беларуси – более 30%.
            </li>
            <li>
              Следом идет Кыргызстан, доля ЕАЭС в накопленных ПИИ страны составляет более 15%.
              Большая часть инвестиций была сделана Россией, за первые два года членства Кыргызстан
              получил от России более 770 млн долл. США для обустройства таможенной границы.
            </li>
          </ul>
        </div>
      </div> */}

      {/* <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.chartStructure} />
      </div> */}

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.VolumesAccumulatedMutualFDI} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.chartInvestmentsToCountry} />
      </div>
    </>
  );
};

export default Investment;
