const stateDebt = {
  debtDynamicsRecentArm: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_debt_dynamics_to_GDP/',
    params: { region: 'Армения' },
  },
  debtDynamicsYearlyArm: {
    method: 'GET',
    url: 'EAEU/macro/yearly_debt_dynamics_to_GDP/',
    params: { region: 'Армения' },
  },
  debtDynamicsRecentBel: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_debt_dynamics_to_GDP/',
    params: { region: 'Беларусь' },
  },
  debtDynamicsYearlyBel: {
    method: 'GET',
    url: 'EAEU/macro/yearly_debt_dynamics_to_GDP/',
    params: { region: 'Беларусь' },
  },
  debtDynamicsRecentKaz: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_debt_dynamics_to_GDP/',
    params: { region: 'Казахстан' },
  },
  debtDynamicsYearlyKaz: {
    method: 'GET',
    url: 'EAEU/macro/yearly_debt_dynamics_to_GDP/',
    params: { region: 'Казахстан' },
  },
  debtDynamicsRecentKyr: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_debt_dynamics_to_GDP/',
    params: { region: 'Кыргызстан' },
  },
  debtDynamicsYearlyKyr: {
    method: 'GET',
    url: 'EAEU/macro/yearly_debt_dynamics_to_GDP/',
    params: { region: 'Кыргызстан' },
  },
  debtDynamicsRecentRus: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_debt_dynamics_to_GDP/',
    params: { region: 'Россия' },
  },
  debtDynamicsYearlyRus: {
    method: 'GET',
    url: 'EAEU/macro/yearly_debt_dynamics_to_GDP/',
    params: { region: 'Россия' },
  },
  debtStructureRecentArm: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_debt_struct/',
    params: { region: 'Армения' },
  },
  debtStructureYearlyArm: {
    method: 'GET',
    url: 'EAEU/macro/yearly_debt_struct/',
    params: { region: 'Армения' },
  },
  debtStructureRecentBel: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_debt_struct/',
    params: { region: 'Беларусь' },
  },
  debtStructureYearlyBel: {
    method: 'GET',
    url: 'EAEU/macro/yearly_debt_struct/',
    params: { region: 'Беларусь' },
  },
  debtStructureRecentKaz: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_debt_struct/',
    params: { region: 'Казахстан' },
  },
  debtStructureYearlyKaz: {
    method: 'GET',
    url: 'EAEU/macro/yearly_debt_struct/',
    params: { region: 'Казахстан' },
  },
  debtStructureRecentKyr: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_debt_struct/',
    params: { region: 'Кыргызстан' },
  },
  debtStructureYearlyKyr: {
    method: 'GET',
    url: 'EAEU/macro/yearly_debt_struct/',
    params: { region: 'Кыргызстан' },
  },
  debtStructureRecentRus: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_debt_struct/',
    params: { region: 'Россия' },
  },
  debtStructureYearlyRus: {
    method: 'GET',
    url: 'EAEU/macro/yearly_debt_struct/',
    params: { region: 'Россия' },
  },
  budgetRecentArm: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_budget_struct_to_GDP/',
    params: { region: 'Армения' },
  },
  budgetYearlyArm: {
    method: 'GET',
    url: 'EAEU/macro/yearly_budget_struct_to_GDP/',
    params: { region: 'Армения' },
  },
  budgetRecentBel: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_budget_struct_to_GDP/',
    params: { region: 'Беларусь' },
  },
  budgetYearlyBel: {
    method: 'GET',
    url: 'EAEU/macro/yearly_budget_struct_to_GDP/',
    params: { region: 'Беларусь' },
  },
  budgetRecentKaz: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_budget_struct_to_GDP/',
    params: { region: 'Казахстан' },
  },
  budgetYearlyKaz: {
    method: 'GET',
    url: 'EAEU/macro/yearly_budget_struct_to_GDP/',
    params: { region: 'Казахстан' },
  },
  budgetRecentKyr: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_budget_struct_to_GDP/',
    params: { region: 'Кыргызстан' },
  },
  budgetYearlyKyr: {
    method: 'GET',
    url: 'EAEU/macro/yearly_budget_struct_to_GDP/',
    params: { region: 'Кыргызстан' },
  },
  budgetRecentRus: {
    method: 'GET',
    url: 'EAEU/macro/quarterly_budget_struct_to_GDP/',
    params: { region: 'Россия' },
  },
  budgetYearlyRus: {
    method: 'GET',
    url: 'EAEU/macro/yearly_budget_struct_to_GDP/',
    params: { region: 'Россия' },
  },
};

export default stateDebt;
