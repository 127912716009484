import React from 'react';
import DownloadPDF from 'assets/img/pdf.svg';

import styles from './CommonStylesUnionState.module.css';
import slidesBelarus from '../downloadableFiles/SlidesBelarus.pdf';

const AboutProjectUnionState: React.FC = () => {
  const itemBlockStyle: any = {
    marginBottom: '50px',
    padding: '30px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08)',
  };

  return (
    <div className={styles.page_text}>
      <h1>О Союзном государстве</h1>

      <h2 id="Информация">
        Краткая информация о текущем состоянии торгово-экономического сотрудничества России и
        Беларуси:
      </h2>

      <div style={itemBlockStyle}>
        <p>
          <b>
            {' '}
            По данным ФТС за 2022 г. товарооборот между Россией и Беларусью в стоимостном выражении
            увеличился на 13% до 43,42 млрд долл. в сравнении с 2021 г., в физическом снизился на
            6,4% (до 56,61 млн тонн).
          </b>
        </p>
        <p>
          <b>Сокращение поставок наблюдается только для экспорта России на рынок Беларуси.</b>{' '}
          Экспортные поставки товаров из России в Республику Беларусь сократились на 6,4% в
          стоимостном выражении (до 21,35 млрд долл.), в физическом -{' '}
          <b>на 16,3% (до 37,4 млн тонн).</b>
        </p>
        <p>
          <b>
            Падение поставок объясняется сокращением по стоимости экспорта товарной группы
            «Минеральные продукты на 23,8% до 7,2 млрд долл. США, физических объемов – на 19,9% до
            28,1 млн тонн.
          </b>{' '}
          Основная причина снижения – запрет на импорт и транзит белорусских нефтепродуктов
          Европейским союзом, куда экспортировалось около 95% всех нефтепродуктов страны. Это
          привело к сокращению объемов переработки нефти НПЗ Республики Беларусь в 2 раза.
          Переработанные нефтепродукты производились на основе российской нефти.
        </p>
        <p>
          Наблюдается также{' '}
          <b>сокращение экспорта товарной группы «Машины, оборудование и транспортные средства»</b>{' '}
          – на 16,4% в стоимостном выражении (до 2,64 млрд долл.), в физическом – на 18,0% (до 0,3
          млн тонн). Основная причина – сокращение производства в России (производство транспортных
          средств, прицепов и полуприцепов сократилось на 44,7%) и спроса в Беларуси.
        </p>
        <p>
          <b>
            Товарный импорт России из Беларуси демонстрирует значительный рост: в стоимостном
            выражении – на 41,3% (до 22,08 млрд долл.), в физическом – на 21,3% (до 19,2 млн тонн).
          </b>
        </p>
        <p>
          <b>Основу роста импорта обеспечивают следующие товарные группы:</b>
        </p>
        <p>
          {' '}
          – Машины, оборудование и транспортные средства. Рост по стоимости на 46,7% до 7 млрд долл.
          Физические объемы снизились на 2,1% (до 0,9 млн тонн).
        </p>
        <p>
          – Продовольственные товары. Рост по стоимости на 26,5% до 5,84 млрд долл. Физические
          объемы снизились на 10,9% (до 2,6 млн тонн).
        </p>
        <p>
          – Товары химической промышленности (удобрения). Рост по стоимости на 62,7%, до 3,18 млрд
          долл., физических объемов – на 54,3%(до 1,6 млн тонн).
        </p>
        <p>
          – Минеральные продукты. Рост по стоимости составил на 80,6% (до 0,6 млрд долл.),
          физических объемов – на 34,7% (до 9,3 млн тонн).
        </p>
        <p>
          <b>
            Сокращение импорта по стоимости происходит только в отношении группы товаров
            «Драгоценные камни, драгоценные металлы и изделия из них».{' '}
          </b>
        </p>
      </div>

      <div className={styles.page_section}>
        <div className={styles.sub_section}>
          <div className={styles.tile}>
            <div className={styles.tile_title}>
              Презентация и короткая справки о текущем состоянии торгово-экономического
              сотрудничества России и Беларуси
            </div>
            <a
              href={slidesBelarus}
              download="Слайды по Беларуси (февраль 2023).pdf"
              className={styles.downloadPDF_link}
            >
              <span className={styles.downloadPDF_link_img}>
                <img src={DownloadPDF} alt="pdf download" />
              </span>
              <span>Скачать данные</span>
            </a>
          </div>
        </div>
      </div>

      <p>
        При поддержке:
        <br />
        <a href="http://www.vavt.ru/science/site/cda" target="_blank" rel="noreferrer">
          Центра анализа данных Всероссийской академии внешней торговли Министерства экономического
          развития Российской Федерации.
        </a>
      </p>
    </div>
  );
};

export default AboutProjectUnionState;
