import React from 'react';

interface BackgroundType {
  img: string;
  bgColor: string;
  bgGradientColor: string;
  loading: boolean;
}

const MainContext = React.createContext<{
  isMainDir: boolean;
  setIsMainDir: (isMainDir: boolean) => void;
  background?: BackgroundType;
  setBackground: (newBackground: BackgroundType) => void;
}>({
  isMainDir: true,
  setIsMainDir: () => {},
  background: undefined,
  setBackground: () => {},
});

export default MainContext;
