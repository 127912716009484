/* eslint-disable */
import React from 'react';
import {
  BarChart,
  HBarChart,
  HMBarChart,
  SplineBarChart,
  SplineChartOneY,
  SplineChartTwoY,
  MBarChartOneY,
  MBarLineChartTwoY,
} from 'frontend-react-library/lib/Charts';

type SplineBarChartProps = Partial<React.ComponentProps<typeof SplineBarChart>>;
type BarChartProps = Partial<React.ComponentProps<typeof BarChart>>;
type MBarLineChartTwoYProps = Partial<React.ComponentProps<typeof MBarLineChartTwoY>>;
type MBarChartOneYProps = Partial<React.ComponentProps<typeof MBarChartOneY>>;
type SplineChartOneYProps = Partial<React.ComponentProps<typeof SplineChartOneY>>;
type SplineChartTwoYProps = Partial<React.ComponentProps<typeof SplineChartTwoY>>;
type HBarChartProps = Partial<React.ComponentProps<typeof HBarChart>>;
type HMBarChartProps = Partial<React.ComponentProps<typeof HMBarChart>>;

interface IChartOptions {
  SplineBarChart: {
    [key: string]: SplineBarChartProps;
  };
  BarChart: {
    [key: string]: BarChartProps;
  };
  MBarLineChartTwoY: {
    [key: string]: MBarLineChartTwoYProps;
  };
  MBarChartOneY: {
    [key: string]: MBarChartOneYProps;
  };
  SplineChartOneY: {
    [key: string]: SplineChartOneYProps;
  };
  SplineChartTwoY: {
    [key: string]: SplineChartTwoYProps;
  };
  HBarChart: {
    [key: string]: HBarChartProps;
  };
  HMBarChart: {
    [key: string]: HMBarChartProps;
  };
}

export const ChartOptions: IChartOptions = {
  SplineBarChart: {
    preset1: {
      columnDataLabels: true,
      lineDataLabels: true,
      tooltipShared: true,
      dataLabelsColors: ['#fff', '#000'],
      chartColors: ['#2787f5', '#FFA000'],
      yAxisLineWidth: 1,
      dataLabelsFontSize: '20px',
    },
    preset2: {
      tooltipShared: true,
      xAxisGridLineWidth: 0,
      dataLabelsColors: ['#fff', '#000'],
      chartColors: ['#2787f5', '#FFA000'],
      yAxisLineWidth: 1,
    },
    preset3: {
      columnDataLabels: true,
      lineDataLabels: true,
      dataLabelsColors: ['#fff', '#000'],
      chartColors: ['#2787f5', '#FFA000'],
      columnPointPadding: 0,
      tooltipShared: true,
      yAxisLineWidth: 1,
    },
    preset4: {
      columnDataLabels: true,
      lineDataLabels: true,
      dataLabelsColors: ['#fff', '#000'],
      chartColors: ['#2787f5', '#FFA000'],
      columnPointPadding: 0.22,
      tooltipShared: true,
      yAxisLineWidth: 1,
    },
    preset5: {
      tooltipShared: true,
      yAxisLineWidth: 1,
      chartColors: ['#2787f5', '#FF724C', '#FFA000'],
      dataLabelsMonoColor: '#fff',
      columnDataLabels: true,
      dataLabelsFontSize: '12px',
      columnPointPadding: 0,
    },
    preset6: {
      columnDataLabels: true,
      lineDataLabels: true,
      dataLabelsColors: ['#fff', '#000'],
      chartColors: ['#2787f5', '#FFA000'],
      columnPointPadding: 0,
      tooltipShared: true,
      yAxisLineWidth: 1,
      height: 650,
    },
    preset7: {
      tooltipShared: true,
      yAxisLineWidth: 1,
      chartColors: ['#2787f5', '#FF724C', '#FFA000'],
      dataLabelsMonoColor: '#fff',
      columnDataLabels: true,
      columnPointPadding: 0,
    },
  },
  BarChart: {
    preset1: {
      columnPointPadding: 0,
      tooltipShared: true,
      dataLabels: true,
      legend: false,
      largeFont: true,
      dataLabelsFontSize: '24px',
    },
    preset2: {
      columnPointPadding: 0.1,
      percentageDifference: true,
      dataLabels: true,
    },
    preset3: {
      columnPointPadding: 0.2,
      tooltipShared: true,
      dataLabels: false,
      xAxisGridLineWidth: 1,
    },
    preset4: {
      columnPointPadding: 0.05,
      tooltipShared: true,
      dataLabels: true,
      legend: true,
      dataLabelsFontSize: '12px',
      chartColors: ['#2787F5', '#FF724C'],
    },
    preset5: {
      columnPointPadding: 0,
      percentageDifference: true,
      dataLabels: true,
      axisFontSize: '12px',
      dataLabelsFontSize: '16px',
    },
    preset6: {
      columnPointPadding: 0.2,
      percentageDifference: true,
      dataLabels: true,
      axisFontSize: '10px',
      dataLabelsFontSize: '12px',
      height: 650,
    },
    preset7: {
      columnPointPadding: 0,
      percentageDifference: true,
      dataLabels: true,
      axisFontSize: '12px',
      dataLabelsFontSize: '9px',
    },
    preset8: {
      columnPointPadding: 0.2,
      tooltipShared: true,
      dataLabels: true,
      xAxisGridLineWidth: 1,
      dataLabelsFontSize: '16px',
    },
  },
  MBarLineChartTwoY: {
    preset1: {
      tooltipShared: true,
      minPointLength: 40,
      pointWidth: 112,
      columnDataLabelsInside: true,
      columnDataLabelsColors: '#fff',
    },
    preset2: {
      tooltipShared: true,
      minPointLength: 40,
      pointWidth: 112,
      stacking: 'normal',
      dataLabels: true,
      columnDataLabelsInside: true,
      columnDataLabelsColors: '#fff',
      chartColors: ['#2787f5', '#FFA000', '#ffa000'],
      height: 530,
    },
    preset3: {
      tooltipShared: true,
      minPointLength: 40,
      pointWidth: 112,
      stacking: 'percent',
      dataLabels: true,
      columnDataLabelsInside: true,
      columnDataLabelsColors: '#fff',
      chartColors: ['#2787f5', '#FFA000', '#ffa000'],
      height: 470,
      yAxisEnabled: false,
      yAxisLineWidth: 1,
    },
  },
  MBarChartOneY: {
    preset1: {
      tooltipShared: true,
      minPointLength: 40,
      pointWidth: 112,
      stacking: 'normal',
      dataLabels: true,
      dataLabelsMonoColor: '#fff',
      chartColors: ['#FF724C', '#2787F5'],
    },
  },
  SplineChartOneY: {
    preset1: {
      xAxisGridLineWidth: 0,
    },
    preset2: {
      markers: true,
      seriesPointPlacement: 0,
      xAxisGridLineWidth: 0,
    },
    preset3: {
      markers: true,
      seriesPointPlacement: 0,
      xAxisGridLineWidth: 0,
      legend: false,
    },
    preset4: {
      xAxisGridLineWidth: 0,
      legend: false,
    },
    preset5: {
      markers: true,
      seriesPointPlacement: 0,
      xAxisGridLineWidth: 0,
      chartColors: ['#29a32d', '#cc3e31', '#c7cc31'],
    },
  },
  SplineChartTwoY: {
    preset1: {
      xAxisGridLineWidth: 0,
    },
    preset2: {
      markers: true,
      seriesPointPlacement: 0,
      xAxisGridLineWidth: 0,
    },
    preset3: {
      markers: true,
      seriesPointPlacement: 0,
      xAxisGridLineWidth: 0,
      legend: false,
    },
    preset4: {
      xAxisGridLineWidth: 0,
      legend: false,
    },
  },
  HBarChart: {
    preset1: {
      tooltipShared: true,
    },
    preset2: {
      tooltipShared: true,
      dataLabels: true,
      dataLabelsFontSize: '12px',
      chartColors: ['#2787F5', '#FF724C'],
    },
    preset3: {
      tooltipShared: true,
      dataLabels: false,
      chartColors: ['#2787F5', '#FF724C', '#FFA000'],
    },
    preset4: {
      tooltipShared: true,
      axisFontSize: '10px',
    },
    preset5: {
      tooltipShared: true,
      dataLabels: true,
      height: 650,
      dataLabelsFontSize: '10px',
      chartColors: ['#2787F5', '#FF724C'],
      axisFontSize: '10px',
    },
    preset6: {
      tooltipShared: true,
      legend: false,
      dataLabels: true,
      dataLabelsFontSize: '14px',
    },
    preset7: {
      height: 650,
      tooltipShared: true,
      dataLabels: false,
      chartColors: ['#2787F5', '#FF724C'],
      axisFontSize: '10px',
    },
    preset8: {
      tooltipShared: true,
      height: 650,
    },
    preset9: {
      tooltipShared: true,
      dataLabels: true,
      chartColors: ['#2787F5', '#FF724C'],
      axisFontSize: '10px',
      dataLabelsFontSize: '12px',
      height: 650,
    },
    preset10: {
      tooltipShared: true,
      height: 850,
    },
    preset11: {
      tooltipShared: true,
      legend: false,
      dataLabels: true,
      height: 650,
      dataLabelsFontSize: '10px',
      chartColors: ['#2787F5', '#FF724C'],
      axisFontSize: '10px',
    },
  },
  HMBarChart: {
    preset1: {
      stacking: 'percent',
      dataLabels: true,
      height: 650,
      dataLabelsMonoColor: '#fff',
      dataLabelsFontSize: '10px',
    },
    preset2: {
      stacking: 'normal',
      dataLabels: true,
      dataLabelsMonoColor: '#fff',
      height: 650,
      dataLabelsFontSize: '12px',
    },
    preset3: {
      stacking: 'normal',
    },
    preset4: {
      stacking: 'normal',
      height: 650,
      dataLabelsFontSize: '12px',
      chartColors: ['#29a32d', ' #cc3e31', '#c7cc31'],
    },
    preset5: {
      stacking: 'normal',
      height: 650,
      dataLabelsFontSize: '12px',
      chartColors: ['#2787f5'],
    },
    preset6: {
      stacking: 'normal',
      height: 650,
      dataLabelsFontSize: '12px',
      chartColors: ['#08457e'],
    },
  },
};
