import AboutProject from 'pages/AboutProject';
import PublicPage from 'pages/PublicPages/PublicPage';
// import PublicEconomicEffects from 'pages/PublicPages/EconomicEffects';
import MacroeconomicIndicators from 'pages/PublicPages/MacroeconomicIndicators';
import TradeInGoods from 'pages/PublicPages/TradeInGoods';
import PublicTradeInServices from 'pages/PublicPages/TradeInServices';
import PublicInvestment from 'pages/PublicPages/Investment';
import Covid19PP from 'pages/PublicPages/Covid19';
// import MeasuresSustainability from 'pages/PublicPages/MeasuresSustainability';

// ICONS
import { ReactComponent as MonitoringIcon } from 'assets/icons/nav-monitoring.svg';
import { ReactComponent as AboutProjectIcon } from 'assets/icons/nav-about.svg';
// import { ReactComponent as PublicImpactIcon } from 'assets/icons/nav-public-impact-estimates.svg';
import { ReactComponent as DollarIcon } from 'assets/icons/dollar.svg';
import { ReactComponent as CovidIcon } from 'assets/icons/covid.svg';
import { ReactComponent as TradeGoodsIcon } from 'assets/icons/nav-trade-goods.svg';
import { ReactComponent as TradeServicesIcon } from 'assets/icons/nav-trade-services.svg';
import { ReactComponent as InvestmentIcon } from 'assets/icons/nav-investment.svg';
// import { ReactComponent as ShieldIcon } from 'assets/icons/shield.svg';

/*
    > path - путь для роутинга;
    > title - заголовок ссылки в сайдбаре;
    > search_title - заголовок по которому осуществляется поиск в компоненте SearchBar. 
        Если search_title отсутствует, поиск осуществляется по полю title
    > mainDir - путь родительских вкладок. Записывается в виде: dir1/dir2/dir3/;
    > component - компонент страницы;
    > items - массив объектов оглавления раздела:
        >> path - ссылка соответствующая id элемента (заголовка, подзаголовка) на странице;
        >> title - заголовок ссылки.
        >> nested_items - вложенные разделы элемента оглавления (объекта) items;
*/

import { RouteConfig } from 'configs/types';

export const OPEN_ROUTERS: RouteConfig[] = [
  {
    path: '/about',
    title: 'О проекте',
    icon: AboutProjectIcon,
    mainDir: 'О проекте/',
    documentTitle: 'ЕАЭС - О Проекте',
    component: AboutProject,
  },
  {
    path: '/',
    title: 'Евразийская интеграция в цифрах',
    mainDir: '/',
    search_title: 'Евразийская интеграция в цифрах (Открытый портал)',
    documentTitle: 'ЕАЭС - Главная',
    icon: MonitoringIcon,
    component: PublicPage,
  },

  // раздел ниже скрыт по просьбе ИРИП от 16/03/2023
  // {
  //   path: '/measures_sustainability',
  //   title: 'Меры по повышению устойчивости экономик ЕАЭС',
  //   mainDir: 'Открытый портал/',
  //   search_title: 'Меры по повышению устойчивости экономик ЕАЭС (Открытый портал)',
  //   documentTitle: 'ЕАЭС - Меры по повышению устойчивости экономик ЕАЭС (Открытый портал)',
  //   icon: ShieldIcon,
  //   component: MeasuresSustainability,
  // },
  {
    path: '/macroeconomic_indicators',
    title: 'Макроэкономические показатели',
    mainDir: 'Открытый портал/',
    search_title: 'Макроэкономические показатели (Открытый портал)',
    documentTitle: 'ЕАЭС - Макроэкономические показатели (Открытый портал)',
    icon: DollarIcon,
    component: MacroeconomicIndicators,
  },
  {
    path: '/covid19',
    title: 'Эпидемиологическая динамика (COVID-19)',
    mainDir: 'Открытый портал/',
    search_title: 'Заболеваемость Covid-19 (Открытый портал)',
    documentTitle: 'ЕАЭС - Заболеваемость Covid-19',
    icon: CovidIcon,
    component: Covid19PP,
  },
  {
    path: '/trade_in_goods',
    title: 'Торговля товарами',
    mainDir: 'Открытый портал/Торговля товарами',
    search_title: 'Торговля товарами (Открытый портал)',
    documentTitle: 'ЕАЭС - Торговля товарами',
    icon: TradeGoodsIcon,
    component: TradeInGoods,
  },
  {
    path: '/trade_in_services',
    title: 'Торговля услугами',
    mainDir: 'Открытый портал/',
    search_title: 'Торговля услугами (Открытый портал)',
    documentTitle: 'ЕАЭС -Торговля услугами ',
    icon: TradeServicesIcon,
    component: PublicTradeInServices,
  },
  {
    path: '/investment',
    title: 'Инвестиции',
    search_title: 'Международные инвестиции (Открытый портал)',
    documentTitle: 'ЕАЭС - Международные инвестиции',
    mainDir: 'Открытый портал/',
    icon: InvestmentIcon,
    component: PublicInvestment,
  },
  // раздел ниже скрыт по просьбе ИРИП от 16/03/2023

  // {
  //   path: '/economic_effects',
  //   title: 'Экономические эффекты интеграции',
  //   mainDir: 'Открытый портал/',
  //   search_title: 'Экономические эффекты интеграции (Открытый портал)',
  //   documentTitle: 'ЕАЭС - Экономические эффекты интеграции',
  //   icon: PublicImpactIcon,
  //   component: PublicEconomicEffects,
  // },
];
