/* eslint-disable */

(function (m, e, t, r, i, k, a) {
  m[i] =
    m[i] ||
    function () {
      (m[i].a = m[i].a || []).push(arguments);
    };
  m[i].l = 1 * new Date();
  (k = e.createElement(t)),
    (a = e.getElementsByTagName(t)[0]),
    (k.async = 1),
    (k.src = r),
    a.parentNode.insertBefore(k, a);
})(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

// process.env.REACT_APP_ROUTERS === 'open_routers' && process.env.REACT_APP_URL === 'prod'
//   ? ym(97209685, 'init', console.log('DW'), {
//       clickmap: true,
//       trackLinks: true,
//       accurateTrackBounce: true,
//     })
//   : ym(97209609, 'init', console.log('W'), {
//       clickmap: true,
//       trackLinks: true,
//       accurateTrackBounce: true,
//     });

if (process.env.REACT_APP_ROUTERS === 'open_routers' && process.env.REACT_APP_URL === 'gov') {
  ym(97209685, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
  });
}
if (process.env.REACT_APP_ROUTERS === 'full_routers' && process.env.REACT_APP_URL === 'prod') {
  ym(97209609, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
  });
}
