const tradeInServices = {
  serviceTradeVolumeGrowthByQuarterLastYearsExportEAEU: {
    method: 'GET',
    url: 'EAEU/services_trade/quarterly_dynamics_EAEU/',
    params: { variable: 'export' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsImportEAEU: {
    method: 'GET',
    url: 'EAEU/services_trade/quarterly_dynamics_EAEU/',
    params: { variable: 'import' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsExportArm: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Армения', variable: 'export' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsImportArm: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Армения', variable: 'import' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsTurnoverArm: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Армения', variable: 'turnover' },
  },

  serviceTradeVolumeGrowthByQuarterLastYearsTurnoverKaz: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Казахстан', variable: 'turnover' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsTurnoverKyr: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Кыргызстан', variable: 'turnover' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsTurnoverRus: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Россия', variable: 'turnover' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsTurnoverBel: {
    method: 'GET',
    url: 'Belarus/services_trade/yearly_dynamics/',
    params: { type: 'turnover' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsExportBel: {
    method: 'GET',
    url: 'Belarus/services_trade/yearly_dynamics/',
    params: { type: 'export' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsImportBel: {
    method: 'GET',
    url: 'Belarus/services_trade/yearly_dynamics/',
    params: { type: 'import' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsExportKaz: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Казахстан', variable: 'export' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsImportKaz: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Казахстан', variable: 'import' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsExportKyr: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Кыргызстан', variable: 'export' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsImportKyr: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Кыргызстан', variable: 'import' },
  },

  serviceTradeVolumeGrowthByQuarterLastYearsExportUnionStateRus: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Россия', variable: 'export' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsImportUnionStateRus: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Россия', variable: 'import' },
  },

  serviceTradeVolumeGrowthByQuarterLastYearsExportRus: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Россия', variable: 'export' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsImportRus: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { region: 'Россия', variable: 'import' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsExportAze: {
    method: 'GET',
    url: 'Azerbaijan/services_trade/yearly_dynamics/',
    params: { type: 'export' },
  },
  serviceTradeVolumeGrowthByQuarterLastYearsImportAze: {
    method: 'GET',
    url: 'Azerbaijan/services_trade/yearly_dynamics/',
    params: { type: 'import' },
  },
  serviceTradeWithinEAEUVolActByPrevYears: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_trade_groups_within_EAEU/',
  },
  serviceTradeWithinEAEUVolRegByPrevYearsArm: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics_with_EAEU/',
    params: { region: 'Армения' },
  },
  serviceTradeWithinEAEUVolRegByPrevYearsBel: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics_with_EAEU/',
    params: { region: 'Беларусь' },
  },
  serviceTradeWithinEAEUVolRegByPrevYearsKaz: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics_with_EAEU/',
    params: { region: 'Казахстан' },
  },
  serviceTradeWithinEAEUVolRegByPrevYearsKyr: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics_with_EAEU/',
    params: { region: 'Кыргызстан' },
  },
  serviceTradeWithinEAEUVolRegByPrevYearsAze: {
    method: 'GET',
    url: 'Azerbaijan/services_trade/yearly_dynamics/',
    params: { type: 'turnover' },
  },
  serviceTradeWithinEAEUVolRegByPrevYearsRus: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics_with_EAEU/',
    params: { region: 'Россия' },
  },
  serviceTradeWithinEAEUVolRegByPrevYearsEAEU: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics_with_EAEU/',
    params: { region: 'ЕАЭС' },
  },
  serviceTradeDetailedExportEAEU: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups_EAEU/',
    params: { variable: 'export' },
  },
  serviceTradeDetailedImportEAEU: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups_EAEU/',
    params: { variable: 'import' },
  },
  serviceTradeDetailedExportArm: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups/',
    params: { variable: 'export', region: 'Армения' },
  },
  serviceTradeDetailedImportArm: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups/',
    params: { variable: 'import', region: 'Армения' },
  },
  serviceTradeDetailedExportBel: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups/',
    params: { variable: 'export', region: 'Беларусь' },
  },
  serviceTradeDetailedImportBel: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups/',
    params: { variable: 'import', region: 'Беларусь' },
  },
  serviceTradeDetailedExportKaz: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups/',
    params: { variable: 'export', region: 'Казахстан' },
  },
  serviceTradeDetailedImportKaz: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups/',
    params: { variable: 'import', region: 'Казахстан' },
  },
  serviceTradeDetailedExportKyr: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups/',
    params: { variable: 'export', region: 'Кыргызстан' },
  },
  serviceTradeDetailedImportKyr: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups/',
    params: { variable: 'import', region: 'Кыргызстан' },
  },
  serviceTradeDetailedExportRus: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups/',
    params: { variable: 'export', region: 'Россия' },
  },
  serviceTradeDetailedImportRus: {
    method: 'GET',
    url: 'EAEU/services_trade/actual_trade_groups/',
    params: { variable: 'import', region: 'Россия' },
  },
  serviceTradeDetailedExportUkr: {
    method: 'GET',
    url: 'Ukraine/services_trade/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedImportUkr: {
    method: 'GET',
    url: 'Ukraine/services_trade/volume_by_activity_last_years/',
    params: { type: 'import' },
  },
  servicesTradeGroupedExportCountries: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { variable: 'export' },
  },
  servicesTradeGroupedImportCountries: {
    method: 'GET',
    url: 'EAEU/services_trade/yearly_dynamics/',
    params: { variable: 'import' },
  },
  servicesTradeGrowthQuarterExportWithRusAze: {
    method: 'GET',
    url: 'Azerbaijan/services_trade/quarterly_dynamics_with_ru/',
    params: { variable: 'export' },
  },
  servicesTradeGrowthQuarterImportWithRusAze: {
    method: 'GET',
    url: 'Azerbaijan/services_trade/quarterly_dynamics_with_ru/',
    params: { variable: 'import' },
  },
  servicesTradeGrowthQuarterTurnoverWithRusAze: {
    method: 'GET',
    url: 'Azerbaijan/services_trade/quarterly_dynamics_with_ru/',
    params: { variable: 'turnover' },
  },

  servicesTradeGrowthQuarterExportWithRusTur: {
    method: 'GET',
    url: 'Turkmenistan/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'export' },
  },
  servicesTradeGrowthQuarterImportWithRusTur: {
    method: 'GET',
    url: 'Turkmenistan/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'import' },
  },
  servicesTradeGrowthQuarterTurnoverWithRusTur: {
    method: 'GET',
    url: 'Turkmenistan/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'turnover' },
  },

  servicesTradeGrowthQuarterExportWithRusUzb: {
    method: 'GET',
    url: 'Uzbekistan/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'export' },
  },
  servicesTradeGrowthQuarterImportWithRusUzb: {
    method: 'GET',
    url: 'Uzbekistan/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'import' },
  },
  servicesTradeGrowthQuarterTurnoverWithRusUzb: {
    method: 'GET',
    url: 'Uzbekistan/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'turnover' },
  },

  servicesTradeGrowthQuarterExportWithRusUkr: {
    method: 'GET',
    url: 'Ukraine/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'export' },
  },
  servicesTradeGrowthQuarterImportWithRusUkr: {
    method: 'GET',
    url: 'Ukraine/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'import' },
  },
  servicesTradeGrowthQuarterTurnoverWithRusUkr: {
    method: 'GET',
    url: 'Ukraine/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'turnover' },
  },

  servicesTradeGrowthQuarterExportWithRusGeo: {
    method: 'GET',
    url: 'Georgia/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'export' },
  },
  servicesTradeGrowthQuarterImportWithRusGeo: {
    method: 'GET',
    url: 'Georgia/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'import' },
  },
  servicesTradeGrowthQuarterTurnoverWithRusGeo: {
    method: 'GET',
    url: 'Georgia/services_trade/with_Russia/volume_growth_by_quarter/',
    params: { type: 'turnover' },
  },

  servicesQuarterlyWithRusExportMol: {
    method: 'GET',
    url: 'Moldova/services_trade/quarterly_with_ru/',
    params: { type: 'export' },
  },
  servicesQuarterlyWithRusImportMol: {
    method: 'GET',
    url: 'Moldova/services_trade/quarterly_with_ru/',
    params: { type: 'import' },
  },
  servicesQuarterlyWithRusTurnoverMol: {
    method: 'GET',
    url: 'Moldova/services_trade/quarterly_with_ru/',
    params: { type: 'turnover' },
  },

  servicesQuarterlyWithRusExportTaj: {
    method: 'GET',
    url: 'Tajikistan/services_trade/quarterly_with_ru/',
    params: { type: 'export' },
  },
  servicesQuarterlyWithRusImportTaj: {
    method: 'GET',
    url: 'Tajikistan/services_trade/quarterly_with_ru/',
    params: { type: 'import' },
  },
  servicesQuarterlyWithRusTurnoverTaj: {
    method: 'GET',
    url: 'Tajikistan/services_trade/quarterly_with_ru/',
    params: { type: 'turnover' },
  },

  servicesQuarterlyWithWorldExportMol: {
    method: 'GET',
    url: 'Moldova/services_trade/quarterly_with_world/',
    params: { type: 'export' },
  },
  servicesQuarterlyWithWorldImportMol: {
    method: 'GET',
    url: 'Moldova/services_trade/quarterly_with_world/',
    params: { type: 'import' },
  },
  servicesQuarterlyWithWorldTurnoverMol: {
    method: 'GET',
    url: 'Moldova/services_trade/quarterly_with_world/',
    params: { type: 'turnover' },
  },

  servicesQuarterlyWithWorldExportTaj: {
    method: 'GET',
    url: 'Tajikistan/services_trade/quarterly_with_world/',
    params: { type: 'export' },
  },
  servicesQuarterlyWithWorldImportTaj: {
    method: 'GET',
    url: 'Tajikistan/services_trade/quarterly_with_world/',
    params: { type: 'import' },
  },
  servicesQuarterlyWithWorldTurnoverTaj: {
    method: 'GET',
    url: 'Tajikistan/services_trade/quarterly_with_world/',
    params: { type: 'turnover' },
  },

  servicesQuarterlyWithWorldExportUzb: {
    method: 'GET',
    url: 'Uzbekistan/services_trade/quarterly_with_world/',
    params: { type: 'export' },
  },
  servicesQuarterlyWithWorldImportUzb: {
    method: 'GET',
    url: 'Uzbekistan/services_trade/quarterly_with_world/',
    params: { type: 'import' },
  },
  servicesQuarterlyWithWorldTurnoverUzb: {
    method: 'GET',
    url: 'Uzbekistan/services_trade/quarterly_with_world/',
    params: { type: 'turnover' },
  },

  servicesQuarterlyWithWorldExportUkr: {
    method: 'GET',
    url: 'Ukraine/services_trade/quarterly_with_world/',
    params: { type: 'export' },
  },
  servicesQuarterlyWithWorldImportUkr: {
    method: 'GET',
    url: 'Ukraine/services_trade/quarterly_with_world/',
    params: { type: 'import' },
  },
  servicesQuarterlyWithWorldTurnoverUkr: {
    method: 'GET',
    url: 'Ukraine/services_trade/quarterly_with_world/',
    params: { type: 'turnover' },
  },

  servicesQuarterlyWithWorldExportGeo: {
    method: 'GET',
    url: 'Georgia/services_trade/quarterly_with_world/',
    params: { type: 'export' },
  },
  servicesQuarterlyWithWorldImportGeo: {
    method: 'GET',
    url: 'Georgia/services_trade/quarterly_with_world/',
    params: { type: 'import' },
  },
  servicesQuarterlyWithWorldTurnoverGeo: {
    method: 'GET',
    url: 'Georgia/services_trade/quarterly_with_world/',
    params: { type: 'turnover' },
  },

  serviceTradeDetailedExportAze: {
    method: 'GET',
    url: 'Azerbaijan/services_trade/yearly_trade_groups/',
    params: { type: 'export' },
  },
  serviceTradeDetailedImportAze: {
    method: 'GET',
    url: 'Azerbaijan/services_trade/yearly_trade_groups/',
    params: { type: 'import' },
  },

  serviceTradeDetailedWithRusExportAze: {
    method: 'GET',
    url: 'Azerbaijan/services_trade/yearly_trade_groups_with_ru/',
    params: { type: 'export' },
  },
  serviceTradeDetailedWithRusImportAze: {
    method: 'GET',
    url: 'Azerbaijan/services_trade/yearly_trade_groups_with_ru/',
    params: { type: 'import' },
  },

  serviceTradeDetailedWithRusExportMol: {
    method: 'GET',
    url: 'Moldova/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedWithRusImportMol: {
    method: 'GET',
    url: 'Moldova/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'import' },
  },

  serviceTradeDetailedWithRusExportTaj: {
    method: 'GET',
    url: 'Tajikistan/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedWithRusImportTaj: {
    method: 'GET',
    url: 'Tajikistan/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'import' },
  },

  serviceTradeDetailedWithRusExportTur: {
    method: 'GET',
    url: 'Turkmenistan/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedWithRusImportTur: {
    method: 'GET',
    url: 'Turkmenistan/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'import' },
  },

  serviceTradeDetailedWithRusExportUzb: {
    method: 'GET',
    url: 'Uzbekistan/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedWithRusImportUzb: {
    method: 'GET',
    url: 'Uzbekistan/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'import' },
  },

  serviceTradeDetailedWithRusExportUkr: {
    method: 'GET',
    url: 'Ukraine/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedWithRusImportUkr: {
    method: 'GET',
    url: 'Ukraine/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'import' },
  },

  serviceTradeDetailedWithRusExportGeo: {
    method: 'GET',
    url: 'Georgia/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedWithRusImportGeo: {
    method: 'GET',
    url: 'Georgia/services_trade/with_Russia/volume_by_activity_last_years/',
    params: { type: 'import' },
  },

  serviceTradeDetailedExportMol: {
    method: 'GET',
    url: 'Moldova/services_trade/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedImportMol: {
    method: 'GET',
    url: 'Moldova/services_trade/volume_by_activity_last_years/',
    params: { type: 'import' },
  },

  serviceTradeDetailedExportUzb: {
    method: 'GET',
    url: 'Uzbekistan/services_trade/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedImportUzb: {
    method: 'GET',
    url: 'Uzbekistan/services_trade/volume_by_activity_last_years/',
    params: { type: 'import' },
  },

  serviceTradeDetailedExportTaj: {
    method: 'GET',
    url: 'Tajikistan/services_trade/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedImportTaj: {
    method: 'GET',
    url: 'Tajikistan/services_trade/volume_by_activity_last_years/',
    params: { type: 'import' },
  },

  serviceTradeDetailedExportGeo: {
    method: 'GET',
    url: 'Georgia/services_trade/volume_by_activity_last_years/',
    params: { type: 'export' },
  },
  serviceTradeDetailedImportGeo: {
    method: 'GET',
    url: 'Georgia/services_trade/volume_by_activity_last_years/',
    params: { type: 'import' },
  },
};

export default tradeInServices;
