import React from 'react';
import DownloadPDF from 'assets/img/pdf.svg';
import styles from './DownloadPdfTonality.module.scss';

const DownloadPdfTonality: React.FC<{ filePdf: any; title: string; download: string }> = ({
  filePdf,
  title,
  download,
}) => {
  return (
    <div className={styles.page_section}>
      <div className={styles.sub_section}>
        <div className={styles.tile}>
          <div className={styles.tile_title}>{title}</div>
          <a href={filePdf} download={download} className={styles.downloadPDF_link}>
            <span className={styles.downloadPDF_link_img}>
              <img src={DownloadPDF} alt="pdf download" />
            </span>
            <span>Скачать данные</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default DownloadPdfTonality;
