import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  DynamicsOfServices: [
    {
      title: 'Экспорт',
      chart: 'BarChart',
      request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsExportEAEU },
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
    {
      title: 'Импорт',
      chart: 'BarChart',
      request: { ...API.tradeInServices.serviceTradeVolumeGrowthByQuarterLastYearsImportEAEU },
      chartOptions: { ...ChartOptions.BarChart.preset5 },
    },
  ],
  ServiceStructure: [
    {
      title: 'Экспорт',
      chart: 'HBarChart',
      request: { ...API.tradeInServices.serviceTradeDetailedExportEAEU },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
    {
      title: 'Импорт',
      chart: 'HBarChart',
      request: { ...API.tradeInServices.serviceTradeDetailedImportEAEU },
      chartOptions: { ...ChartOptions.HBarChart.preset7 },
    },
  ],
  DynamicsOfGeneralServices: [
    {
      title: 'Экспорт',
      chart: 'SplineBarChart',
      request: { ...API.tradeInServices.servicesTradeGroupedExportCountries },
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
    {
      title: 'Импорт',
      chart: 'SplineBarChart',
      request: { ...API.tradeInServices.servicesTradeGroupedImportCountries },
      chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
    },
  ],
  VolumeTradeServices: [
    {
      title: '',
      chart: 'BarChart',
      request: { ...API.tradeInServices.serviceTradeWithinEAEUVolRegByPrevYearsEAEU },
      chartOptions: { ...ChartOptions.BarChart.preset1 },
    },
  ],
  StructureMutualTradeServices: [
    {
      title: '',
      chart: 'HBarChart',
      request: { ...API.tradeInServices.serviceTradeWithinEAEUVolActByPrevYears },
      chartOptions: { ...ChartOptions.HBarChart.preset10 },
    },
  ],
};
