import React, { useContext } from 'react';
import Context from 'context/CountryContext';
import CountryButtons from 'components/CountryButtons';
import DynamicChart from 'components/DynamicChart';
import { countries } from 'configs/countries';
import { ChartConfigs } from 'configs/pages/public/EconomicEffects';
import { Tooltip } from 'frontend-react-library/lib/ui/Tooltip';

import DownloadXLSX from 'assets/img/xlsx.svg';
import effects1 from './effects1(2021).xlsx';
import effects2 from './effects2(2021).xlsx';
import styles from '../Page.module.scss';

const EconomicEffects: React.FC = () => {
  const { currentCountry } = useContext(Context);

  return (
    <>
      <h1 id="_1">Оценки влияния ЕАЭС на ВВП и торговлю государств&nbsp;—&nbsp;членов</h1>

      <CountryButtons
        countries={[
          countries.EAEU,
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
        ]}
      />

      <div className={styles.page_section}>
        <div className={styles.sub_section}>
          <DynamicChart toggles={ChartConfigs.EconomicGrowth[currentCountry]} />
        </div>
        <div className={styles.notice}>
          {currentCountry === 'EAEU' && (
            <>
              <p>
                Согласно расчетам на основе вычислимой модели общего равновесия{' '}
                <sup id="fnref:1">
                  <a className="footnote-ref" href="#fn:1">
                    1
                  </a>
                </sup>
                , экономическая интеграция в рамках{' '}
                <Tooltip text="Евразийский экономический союз">
                  <span>ЕАЭС</span>
                </Tooltip>{' '}
                внесла различный по масштабам вклад в динамику экономического роста участвующих
                стран, однако этот вклад оценивается как устойчиво положительный для каждого из
                участников интеграционного объединения.
              </p>
              <br />
              <p>
                На это указывают результаты моделирования, которые приводятся и сопоставляются с
                фактическими изменениями показателей в таблицах 1 и 2. Эти результаты позволяют
                говорить о том, что ежегодный дополнительный вклад в экономический рост (
                <Tooltip text="Валовый внутренний продукт">
                  <span>ВВП</span>
                </Tooltip>{' '}
                в постоянных ценах) оценивается 0,2% для{' '}
                <Tooltip text="Евразийский экономический союз">
                  <span>ЕАЭС</span>
                </Tooltip>{' '}
                в целом. В совокупности за период 2016–2022 гг. рост реального совокупного{' '}
                <Tooltip text="Валовый внутренний продукт">
                  <span>ВВП</span>
                </Tooltip>{' '}
                стран Союза составил 8%, и примерно 1,2 п.п. (около $26 млрд) этого изменения,
                согласно расчетам, является выигрышем стран от интеграции за все время.
              </p>
            </>
          )}
          {currentCountry === 'Armenia' && (
            <p>
              За 2016-2022 гг.{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              ежегодно обеспечивал дополнительный прирост ВВП Армении в 0,36 п.п.{' '}
              <Tooltip text="Валовый внутренний продукт">
                <span>ВВП</span>
              </Tooltip>
              , за весь рассматриваемый период выигрыш Армении от интеграции оценивается в 2,5 п.п.{' '}
              <Tooltip text="Валовый внутренний продукт">
                <span>ВВП</span>
              </Tooltip>{' '}
              или 490 млн долл. США.
            </p>
          )}
          {currentCountry === 'Russia' && (
            <p>
              Россия является крупнейшей экономикой{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              и именно эффектами для этой страны во многом объясняются совокупные эффекты для
              объединения в целом. В этом позволяет убедиться представление интеграционных эффектов
              для российского{' '}
              <Tooltip text="Валовый внутренний продукт">
                <span>ВВП</span>
              </Tooltip>
              , которое во многом совпадает с аналогичным графиком для Союза.
            </p>
          )}
          {currentCountry === 'Belarus' && (
            <p>
              Наибольшие выигрыши от интеграции в относительном выражении получила Беларусь. Именно
              тесные экономические связи со странами{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              (в первую очередь с Россией) обуславливают наибольшие позитивные дополнительные
              эффекты для этой страны от интеграции. За 2016-2022 гг.{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              ежегодно обеспечивал дополнительный прирост ВВП страны в 0,9 п.п. ВВП, за весь
              рассматриваемый период выигрыш Беларуси от интеграции оценивается в 6,15 п.п. ВВП или
              4 млрд долл. США.
            </p>
          )}
          {currentCountry === 'Kyrgyzstan' && (
            <p>
              За 2016-2022 гг.{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              ежегодно обеспечивал дополнительный прирост{' '}
              <Tooltip text="Валовый внутренний продукт">
                <span>ВВП</span>
              </Tooltip>{' '}
              Киргизии в 0,6 п.п.{' '}
              <Tooltip text="Валовый внутренний продукт">
                <span>ВВП</span>
              </Tooltip>
              , за весь рассматриваемый период выигрыш Киргизии от интеграции оценивается в 4,3 п.п.{' '}
              <Tooltip text="Валовый внутренний продукт">
                <span>ВВП</span>
              </Tooltip>{' '}
              или 430 млн долл. США.{' '}
            </p>
          )}
          {currentCountry === 'Kazakhstan' && (
            <p>
              За 2016-2022 гг.{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              ежегодно обеспечивал дополнительный прирост{' '}
              <Tooltip text="Валовый внутренний продукт">
                <span>ВВП</span>
              </Tooltip>{' '}
              Казахстана в 0,2 п.п.{' '}
              <Tooltip text="Валовый внутренний продукт">
                <span>ВВП</span>
              </Tooltip>
              , за весь рассматриваемый период выигрыш Казахстана от интеграции оценивается в 1,3
              п.п.{' '}
              <Tooltip text="Валовый внутренний продукт">
                <span>ВВП</span>
              </Tooltip>{' '}
              или 2,8 млрд долл. США.
            </p>
          )}
        </div>
      </div>

      {currentCountry === 'EAEU' && (
        <div className={styles.page_section}>
          <div className={styles.sub_section}>
            <DynamicChart toggles={ChartConfigs.GrowthMutualTrade[currentCountry]} />
          </div>

          <div className={styles.notice}>
            <p>
              В то же время динамика взаимной торговли между странами – участницами в значительно
              большей степени определялась интеграционными эффектами. К примеру, в 2020 г. взаимная
              торговля снизилась на 10,7% между странами{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              , в отсутствии интеграционного эффекта падение составило бы 16%.
            </p>
            <br />
            <p>
              Одновременно со значительным эффектом на взаимную торговлю, евразийская интеграция
              оказала весьма ограниченное влияние на динамику внешней торговли. Представленные в
              таблице 2 результаты позволяют говорить о том, что в среднем вклад интеграции в
              изменение торговли стран{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>{' '}
              с третьими странами оценивается в величину, не превышающую 0,5%, тогда как наблюдаемые
              изменения внешней торговли были значительно более масштабными и в отдельные годы
              достигали десятков процентов.
            </p>
          </div>
        </div>
      )}

      <div className={styles.page_section}>
        <div className={styles.sub_section}>
          <div className={styles.tile}>
            <div className={styles.tile_title}>
              Таблица 1 – фактические изменения и оценки интеграционных эффектов для реального{' '}
              <Tooltip text="Валовый внутренний продукт">
                <span>ВВП</span>
              </Tooltip>{' '}
            </div>
            <a href={effects1} download="effects1(2021).xlsx" className={styles.downloadXLSX_link}>
              <span className={styles.downloadXLSX_link_img}>
                <img src={DownloadXLSX} alt="xlsx download" />
              </span>
              <span>Скачать данные</span>
            </a>
          </div>
        </div>

        <div className={styles.sub_section}>
          <div className={styles.tile}>
            <div className={styles.tile_title}>
              Таблица 2 – фактические изменения и оценки интеграционных эффектов для взаимной и
              внешней торговли стран{' '}
              <Tooltip text="Евразийский экономический союз">
                <span>ЕАЭС</span>
              </Tooltip>
            </div>
            <a href={effects2} download="effects2(2021).xlsx" className={styles.downloadXLSX_link}>
              <span className={styles.downloadXLSX_link_img}>
                <img src={DownloadXLSX} alt="xlsx download" />
              </span>
              <span>Скачать данные</span>
            </a>
          </div>
        </div>

        {currentCountry === 'EAEU' && (
          <div className={styles.notice}>
            <hr />
            <ol className={styles.footnote}>
              <li id="fn:1">
                <p>
                  Вычислимые модели общего равновесия (
                  <Tooltip text="Computable General Equilibrium">
                    <span>CGE</span>
                  </Tooltip>
                  ) характеризуют поведение экономических субъектов; они описывают спрос и
                  предложение, определяют цены на товары и факторы производства таким образом, чтобы
                  выполнялись балансовые ограничения, и дают численные результаты, пригодные для
                  использования в экономической политике.&#160;
                  <a className="footnote-backref" href="#fnref:1" title="Вернуться к тексту">
                    &#8617;
                  </a>
                </p>
              </li>
            </ol>
          </div>
        )}
      </div>
    </>
  );
};

export default EconomicEffects;
