const transport = {
  redirect_commodities_tradeRusWorld: {
    method: 'GET',
    url: 'hardcode/Russia/transport/redirect_commodities_trade/',
    params: { region: 'World' },
  },

  redirect_commodities_tradeRusCIS: {
    method: 'GET',
    url: 'hardcode/Russia/transport/redirect_commodities_trade/',
    params: { region: 'CIS' },
  },

  forecast_trade_flowExport: {
    method: 'GET',
    url: 'hardcode/Russia/transport/forecast_trade_flow/',
    params: { variable: 'export' },
  },

  forecast_trade_flowImport: {
    method: 'GET',
    url: 'hardcode/Russia/transport/forecast_trade_flow/',
    params: { variable: 'import' },
  },
};
export default transport;
