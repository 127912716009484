import React from 'react';

const AuthUser = React.createContext<any>({
  isAuthUser: false,
  openLogin: false,
  setOpenLogin: () => {},
  setIsAuthUser: () => {},

  isAuthVAVTUser: false,
  setIsAuthVAVTUser: () => {},

  commonError: '',
  setCommonError: () => {},

  link: '',
  setAuthLink: () => {},

  isAuthPerforming: true,
  setIsAuthPerforming: () => {},
});

export default AuthUser;
