import React, { useContext } from 'react';
import Context from 'context/CountryContext';
import CountryButtons from 'components/CountryButtons';
import DynamicChart from 'components/DynamicChart';

import { countries } from 'configs/countries';
import { ChartConfigs } from 'configs/pages/public/MacroeconomicIndicators';

import styles from '../Page.module.scss';

const MacroeconomicIndicators: React.FC = () => {
  const { currentCountry } = useContext(Context);

  return (
    <>
      <h1 id="_1">Макроэкономические показатели</h1>

      <CountryButtons
        countries={[
          countries.EAEU,
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Russia,
        ]}
      />
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.GDPdynamics[currentCountry]} />
      </div>
      <div className={styles.page_section}>
        {currentCountry === 'EAEU' && (
          <DynamicChart toggles={ChartConfigs.AnnualGDP[currentCountry]} />
        )}
      </div>
      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.ProductionIndustrial[currentCountry]} />
      </div>

      {process.env.REACT_APP_ROUTERS === 'full_routers' && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.AgricultureQuarterlyYearly[currentCountry]} />
        </div>
      )}
    </>
  );
};

export default MacroeconomicIndicators;
