import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/UnionState/CommonUnionState';
import styles from './CommonStylesUnionState.module.css';

const TradeInGoodsUnionState: React.FC = () => {
  const itemBlockStyle: any = {
    marginBottom: '50px',
    padding: '30px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08)',
  };

  return (
    <div>
      <h1>Торговля товарами</h1>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.CommoditiesMonthlyBelstat} />
      </div>

      {/* <div style={itemBlockStyle}>
        <p style={{ fontWeight: `bold` }}>Динамика экспорта, импорта и товарооборота (графики).</p>
        <p style={{ fontWeight: `bold` }}>1. Годовая динамика</p>

        <p style={{ marginLeft: `30px` }}>1. Торговля Россия-Беларусь (+ структура торговли)</p>
        <p style={{ marginLeft: `30px` }}>2. Торговля Россия – мир (+ прогноз до 2027 г. (МВФ))</p>
        <p style={{ marginLeft: `30px` }}>
          3. Торговля Россия - топ 5 внешних партнеров (за исключением РБ)
        </p>
        <p style={{ marginLeft: `30px` }}>
          4. Торговля Беларусь – мир (+ прогноз до 2027 г. (МВФ))
        </p>
        <p style={{ marginLeft: `30px` }}>
          5. Торговля Беларусь – топ 5 внешних партнеров (за исключением РФ))
        </p>

        <p style={{ fontWeight: `bold` }}>2. Ежемесячная динамика (накопленным итогом</p>

        <p style={{ marginLeft: `30px` }}>1. Торговля Россия-Беларусь (+ структура торговли)</p>
        <p style={{ marginLeft: `30px` }}>2. Торговля Россия – мир</p>
        <p style={{ marginLeft: `30px` }}>
          3. Торговля Россия - топ 5 внешних партнеров (за исключением РБ)
        </p>
        <p style={{ marginLeft: `30px` }}>4. Торговля Беларусь – мир</p>
        <p style={{ marginLeft: `30px` }}>
          5. Торговля Беларусь – топ 5 внешних партнеров (за исключением РФ))
        </p>
      </div> */}

      <div style={itemBlockStyle}>
        <p>
          За 2015-2021 гг. товарооборот России с Беларусью вырос в 1,6 раза – с 23,5 до 38,8 млрд
          долл. США. В частности, экспорт России на рынок Беларуси увеличился на 54% - с 15 до 23,1
          млрд долл., импорт почти в 2 раза – с 8,4 до 15,6 млрд долл.
        </p>
        <p>
          В структуре товарооборота за рассматриваемый период в наибольшей степени сократилась доля
          минеральных продуктов – с 29,1 до 19,3%. Вместе с тем выросла доля машин, оборудования и
          транспортных средств – с 15,3 до 20,4%. Вырос также удельный вес товаров химической
          промышленности – с 10 до 13% от совокупного объема товарооборота между партнерами.
        </p>
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.CommoditiesУearlyСomplementarityRusBel} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.Top5PartnersExportUS} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.Top5PartnersImportUS} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.CommoditiesTradeYearlyGroupsExportUS} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.CommoditiesTradeYearlyGroupsImportUS} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.CommoditiesTradeYearlyUS} />
      </div>
    </div>
  );
};

export default TradeInGoodsUnionState;
