import React from 'react';
import directions2021_2023 from '../downloadableFiles/Directions2021_2023.pdf';
import agreementUS from '../downloadableFiles/AgreementUS.pdf';

import styles from './CommonStylesUnionStatePublic.module.css';

const AboutProjectUnionStatePublic: React.FC = () => {
  return (
    <div className={styles.page_text}>
      <h1>О Союзном государстве</h1>

      <h2 id="Основные цели">Основные цели</h2>
      <div className={styles.itemOneBlock}>
        <p>
          Основополагающий документ Союзного государства&nbsp;(далее&nbsp;–&nbsp;СГ) - Договор о
          создании Союзного государства от&nbsp;08.12.1999&nbsp;г.{' '}
          <b>
            Среди указанных в Договоре целей, наиболее важные с точки зрения углубления интеграции
            являются следующие:
          </b>{' '}
        </p>
        <p> – создание единого экономического пространства;</p>
        <p> – формирование единой правовой системы демократического государства; </p>
        <p>
          – проведение согласованной социальной политики, направленной на создание условий,
          обеспечивающих достойную жизнь и свободное развитие человека.
        </p>
      </div>

      <h2 id="Основные цели">Основополагающие документы:</h2>
      <div className={styles.wrapperTwoBlocks}>
        <div className={styles.firstBlockElement}>
          <div className={styles.secondBlockElement}>
            <a
              href={agreementUS}
              download="Договор о создании Союзного государства.pdf"
              className={styles.downloadPDF_link}
            >
              <p>Договор о создании Союзного государства</p>
            </a>
          </div>
          <div className={styles.thirdBlockElement}>
            <p>
              <p>
                СГ предусматривает постепенный переход к гражданству Союзного государства. До
                принятия союзного нормативно-правового акта в области гражданства правовое положение
                граждан Союзного государства регулируется национальными законодательными актами
                государств-участников, договорами между ними в данной области и настоящим Договором.
              </p>
            </p>
          </div>
        </div>
        <div className={styles.firstBlockElement}>
          <div className={styles.secondBlockElement}>
            <a
              href={directions2021_2023}
              download="Декрет от 4 ноября 2021 г. No 6.pdf"
              className={styles.downloadPDF_link}
            >
              <p>
                Декрет от 4&nbsp;ноября&nbsp;2021&nbsp;г. No&nbsp;6 Об Основных направлениях
                реализации положений Договора о создании Союзного государства на
                2021&nbsp;–&nbsp;2023&nbsp;годы.
              </p>
            </a>
          </div>
          <div className={styles.thirdBlockElement}>
            <p>
              В целях реализации Договора о создании Союзного государства от 8 декабря 1999 года
              (далее – Договор), а также унификации, гармонизации и сближения законодательства
              государств – участников Союзного государства, Высший Государственный Совет Союзного
              государства{' '}
              <strong>
                <em>ПОСТАНОВЛЯЕТ:</em>
              </strong>
            </p>
            <p>
              <strong>1)</strong> Утвердить прилагаемые Основные направления реализации положений
              Договора о создании Союзного государства на 2021–2023 годы (далее – Основные
              направления) и Союзные программы, являющиеся неотъемлемой частью Основных направлений.
            </p>
            <p>
              <strong>2)</strong> Правительству Республики Беларусь и Правительству Российской
              Федерации, а также Национальному банку Республики Беларусь и Центральному банку
              Российской Федерации обеспечить реализацию мероприятий Основных направлений, а также
              выполнение мероприятий, предусмотренных Союзными программами.
            </p>
            <p>
              <strong>3)</strong> Правительству Республики Беларусь и Правительству Российской
              Федерации, а также Национальному банку Республики Беларусь и Центральному банку
              Российской Федерации представлять в установленном порядке ежегодно во втором и в
              четвертом кварталах информацию о ходе их выполнения в Совет Министров Союзного
              государства.
            </p>
            <p>
              <strong>4)</strong> Правительству Республики Беларусь и Правительству Российской
              Федерации, а также Национальному банку Республики Беларусь и Центральному банку
              Российской Федерации представить в Совет Министров Союзного государства согласованные
              предложения по реализации иных положений Договора, не предусмотренных Основными
              направлениями и Союзными программами, в срок до 31 декабря 2022 года. 5) Настоящий
              Декрет вступает в силу со дня его подписания.
            </p>
          </div>
        </div>
      </div>

      <h2 id="Основные цели">Органы Союзного государства:</h2>
      <div className={styles.itemOneBlock}>
        <p>
          <b> Высший Государственный Совет.</b> Основные функции: образует в пределах своей
          компетенции органы Союзного государства, включая органы управления отраслевого и
          функционального характера; утверждает бюджет; утверждает международные договоры Союзного
          государства; назначает выборы в Палату Представителей Парламента.
        </p>{' '}
        <p>
          <b>Совет Министров.</b> Основные функции: разработка основных направления общей политики
          по вопросам развития Союзного государства; внесение в Парламент Союзного государства
          проектов союзных законов и Основ законодательства; разработка бюджета Союзного
          государства; формирование предложений по формированию отраслевых и функциональных органов
          Союзного государства.{' '}
        </p>
        <p>
          <b>Парламент.</b> Основные функции: принимает законы и Основы законодательства Союзного
          государства по вопросам, отнесенным настоящим Договором к компетенции (или совместному
          ведению) Союзного государства; ратифицирует международные договоры, заключаемые от имени
          Союзного государства; рассматривает предложения по вопросам присоединения к Союзному
          государству третьих государств.
        </p>{' '}
        <p>
          <b>Суд СГ (не создан).</b> Основные функции: обеспечивает единообразное толкование и
          применение настоящего Договора, нормативно-правовых актов Союзного государства;
        </p>{' '}
        <p>
          <b>Счетная палата (не создана).</b> Основные функции: осуществление контроля за финансами
          Союзного государства
        </p>
      </div>

      <h2 id="Основные цели">Вопросы которые входят в совместное ведение Союзного государства</h2>
      <div className={styles.itemOneBlock}>
        <p>
          <strong>1. </strong>Принятие в состав Союзного государства других государств;
        </p>
        <p>
          <strong>2. </strong>Координация и взаимодействие в сфере внешней политики, связанные с
          осуществлением настоящего Договора;
        </p>
        <p>
          <strong>3. </strong>Проведение согласованного курса на укрепление Содружества Независимых
          Государств;
        </p>
        <p>
          <strong>4. </strong>Совместная оборонная политика, координация деятельности в области
          военного строительства, развитие вооруженных сил государств-участников, совместное
          использование военной инфраструктуры и принятие других мер для поддержания
          обороноспособности Союзного государства;
        </p>
        <p>
          <strong>5. </strong>Взаимодействие в международном сотрудничестве по военным и пограничным
          вопросам, включая реализацию заключенных государствами участниками международных договоров
          по вопросам сокращения вооруженных сил и ограничения вооружений;
        </p>
        <p>
          <strong>6. </strong>Взаимодействие в области осуществления демократических преобразований,
          реализация и защита основных прав и свобод граждан Союзного государства;
        </p>
        <p>
          <strong>7. </strong>Гармонизация и унификация законодательства государств-участников;
        </p>
        <p>
          <strong>8. </strong>Осуществление инвестиционной политики в интересах рационального
          разделения труда;
        </p>
        <p>
          <strong>9. </strong>Охрана окружающей среды;
        </p>
        <p>
          <strong>10.</strong> Совместные действия в области экологической безопасности,
          предупреждения природных и техногенных катастроф и ликвидации их последствий, в том числе
          последствий аварии на Чернобыльской АЭС;
        </p>
        <p>
          <strong>11.</strong> Развитие науки, образования, культуры, создание равных условий
          сохранения и развития этнической, культурной и языковой самобытности народов;
        </p>
        <p>
          <strong>12.</strong> Формирование общего научного, технологического и информационного
          пространства;
        </p>
        <p>
          <strong>13.</strong> Согласованная социальная политика, включая вопросы занятости,
          миграции, условий труда и его охраны, социального обеспечения и страхования;
        </p>
        <p>
          <strong>14.</strong> Обеспечение равных прав граждан в трудоустройстве и оплате труда, в
          получении образования, медицинской помощи, предоставлении других социальных гарантий;
        </p>
        <p>
          <strong>15.</strong> Борьба с терроризмом, коррупцией, распространением наркотиков и
          другими видами преступлений.
        </p>
      </div>

      <h2 id="Основные цели">Как строится работа в рамках Союзного государства</h2>
      <div className={styles.itemOneBlock}>
        <p>
          Каждые три года определяются основные направления реализации Договора о создании Союзного
          государства. Актуальные направления определены на 2021 – 2023 гг. и состоят из{' '}
          <strong>7 основных блоков</strong>:
        </p>

        <p>
          <strong>1.</strong> Проведение единой структурной макроэкономической политики и создание
          общего финансового рынка;{' '}
        </p>
        <p>
          <strong>2.</strong> Создание единого экономического пространства и обеспечение равных
          условий хозяйствования;{' '}
        </p>
        <p>
          <strong>3.</strong> Гармонизация налогового и таможенного законодательства. Проведение
          единой налоговой политики, а также единой торговой и таможенно-тарифной политики в
          отношении третьих стран, международных организаций и объединений;{' '}
        </p>
        <p>
          <strong>4.</strong> Формирование и исполнение бюджета Союзного государства;
        </p>
        <p>
          <strong>5.</strong> Проведение согласованной политики в области охраны труда, социальной
          защиты населения, пенсионного обеспечения;{' '}
        </p>
        <p>
          <strong>6.</strong> Унификация гражданского законодательства и определение основ создания
          собственности Союзного государства;{' '}
        </p>
        <p>
          <strong>7.</strong> Обеспечение реализации положений Договора о создании Союзного
          государства от 8 декабря 1999 года и наращивание российско-белорусского сотрудничества.
          Включает разработку проекта «Основных направлений реализации Договора о создании Союзного
          государства на 2024–2026 годы».{' '}
        </p>
      </div>

      <h2 id="Основные цели">О действующих Союзных программах</h2>
      <div className={styles.itemOneBlock}>
        <p>
          <strong>
            {' '}
            1. Союзная программа по гармонизации валютного регулирования и валютного контроля:
          </strong>
          <p>
            Гармонизация норм по открытию счетов, проведению валютных операций, требований к
            репатриации валютной выручки.
          </p>
        </p>
        <p>
          <strong>
            2. Союзная программа по унификации законодательства в сфере туристской деятельности:
          </strong>
          <p>
            Унификация регулирования бухгалтерского учета и составления бухгалтерской (финансовой)
            отчетности., обеспечение равных условий доступа заинтересованных лиц в Российской
            Федерации и Республики Беларусь к финансовой отчетности российских и белорусских
            хозяйствующих субъектов.
          </p>{' '}
        </p>
        <p>
          <strong>
            3. Союзная программа по введению единых правил доступа к государственному заказу и
            государственным закупкам в Республике Беларусь и Российской Федерации:
          </strong>
          <p>
            Гармонизация законодательств сторон в части обеспечения равного доступа к
            государственному заказу и государственным закупкам, в том числе установление возможности
            использования при государственных (муниципальных) закупках в Российской Федерации
            банковских гарантий, выданных банками Республики Беларусь
          </p>
        </p>
        <p>
          <strong>
            4. Союзная программа по гармонизации денежно-кредитной политики и макропруденциального
            регулирования:{' '}
          </strong>{' '}
          <p>
            Российской Федерации, реализация комплекса мер по переходу Республики Беларусь на режим
            инфляционного тестирования, а также сближение методологии и инструментария управления
            ликвидностью банковского сектора в России и Белоруссии.
          </p>
        </p>
        <p>
          <strong>5. Союзная программа в области единых правил конкуренции: </strong>
          <p>
            Определение и согласование направлений и подходов формирования и внедрения единых правил
            конкуренции.
          </p>
        </p>
        <p>
          <strong>
            6. Союзная программа по гармонизации норм регулирования кредитных и некредитных
            финансовых организаций, а также финансового рынка в целом, включая обеспечение создания
            единых принципов страхования вкладов:{' '}
          </strong>
          <p>
            Гармонизация требований в области регулирования и надзора за деятельностью участников
            финансового рынка в банковской и страховой сферах, в сфере рынка ценных бумаг,
            лизинговых организаций, микрофинансовых институтов и деятельности на внебиржевом рынке.
          </p>
        </p>
        <p>
          <strong>
            7. Союзная программа по гармонизации налогового и таможенного законодательства
            Республики Беларусь и Российской Федерации и сотрудничеству в таможенной сфере:{' '}
          </strong>{' '}
          <p>
            Подписан международный договор об общих принципах налогообложения по косвенным налогам,
            внедрена интегрированная система администрирования косвенных налогов (далее – ИСА КН), а
            также международный Договор о гармонизации таможенного законодательства двух стран.
          </p>
        </p>
        <p>
          <strong>
            8. Союзная программа по интеграции информационных систем государственных контролирующих
            органов в части ветеринарного и карантинного фитосанитарного контроля:{' '}
          </strong>{' '}
          <p>
            Интеграция информационных систем государственных контролирующих органов в области
            карантина растений и ветеринарии, которая приведет к автоматизации процесса обмена
            данными по выданным актам карантинного фитосанитарного контроля (надзора), обеспечению
            прослеживаемости подконтрольных госветнадзору товаров и подкарантинной продукции.
          </p>
        </p>
        <p>
          <strong>
            9. Союзная программа по интеграции информационных систем транспортного контроля
            государственных контролирующих органов:{' '}
          </strong>
          <p>
            Внедрение российских информационных систем транспортного контроля в Республике Беларусь
          </p>
        </p>
        <p>
          <strong>
            10. Союзная программа по унификации регулирования транспортного рынка
            государств-участников Договора о создании Союзного государства от 8 декабря 1999 года:
          </strong>{' '}
          <p>
            {' '}
            В области воздушного транспорта. Реализация согласованных подходов и мероприятий по
            обеспечению равных тарифных условий при оказании услуг аэропортов и аэронавигации в
            государствах и в отношении эксплуатантов двух государств. В области морского транспорта.
            Унификация условий допуска к плаванию по внутренним водным путям государств. В области
            автомобильного транспорта. Возможность осуществления перевозчиками обоих государств
            перевозок на безразрешительной системе
          </p>
        </p>
        <p>
          <strong>
            11. Союзная программа по формированию единых принципов функционирования единого рынка
            связи и информатизации в целях реализации положений Договора о создании Союзного
            государства от 8 декабря 1999 года:{' '}
          </strong>{' '}
          <p>
            Унификация законодательств России и Белоруссии в области почтовой связи, оказании услуг
            связи и построения инфраструктуры сетей связи, отмена роуминга на территории Союзного
            государства.
          </p>
        </p>
        <p>
          <strong>12. Программа по формированию единой промышленной политики</strong>
        </p>
        <p>
          <strong>13. Программа по формированию единой аграрной политики</strong>
        </p>
        <p>
          <strong>
            14. Союзная программа по гармонизации налогового и таможенного законодательства
            Республики Беларусь и Российской Федерации и сотрудничеству в таможенной сфере
          </strong>
        </p>
        <p>
          <strong>
            15. Союзные программы по формированию объединенных рынков газа, нефти и нефтепродуктов,
            электроэнергии
          </strong>
        </p>
        <p>
          <strong>
            16. Союзная программа по формированию единых принципов функционирования единого рынка
            связи и информатизации: Формирование единых рынков, политик и гармонизации регулирования
          </strong>
        </p>
        <p>
          <strong>17. Формирование Суда Союзного государства</strong>
        </p>
      </div>

      <p>
        При поддержке:
        <br />
        <a href="http://www.vavt.ru/science/site/cda" target="_blank" rel="noreferrer">
          Центра анализа данных Всероссийской академии внешней торговли Министерства экономического
          развития Российской Федерации.
        </a>
      </p>
    </div>
  );
};

export default AboutProjectUnionStatePublic;
