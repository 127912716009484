/* eslint-disable react/jsx-no-constructed-context-values */
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// import AuthPageVAVT from 'pages/AuthPageVAVT/AuthPageVAVT';
import App from './App';

import './metrics';

const Main = () => {
  return (
    <BrowserRouter>
      {/* @ts-ignore */}
      <App />
    </BrowserRouter>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));
