import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  News: {
    EasternPartnership: {
      Spline: {
        Azerbaijan: [
          {
            title: 'Восточное партнерство',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEPAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Восточное партнерство',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEPArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Восточное партнерство',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEPBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Восточное партнерство',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEPGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Восточное партнерство',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEPKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Восточное партнерство',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEPKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Восточное партнерство',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEPMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Восточное партнерство',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEPTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Восточное партнерство',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEPUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Восточное партнерство',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEPUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Восточное партнерство',
            chart: 'HMBarChart',
            request: { ...API.news.newsEPUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    EAEU: {
      Spline: {
        Azerbaijan: [
          {
            title: 'ЕАЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEAEUAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ЕАЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEAEUArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ЕАЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEAEUBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ЕАЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEAEUGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ЕАЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEAEUKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ЕАЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEAEUKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ЕАЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEAEUMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ЕАЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEAEUTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ЕАЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEAEUUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ЕАЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEAEUUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ЕАЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEAEUUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    EU: {
      Spline: {
        Azerbaijan: [
          {
            title: 'ЕС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEUAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ЕС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEUArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ЕС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEUBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ЕС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEUGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ЕС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEUKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ЕС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEUKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ЕС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEUMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ЕС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEUTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ЕС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEUUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ЕС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsEUUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ЕС',
            chart: 'HMBarChart',
            request: { ...API.news.newsEUUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    China: {
      Spline: {
        Azerbaijan: [
          {
            title: 'Китай',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsChinaAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Китай',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsChinaArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Китай',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsChinaBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Китай',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsChinaGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Китай',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsChinaKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Китай',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsChinaKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Китай',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsChinaMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Китай',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsChinaTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Китай',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsChinaUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Китай',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsChinaUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Китай',
            chart: 'HMBarChart',
            request: { ...API.news.newsChinaUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    NATO: {
      Spline: {
        Azerbaijan: [
          {
            title: 'НАТО',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsNATOAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'НАТО',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsNATOArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'НАТО',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsNATOBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'НАТО',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsNATOGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'НАТО',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsNATOKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'НАТО',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsNATOKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'НАТО',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsNATOMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'НАТО',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsNATOTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'НАТО',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsNATOUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'НАТО',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsNATOUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'НАТО',
            chart: 'HMBarChart',
            request: { ...API.news.newsNATOUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    CSTO: {
      Spline: {
        Azerbaijan: [
          {
            title: 'ОДКБ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCSTOAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ОДКБ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCSTOArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ОДКБ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCSTOBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ОДКБ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCSTOGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ОДКБ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCSTOKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ОДКБ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCSTOKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ОДКБ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCSTOMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ОДКБ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCSTOTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ОДКБ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCSTOUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ОДКБ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCSTOUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ОДКБ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCSTOUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    BRI: {
      Spline: {
        Azerbaijan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBRIAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBRIArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBRIBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBRIGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBRIKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBRIKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBRIMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBRITajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBRIUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBRIUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRITajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRITajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ОПОП Шелковый путь',
            chart: 'HMBarChart',
            request: { ...API.news.newsBRIUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    OIC: {
      Spline: {
        Azerbaijan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOICAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOICArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOICBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOICGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOICKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOICKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOICMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOICTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOICUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOICUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Организация исламского сотрудничества (ОИС)',
            chart: 'HMBarChart',
            request: { ...API.news.newsOICUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    OTC: {
      Spline: {
        Azerbaijan: [
          {
            title: 'Организация тюркских государств',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOTCAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Организация тюркских государств',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOTCArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Организация тюркских государств',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOTCBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Организация тюркских государств',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOTCGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Организация тюркских государств',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOTCKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Организация тюркских государств',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOTCKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Организация тюркских государств',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOTCMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Организация тюркских государств',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOTCTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Организация тюркских государств',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOTCUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Организация тюркских государств',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsOTCUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Организация тюркских государств',
            chart: 'HMBarChart',
            request: { ...API.news.newsOTCUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    BSEC: {
      Spline: {
        Azerbaijan: [
          {
            title: 'ОЧЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBSECAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ОЧЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBSECArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ОЧЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBSECBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ОЧЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBSECGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ОЧЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBSECKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ОЧЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBSECKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ОЧЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBSECMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ОЧЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBSECTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ОЧЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBSECUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ОЧЭС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsBSECUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ОЧЭС',
            chart: 'HMBarChart',
            request: { ...API.news.newsBSECUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    Russia: {
      Spline: {
        Azerbaijan: [
          {
            title: 'Россия',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRussiaAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Россия',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRussiaArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Россия',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRussiaBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Россия',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRussiaGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Россия',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRussiaKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Россия',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRussiaKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Россия',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRussiaMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Россия',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRussiaTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Россия',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRussiaUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Россия',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRussiaUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Россия',
            chart: 'HMBarChart',
            request: { ...API.news.newsRussiaUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    CIS: {
      Spline: {
        Azerbaijan: [
          {
            title: 'СНГ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCISAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'СНГ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCISArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'СНГ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCISBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'СНГ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCISGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'СНГ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCISKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'СНГ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCISKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'СНГ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCISMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'СНГ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCISTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'СНГ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCISUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'СНГ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsCISUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'СНГ',
            chart: 'HMBarChart',
            request: { ...API.news.newsCISUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    RB: {
      Spline: {
        Azerbaijan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRBAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRBArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRBBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRBGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRBKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRBKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRBMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRBTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRBUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsRBUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Союзное государство РБ и РФ',
            chart: 'HMBarChart',
            request: { ...API.news.newsRBUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    USA: {
      Spline: {
        Azerbaijan: [
          {
            title: 'США',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsUSAAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'США',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsUSAArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'США',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsUSABelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'США',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsUSAGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'США',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsUSAKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'США',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsUSAKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'США',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsUSAMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'США',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsUSATajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'США',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsUSAUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'США',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsUSAUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSABelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSATajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSABelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSATajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'США',
            chart: 'HMBarChart',
            request: { ...API.news.newsUSAUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    Turkey: {
      Spline: {
        Azerbaijan: [
          {
            title: 'Турция',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsTurkeyAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Турция',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsTurkeyArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Турция',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsTurkeyBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Турция',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsTurkeyGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Турция',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsTurkeyKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Турция',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsTurkeyKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Турция',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsTurkeyMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Турция',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsTurkeyTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Турция',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsTurkeyUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Турция',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsTurkeyUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'Турция',
            chart: 'HMBarChart',
            request: { ...API.news.newsTurkeyUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    SCO: {
      Spline: {
        Azerbaijan: [
          {
            title: 'ШОС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsSCOAzeSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ШОС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsSCOArmSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ШОС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsSCOBelSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ШОС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsSCOGeoSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ШОС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsSCOKazSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ШОС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsSCOKyrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ШОС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsSCOMolSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ШОС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsSCOTajSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ШОС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsSCOUzbSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ШОС',
            chart: 'SplineChartOneY',
            request: { ...API.news.newsSCOUkrSpline },
            chartOptions: { ...ChartOptions.SplineChartOneY.preset5 },
          },
        ],
      },
      Bars: {
        Azerbaijan: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOAzeBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Armenia: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOArmBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Belarus: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOBelBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Georgia: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOGeoBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOKazBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOKyrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Moldova: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOMolBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Tajikistan: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOTajBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOUzbBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
        Ukraine: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOUkrBars },
            chartOptions: { ...ChartOptions.HMBarChart.preset4 },
          },
        ],
      },
      Quantity: {
        Azerbaijan: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOAzeQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Armenia: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOArmQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Belarus: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOBelQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Georgia: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOGeoQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kazakhstan: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOKazQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOKyrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Moldova: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOMolQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Tajikistan: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOTajQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Uzbekistan: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOUzbQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
        Ukraine: [
          {
            title: 'ШОС',
            chart: 'HMBarChart',
            request: { ...API.news.newsSCOUkrQuantity },
            chartOptions: { ...ChartOptions.HMBarChart.preset5 },
          },
        ],
      },
    },
    Quantity: {
      Bars: {
        Azerbaijan: [
          {
            title: 'Общее количество новостей Азербайджан',
            chart: 'HMBarChart',
            request: { ...API.news.newsQuantityAze },
            chartOptions: { ...ChartOptions.HMBarChart.preset6 },
          },
        ],
        Armenia: [
          {
            title: 'Общее количество новостей Армения',
            chart: 'HMBarChart',
            request: { ...API.news.newsQuantityArm },
            chartOptions: { ...ChartOptions.HMBarChart.preset6 },
          },
        ],
        Belarus: [
          {
            title: 'Общее количество новостей Беларусь',
            chart: 'HMBarChart',
            request: { ...API.news.newsQuantityBel },
            chartOptions: { ...ChartOptions.HMBarChart.preset6 },
          },
        ],
        Georgia: [
          {
            title: 'Общее количество новостей Грузия',
            chart: 'HMBarChart',
            request: { ...API.news.newsQuantityGeo },
            chartOptions: { ...ChartOptions.HMBarChart.preset6 },
          },
        ],
        Kazakhstan: [
          {
            title: 'Общее количество новостей Казахстан',
            chart: 'HMBarChart',
            request: { ...API.news.newsQuantityKaz },
            chartOptions: { ...ChartOptions.HMBarChart.preset6 },
          },
        ],
        Kyrgyzstan: [
          {
            title: 'Общее количество новостей Кыргызстан',
            chart: 'HMBarChart',
            request: { ...API.news.newsQuantityKyr },
            chartOptions: { ...ChartOptions.HMBarChart.preset6 },
          },
        ],
        Moldova: [
          {
            title: 'Общее количество новостей Молдова',
            chart: 'HMBarChart',
            request: { ...API.news.newsQuantityMol },
            chartOptions: { ...ChartOptions.HMBarChart.preset6 },
          },
        ],
        Tajikistan: [
          {
            title: 'Общее количество новостей Таджикистан',
            chart: 'HMBarChart',
            request: { ...API.news.newsQuantityTaj },
            chartOptions: { ...ChartOptions.HMBarChart.preset6 },
          },
        ],
        Uzbekistan: [
          {
            title: 'Общее количество новостей Узбекистан',
            chart: 'HMBarChart',
            request: { ...API.news.newsQuantityUzb },
            chartOptions: { ...ChartOptions.HMBarChart.preset6 },
          },
        ],
        Ukraine: [
          {
            title: 'Общее количество новостей Украина',
            chart: 'HMBarChart',
            request: { ...API.news.newsQuantityUkr },
            chartOptions: { ...ChartOptions.HMBarChart.preset6 },
          },
        ],
      },
    },
  },
};
