import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/EAEU/LaborAndIncome';

import styles from '../Page.module.scss';

const LaborAndIncome: React.FC = () => {
  return (
    <>
      <h1>Труд и доходы населения</h1>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.UnemploymentRate} />
      </div>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.RealMonetaryIncomeIndexes} />
      </div>
    </>
  );
};

export default LaborAndIncome;
