import React from 'react';
import Table from 'components/Table';

const Sources: React.FC = () => {
  return (
    <Table
      tableHead={[
        'Блок',
        'Перечень показателей',
        'География',
        'Величины',
        'Источники информации',
        'Периодичность обновления',
        'Отражение показателя в текущей версии макета',
      ]}
      tableBody={[
        [
          'Динамика ВВП',
          'Изменение реального ВВП стран-членов СНГ, ЕАЭС, в % к предыдущему году  В целом, по интеграционным блокам и по каждой стране',
          'СНГ и ЕАЭС',
          '- % к предыдущему году',
          'Статкомитет СНГ, раздел «Макропоказатели по СНГ за прошедший квартал», ЕЭК, национальные счета',
          'Ежеквартально',
          'Только для ЕАЭС и его стран-членов',
        ],
        [
          'Торговля товарами',
          'Взаимная торговля в рамках интеграционных объединений. Совокупные объемы по каждому объединению.',
          'СНГ и ЕАЭС',
          '- Стоимостные - Физические показатели',
          '<a href="http://www.cisstat.com/0base/frame-rus.htm">База данных Статкомитета СНГ</a> (требуется доступ) <a href="http://www.eurasiancommission.org/ru/act/integr_i_makroec/dep_stat/tradestat/Pages/default.aspx">ЕЭК</a>, статистика внешней и взаимной торговли товарами <a href="https://comtrade.un.org/">UN Comtrade Database</a> Национальные таможенные ведомства. Например, данные базы ФТС РФ через Российский экспортный центр (РЭЦ)',
          'Ежемесячно (с лагом в 2 месяца). Например, сейчас доступны данные только за январь-февраль по всем странам СНГ. Для России доступны данные за март 2021 г.',
          '',
        ],
        [
          '',
          'Товарооборот, совокупные объемы экспорта и импорта товаров интеграционных блоков с внешними партнерами.  Товарная структура экспорта и импорта товаров интеграционных блоков с внешними партнерами.',
          'СНГ, ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Стоимостные - Физические показатели',
          '',
          '',
          '',
        ],
        [
          '',
          'Доля взаимной торговли в совокупном товарообороте интеграционных блоков',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Соотношение, в %',
          '',
          '',
          '',
        ],
        [
          '',
          'Двусторонняя торговля (оборот, экспорт, импорт) между партнерами (пары стран) в рамках интеграционных блоков.  Товарная структура экспорта и импорта товаров.',
          'СНГ',
          '- Стоимостные - Физические показатели',
          '',
          '',
          'Между Россией и странами ЕАЭС',
        ],
        [
          '',
          'Двусторонняя торговля (оборот, экспорт, импорт) между участниками СНГ и странами вне интеграционного блока  Товарная структура экспорта и импорта товаров.',
          'СНГ, страны вне рассматриваемых интеграционных блоков',
          '- Стоимостные - Физические показатели',
          '',
          '',
          '',
        ],
        [
          '',
          'Доля торговли страны с партнерами по СНГ и ЕАЭС в ее совокупном товарообороте',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Соотношение, в %',
          '',
          '',
          '',
        ],
        [
          'Торговля услугами',
          'Взаимная торговля в рамках интеграционных объединений. Совокупные объемы по каждому объединению.',
          'СНГ и ЕАЭС',
          '- Стоимостные',
          'Центральные банки стран СНГ. Например, для России: ЦБ РФ, <a href="https://cbr.ru/statistics/macro_itm/svs/">статистика внешнего сектора</a>',
          'Ежегодно',
          '',
        ],
        [
          '',
          'Совокупные объемы экспорта и импорта услуг интеграционных блоков с внешними партнерами.',
          'СНГ, ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Стоимостные',
          '',
          '',
          '',
        ],
        [
          '',
          'Доля взаимной торговли услугами в совокупном обороте интеграционных блоков',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Соотношение, в %',
          '',
          '',
          '',
        ],
        [
          '',
          'Двусторонняя торговля между партнерами (пары стран) в рамках интеграционных блоков  Структура экспорта и импорта услуг',
          'СНГ',
          '- Стоимостные',
          '',
          '',
          'Между Россией и странами ЕАЭС',
        ],
        [
          '',
          'Двусторонняя торговля услугами между участниками СНГ и странами вне интеграционного блока  Структура экспорта и импорта услуг',
          'СНГ, страны вне рассматриваемых интеграционных блоков',
          '- Стоимостные',
          '',
          '',
          '',
        ],
        [
          '',
          'Доля торговли услугами страны с партнерами по СНГ и ЕАЭС в ее совокупном обороте услуг',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Соотношение, в %',
          '',
          '',
          '',
        ],
        [
          'Прямые иностранные инвестиции (ПИИ)',
          'Динамика взаимных ПИИ внутри СНГ и ЕАЭС.',
          'СНГ и ЕАЭС',
          '- Стоимостные',
          '<a href="http://www.cisstat.com/0base/frame-rus.htm">База данных Статкомитета СНГ</a> (требуется доступ). Центральные банки стран СНГ. Например, для России: ЦБ РФ, <a href="https://cbr.ru/statistics/macro_itm/svs/">статистика внешнего сектора</a>',
          'Ежегодно',
          '',
        ],
        [
          '',
          'Динамика совокупных объемов ПИИ между СНГ, ЕАЭС и партнерами вне интеграционных блоков',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Стоимостные',
          '',
          '',
          '',
        ],
        [
          '',
          'Доля взаимных ПИИ внутри СНГ и ЕАЭС в совокупном объеме ПИИ',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Соотношение, в %',
          '',
          '',
          '',
        ],
        [
          '',
          'Двусторонняя динамика ПИИ между участниками СНГ и странами вне интеграционного блока',
          'СНГ, страны вне рассматриваемых интеграционных блоков',
          '- Стоимостные',
          '',
          '',
          '',
        ],
        [
          '',
          'Доля ПИИ, получаемых страной от партнеров по СНГ и ЕАЭС от совокупного объема, полученных ПИИ за определенный период',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Соотношение, в %',
          '',
          '',
          '',
        ],
        [
          '',
          'Доля ПИИ, направляемых страной в страны-партнеры по СНГ и ЕАЭС от совокупного объема ее направленных ПИИ за определенный период',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Соотношение, в %',
          '',
          '',
          '',
        ],
        [
          'Миграционные потоки',
          'Двусторонние потоки трудовых мигрантов между партнерами по СНГ',
          'СНГ',
          '- Количество человек',
          'Данные министерств внутренних дел стран СНГ. Например, для России: МВД РФ, <a href="https://мвд.рф/Deljatelnost/statistics/migracionnaya/item/24261216/">сводка основных показателей деятельности по миграционной ситуации (до апреля 2021 г.)</a>',
          'Ежегодно. Для России есть информация за каждый месяц',
          '',
        ],
        [
          '',
          'Двусторонние потоки трудовых мигрантов между странами СНГ и внешними партнерами вне интеграционных объединений (СНГ и ЕАЭС)',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Количество человек',
          '',
          '',
          '',
        ],
        [
          '',
          'Доля трудовых мигрантов, прибывших в страны СНГ, ЕАЭС от их совокупного потока, формируемого странами СНГ, ЕАЭС',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Соотношение, в %',
          '',
          '',
          '',
        ],
        [
          'Трансграничные переводы физ. лиц',
          'Двусторонние объемы трансграничных переводов резидентов и нерезидентов между партнерами по СНГ',
          'СНГ',
          '- Стоимостные объемы',
          'Центральные банки стран СНГ. Например, для России: ЦБ РФ, статистика внешнего сектора',
          'Ежегодно',
          'Между Россией и странами ЕАЭС',
        ],
        [
          '',
          'Двусторонние объемы трансграничных переводов резидентов и нерезидентов между страной СНГ и внешним партнером вне интеграционного объединения',
          'СНГ, страны вне рассматриваемых интеграционных блоков',
          '- Стоимостные объемы',
          '',
          '',
          '',
        ],
        [
          '',
          'Доля стран СНГ, ЕАЭС в совокупном объеме полученных и направленных страной трансграничных переводов',
          'СНГ и ЕАЭС, страны вне рассматриваемых интеграционных блоков',
          '- Соотношение, в %',
          '',
          '',
          '',
        ],
      ]}
      width="100%"
      theadAlign="center"
      useHTML
    />
  );
};

export default Sources;
