import React from 'react';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/EAEU/MigrationFlows';

import styles from '../Page.module.scss';

const MigrationFlows: React.FC = () => {
  return (
    <>
      <h1>Миграционные потоки</h1>

      <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.chartMigrantsRusFromEAEU} />
      </div>

      {/* график скрыт поскольку у источника некачественные данные */}
      {/* <div className={styles.page_section}>
        <DynamicChart toggles={ChartConfigs.chartMigrationThisYear} />
      </div> */}
    </>
  );
};

export default MigrationFlows;
