import React, { ReactElement, useState } from 'react';
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/caret-right.svg';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import styles from './Comment.module.scss';

const Comment: React.FC<{ content: ReactElement; title: string }> = ({ content, title }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={open ? classNames(styles.commentUi, styles.commentUiOpen) : styles.commentUi}>
      <div
        className={styles.summary}
        onClick={() => setOpen(!open)}
        onKeyPress={() => setOpen(!open)}
        role="button"
        tabIndex={0}
      >
        <div>
          <p>{title}</p>
        </div>
        {/* @ts-ignore */}
        <ArrowForwardIcon className={styles.commentUiArrowIcon} />
      </div>
      {/* @ts-ignore */}
      <AnimateHeight duration={500} height={open ? 'auto' : 0}>
        {content}
      </AnimateHeight>
    </div>
  );
};

export default Comment;
