import React, { useState, useEffect, useContext, useCallback } from 'react';
import SidebarContext from 'context/SidebarContext';
import ProjectMainDir from 'context/MainContext';
import { useLocation } from 'react-router-dom';
import LogoIMG from 'assets/img/heraldry_big.svg';
import SidebarToggleIMG from 'assets/icons/navbar-toggle.svg';
import classnames from 'classnames';
import { sidebarContentOptions } from '../../configs/functions/sidebarContentOptions';
import { sidebarOptions, SidebarItemType } from '../../configs/functions/sidebarOptions';
import SidebarItem from './components/SidebarItem';
import SidebarNav from './components/SidebarNav';
import styles from './Sidebar.module.scss';

const Sidebar: React.FC = () => {
  const { showSidebar, setShowSidebar } = useContext(SidebarContext);
  const { isMainDir } = useContext(ProjectMainDir);

  const [checkboxChecked, setCheckboxChecked] = useState({}); // объект, которые хранит информацию о вкладках (открыты/закрыты)

  const sidebarContentConfig = sidebarContentOptions(isMainDir); // объект для создания оглавления на странице (якорные ссылки)
  const sidebarConfig = sidebarOptions(isMainDir); // объект для создания ссылок и вкладок сайдбара
  const location = useLocation();

  // обновление checkboxChecked при изменении location:
  // при обновлении страницы вкладки автоматически открывается до уровня на котором расположена текущая страница
  useEffect(() => {
    const obj = { ...checkboxChecked };
    const arrURL = location.pathname.slice(1).split('/');
    let path = '';
    for (let i = 0; i < arrURL.length - 1; i++) {
      if (path.length) {
        obj[`${path}/${arrURL[i]}`] = true;
        path = `${path}/${arrURL[i]}`;
      } else {
        obj[arrURL[i]] = true;
        path = arrURL[i];
      }
    }
    setCheckboxChecked({ ...obj });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // рекурсивная функция для создания разметки, если у объекта есть открывающиеся подсекции
  const recursiveSections = (obj: SidebarItemType, locationIncludes: string, content: any) => {
    const objSubsections = obj.subsections;
    const includesPath = locationIncludes.length ? `${locationIncludes}/${obj.head}` : obj.head;
    const createSidebarSubsections = Object.keys(objSubsections).map((key) => {
      if (!objSubsections[key].subsections) {
        return (
          <SidebarItem
            key={objSubsections[key].head}
            obj={objSubsections[key]}
            content={objSubsections[key].items && content[objSubsections[key].head]}
          />
        );
      }
      return recursiveSections(
        objSubsections[key],
        includesPath,
        content[objSubsections[key].head]
      );
    });

    return (
      <SidebarNav
        key={includesPath}
        locationIncludes={includesPath}
        iconType={obj.iconType}
        title={obj.title}
        createSidebarSubsections={createSidebarSubsections}
        checkboxChecked={checkboxChecked}
        setCheckboxChecked={setCheckboxChecked}
      />
    );
  };

  // создания разметки сайдбара
  const createSidebar = useCallback(
    () =>
      Object.keys(sidebarConfig).map((key) => {
        if (!sidebarConfig[key].subsections) {
          return (
            <SidebarItem
              key={sidebarConfig[key].head}
              obj={sidebarConfig[key]}
              content={sidebarConfig[key].items && sidebarContentConfig[sidebarConfig[key].head]}
            />
          );
        }
        if (sidebarContentConfig[sidebarConfig[key].head]) {
          return recursiveSections(
            sidebarConfig[key],
            '',
            sidebarContentConfig[sidebarConfig[key].head]
          );
        }
        return recursiveSections(sidebarConfig[key], '', {});
      }),
    [isMainDir]
  );

  return (
    <div
      className={
        showSidebar
          ? classnames(styles.sidebar, styles.sidebar_primary, styles.sidebar_open)
          : classnames(styles.sidebar, styles.sidebar_primary)
      }
    >
      <nav
        className={classnames(
          styles.sidebar_nav,
          styles.sidebar_nav_primary,
          styles.sidebar_nav_integrated
        )}
      >
        <div className={styles.sidebar_top}>
          <img src={LogoIMG} className={styles.sidebar_logo} alt="logo" />
          <div
            className={styles.sidebar_toggle}
            role="button"
            tabIndex={0}
            onClick={() => setShowSidebar(!showSidebar)}
            onKeyPress={() => setShowSidebar(!showSidebar)}
          >
            <img src={SidebarToggleIMG} alt="sidebar_toggle" />
          </div>
        </div>

        {!isMainDir ? (
          <div className={styles.sidebar_text}>
            <div>
              Мониторинг состояния реализации Союзных программ и торгово&nbsp;-&nbsp;экономического
              сотрудничества в рамках Союзного государства{' '}
            </div>
          </div>
        ) : (
          <div className={styles.sidebar_text}>
            <div>Оценки влияния ЕАЭC на ВВП и торговлю государств&nbsp;—&nbsp;членов</div>
          </div>
        )}

        <div
          className={classnames(styles.sidebar_nav_list, styles.sidebar_scrollwrap)}
          onMouseEnter={() => setShowSidebar(true)}
          onMouseLeave={() => setShowSidebar(false)}
        >
          {createSidebar()}
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
