import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  redirect_commodities_tradeRusWorld: [
    {
      title: '',
      chart: 'HBarChart',
      request: { ...API.transport.redirect_commodities_tradeRusWorld },
      chartOptions: { ...ChartOptions.HBarChart.preset1 },
    },
  ],

  redirect_commodities_tradeRusCIS: [
    {
      title: '',
      chart: 'HBarChart',
      request: { ...API.transport.redirect_commodities_tradeRusCIS },
      chartOptions: { ...ChartOptions.HBarChart.preset1 },
    },
  ],

  forecast_trade_flowExport: [
    {
      title: '',
      chart: 'HBarChart',
      request: { ...API.transport.forecast_trade_flowExport },
      chartOptions: { ...ChartOptions.HBarChart.preset9 },
    },
  ],

  forecast_trade_flowImport: [
    {
      title: '',
      chart: 'HBarChart',
      request: { ...API.transport.forecast_trade_flowImport },
      chartOptions: { ...ChartOptions.HBarChart.preset9 },
    },
  ],
};
