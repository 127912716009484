/* eslint-disable */
import React from 'react';
import styles from './Table.module.scss';

type TheadObj = {
  title: string;
  colspan?: number;
  img?: string;
};

const Table: React.FC<{
  caption?: string; // заголовок
  tableHead?: Array<string | number> | Array<Array<string | TheadObj>>; // заголовок столбцов
  tableBody: Array<Array<string | number>>; // содержимое строк
  width?: string; // ширина
  theadAlign?: 'left' | 'center' | 'right' | 'justify' | 'char' | undefined; // выравнивание для заголовков столбцов
  tbodyClass?: 'first' | 'all' | undefined; // задание класса для ячейки: для первой каждой строки; для всех; undefined
  useHTML?: boolean; // использование html элементов в ячейке
}> = ({ caption, tableHead, tableBody, width, theadAlign, tbodyClass, useHTML = false }) => {
  const itemContent = (item) => {
    return { __html: item };
  };

  // создание заголовков столбцов
  const func = () => {
    let flag = false;
    const returnHead = tableHead?.map((item, index) => {
      if (Array.isArray(item)) {
        flag = true;
        const itemArr = item.map((item, index) => {
          if (typeof item === 'object') {
            if (item?.colspan) {
              // возвращает заголовочную ячейку с возможностью задания св-ва colSpan,
              // для элемента массива: {title: string, colspan: number}
              return (
                <th key={index} colSpan={item.colspan} align={theadAlign}>
                  {item.title}
                </th>
              );
            }
            if (item?.img) {
              return (
                <th key={index} align={theadAlign}>
                  <img src={item.img} alt="countryFlag" />
                  {item.title}
                </th>
              );
            }
            return null;
          } else {
            return (
              <th key={index} align={theadAlign}>
                {item}
              </th>
            );
          }
        });
        return <tr key={index}>{itemArr}</tr>;
      } else {
        return (
          <th key={index} align={theadAlign}>
            {item}
          </th>
        );
      }
    });
    return flag ? returnHead : <tr>{returnHead}</tr>;
  };

  // создание строк таблицы
  const returnBody = tableBody.map((row, index) => {
    const tableRow = row.map((item, index) => {
      let classTableRow = false;
      if (tbodyClass === 'first' && index === 0) {
        classTableRow = true;
      }
      if (tbodyClass === 'all') {
        classTableRow = true;
      }
      if (tableHead || index !== 0) {
        if (useHTML) {
          return (
            <td
              dangerouslySetInnerHTML={itemContent(item)}
              className={classTableRow ? 'var' : undefined}
              key={index}
            />
          );
        } else {
          return (
            <td className={classTableRow ? 'var' : undefined} key={index}>
              {item}
            </td>
          );
        }
      } else {
        // в случае, если заголовок таблицы не задан, первый столбец имеет тег <th></th>
        return (
          <th className={classTableRow ? 'var' : undefined} key={index}>
            {item}
          </th>
        );
      }
    });
    return <tr key={index}>{tableRow}</tr>;
  });

  // стиль {whiteSpace: 'pre-line'} задан, чтобы учитывать переносы в тексте \n
  return (
    <table className={styles.table} style={{ width }}>
      <caption>{caption}</caption>
      <thead>{func()}</thead>
      <tbody>{returnBody}</tbody>
    </table>
  );
};

export default Table;
