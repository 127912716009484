import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  GDPdynamicsAze: {
    Azerbaijan: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPvolumeByQuarterFreqRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset4 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset3 },
      },
    ],
  },
  GDPdynamics: {
    Russia: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPvolumeByQuarterFreqRus },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Belarus: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPvolumeByQuarterFreqBel },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPdynamicsBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Armenia: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPvolumeByQuarterFreqArm },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Kyrgyzstan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPvolumeByQuarterFreqKyr },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPdynamicsKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Kazakhstan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPvolumeByQuarterFreqKaz },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Azerbaijan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPvolumeByQuarterFreqAze },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActLastFreqAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },
  ChartGDPbyAct: {
    Russia: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPByActLastRus },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset7 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActRus },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset7 },
      },
    ],
    Belarus: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPByActLastBel },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset7 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActBel },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset7 },
      },
    ],
    Armenia: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPByActLastArm },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset7 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActArm },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset7 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPByActLastKyr },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset7 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActKyr },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset7 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Актуальные',
        request: { ...API.GDP.GDPByActLastKaz },
        chart: 'BarChart',
        chartOptions: { ...ChartOptions.BarChart.preset7 },
      },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActKaz },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset7 },
      },
    ],
    Moldova: [
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByActMol },
        chart: 'HBarChart',
        chartOptions: { ...ChartOptions.HBarChart.preset7 },
      },
    ],
  },

  GDPGrowth: {
    Russia: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Belarus: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Armenia: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Kyrgyzstan: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Kazakhstan: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Azerbaijan: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthAze },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Moldova: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthMol },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Tajikistan: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthTaj },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Uzbekistan: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthUzb },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Ukraine: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthUkr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Turkmenistan: [
      {
        title: '',
        request: { ...API.GDP.GDPGrowthTyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
  },
  GNIPrices: {
    Russia: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalRus },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
    Belarus: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalBel },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
    Armenia: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalArm },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalKyr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalKaz },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
    Azerbaijan: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDAze },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalAze },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
    Georgia: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDGeo },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalGeo },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
    Moldova: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDMol },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalMol },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
    Tajikistan: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDTaj },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalTaj },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDUzb },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalUzb },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
    Ukraine: [
      {
        title: 'Долл. США',
        request: { ...API.GNI.GNIPricesUSDUkr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
      {
        title: 'Национальная валюта',
        request: { ...API.GNI.GNIPricesLocalUkr },
        chart: 'SplineChartOneY',
        chartOptions: { ...ChartOptions.SplineChartOneY.preset1 },
      },
    ],
  },
  ProductionIndustrialGeo: [
    {
      title: '',
      request: { ...API.GDP.YearlyProductionGeo },
      chart: 'SplineBarChart',
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],

  ProductionIndustrial: {
    Russia: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.industrialProduction.productionQuarterRus },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Belarus: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.industrialProduction.productionQuarterBel },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Armenia: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.industrialProduction.productionQuarterArm },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Kyrgyzstan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.industrialProduction.productionQuarterKyr },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Kazakhstan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.industrialProduction.productionQuarterKaz },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Azerbaijan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.industrialProduction.productionQuarterAze },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.industrialProduction.productionYearlyAze },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Moldova: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.industrialProduction.productionQuarterMol },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.YearlyProductionMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },

  GDPYearlyQuarterly: {
    Ukraine: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPByQuarterUkr },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByYearUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Moldova: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPByQuarterMol },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByYearMol },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Uzbekistan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPByQuarterUzb },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByYearUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Tajikistan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPByQuarterTaj },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByYearTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Georgia: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.GDPByQuarterGeo },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.GDPByYearGeo },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },

  IndustrialQuarterYear: {
    Ukraine: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.IndustrialByQuarterUkr },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.IndustrialByYearUkr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Uzbekistan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.IndustrialByQuarterUzb },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.IndustrialByYearUzb },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Tajikistan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.IndustrialByQuarterTaj },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.IndustrialByYearTaj },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },

  AgricultureQuarterlyYearly: {
    Armenia: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.AgricultureQuarterlyArm },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyArm },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Belarus: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.AgricultureQuarterlyBel },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyBel },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Russia: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.AgricultureQuarterlyRus },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyRus },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Kyrgyzstan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.AgricultureQuarterlyKyr },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyKyr },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
    Kazakhstan: [
      // {
      //   title: 'Актуальные',
      //   request: { ...API.GDP.AgricultureQuarterlyKaz },
      //   chart: 'SplineBarChart',
      //   chartOptions: { ...ChartOptions.SplineBarChart.preset2 },
      // },
      {
        title: 'Исторические',
        request: { ...API.GDP.AgricultureYearlyKaz },
        chart: 'SplineBarChart',
        chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
      },
    ],
  },

  YearlyAgricultureAbh: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.GDP.YearlyAgricultureAbh },
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],

  YearlyGdpAbh: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.GDP.YearlyGdpAbh },
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],

  YearlyIndustrialAbh: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.GDP.YearlyIndustrialAbh },
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],

  YearlyAgricultureSO: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.GDP.YearlyAgricultureSO },
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],

  YearlyGdpSO: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.GDP.YearlyGdpSO },
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],

  YearlyIndustrialSO: [
    {
      title: '',
      chart: 'SplineBarChart',
      request: { ...API.GDP.YearlyIndustrialSO },
      chartOptions: { ...ChartOptions.SplineBarChart.preset7 },
    },
  ],
};
