import { useContext } from 'react';
import CountryButtons from 'components/CountryButtons';
import { countries } from 'configs/countries';
import DynamicChart from 'components/DynamicChart';
import { ChartConfigs } from 'configs/pages/UnionState/CommonUnionState';
import Context from 'context/CountryContext';
import DownloadXLSX from 'assets/img/xlsx.svg';
import DownloadPDF from 'assets/img/pdf.svg';

import styles from '../Page.module.scss';

import exportPotential from './exportPotential.xlsx';
import calculationMethodology from './calculationMethodology.pdf';

const ExportPotentialSC = () => {
  const { currentCountry } = useContext(Context);

  const countrySet = [
    'EAEU',
    'CIS',
    'Belarus',
    'Armenia',
    'Kyrgyzstan',
    'Kazakhstan',
    'Azerbaijan',
    'Moldova',
    'Tajikistan',
    'Turkmenistan',
    'Uzbekistan',
  ];

  const itemBlockStyle: any = {
    marginBottom: '50px',
    padding: '30px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 24px rgba(0, 0, 0, 0.08)',
  };

  return (
    <>
      <h1 id="_1">Нереализованный экспортный потенциал России на рынки стран СНГ</h1>
      <CountryButtons
        countries={[
          countries.CIS,
          countries.EAEU,
          countries.Armenia,
          countries.Belarus,
          countries.Kazakhstan,
          countries.Kyrgyzstan,
          countries.Azerbaijan,
          countries.Moldova,
          countries.Tajikistan,
          countries.Turkmenistan,
          countries.Uzbekistan,
        ]}
      />

      {countrySet.includes(currentCountry) && (
        <div className={styles.page_section}>
          <DynamicChart toggles={ChartConfigs.ExportPotential[currentCountry]} />
        </div>
      )}

      {currentCountry === 'EAEU' && (
        <>
          <h2>ЕАЭС</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что объем нереализованного экспортного потенциала России
              на рынки стран ЕАЭС составляет 4,86 млрд долл. США, что соответствует 15% от уровня
              экспорта России в страны ЕАЭС за 2021 г. (34,1 млрд долл.).
            </p>
            <p>
              Наибольший экспортный потенциал был идентифицирован для минеральных продуктов (руды и
              концентраты драгоценных металлов, а также цинковые), пищевых и сельскохозяйственных
              товаров, а также для товаров с высокой добавленной стоимостью, включая части
              транспортных средств, готовую железнодорожную технику.
            </p>
          </div>
        </>
      )}

      {currentCountry === 'CIS' && (
        <>
          <h2>СНГ</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что совокупный объем нереализованного экспортного
              потенциала России на рынки стран СНГ (за исключением Украины),{' '}
              <b>
                составляет 8,14 млрд долл. США, что соответствует 14,3% от уровня экспорта России в
                страны СНГ 2021 г. (56,8 млрд долл.).{' '}
              </b>
            </p>
            <p>
              Важно отметить, что оцениваемый объем достигается за счет наращивания поставок
              неэнергетических товаров. В основе рассматриваемой методологии лежит предположение о
              том, что по энергетических товарам потенциал почти исчерпан. В результате, наибольший
              экспортный потенциал был идентифицирован для товаров сельского хозяйства, пищевой
              промышленности, обработанной древесины, металлов и изделий их них. Стоит подчеркнуть,
              что значительный потенциал для наращивания поставок был также выявлен для отдельных
              частей транспортных средств, используемых в их производстве.
            </p>
          </div>
        </>
      )}

      {currentCountry === 'Azerbaijan' && (
        <>
          <h2>Азербайджан</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что объем нереализованного экспортного потенциала России
              на рынок Азербайджанской Республики,{' '}
              <b>
                составляет 476 млн долл. США, что соответствует 20,5% от уровня экспорта России в
                Азербайджан 2021 г.{' '}
              </b>
              (2,3 млрд долл.).
            </p>
            <p>
              Наибольший экспортный потенциал был идентифицирован для товаров сельского хозяйства,
              товаров машиностроения (вагоны, локомотивы и их части), металлургических товаров, а
              также химической продукции .
            </p>
          </div>
        </>
      )}

      {currentCountry === 'Armenia' && (
        <>
          <h2>Азербайджан</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что объем нереализованного экспортного потенциала России
              на рынок Армении{' '}
              <b>
                составляет 396 млн долл. США, что соответствует 20,9% от уровня экспорта России в
                Армению 2021 г. (1,9 млрд долл.).
              </b>
            </p>
            <p>
              Наибольший экспортный потенциал был идентифицирован для металлургических товаров,
              товаров сельского хозяйства, драгоценных камней и химической продукции.
            </p>
          </div>
        </>
      )}

      {currentCountry === 'Belarus' && (
        <>
          <h2>Беларусь</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что объем нереализованного экспортного потенциала России
              на рынок Беларуси составляет{' '}
              <b>
                2,3 млрд долл. США, что соответствует 10% от уровня экспорта России в Беларусь за
                2021 г. (23,13 млрд долл.).
              </b>
            </p>
            <p>
              Наибольший экспортный потенциал был идентифицирован для локомотивов, железнодорожных
              вагонов, электрического оборудования, товаров химической промышленности и пищевой
              промышленности.
            </p>
          </div>
        </>
      )}

      {currentCountry === 'Kazakhstan' && (
        <>
          <h2>Казахстан</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что объем нереализованного экспортного потенциала России
              на рынок Армении составляет{' '}
              <b>
                2,5 млрд долл. США, что соответствует 14% от уровня экспорта России в Казахстан за
                2021 г. (18,5 млрд долл.).
              </b>
            </p>
            <p>
              Наибольший экспортный потенциал был идентифицирован для различных видов минеральных
              продуктов (руды), отдельных частей транспортных средств, товаров пищевой
              промышленности, химической промышленности и сельского хозяйства.
            </p>
          </div>
        </>
      )}

      {currentCountry === 'Kyrgyzstan' && (
        <>
          <h2>Кыргызстан</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что объем нереализованного экспортного потенциала России
              на рынок Киргизии составляет{' '}
              <b>
                492 млн долл. США, что соответствует 23% от уровня экспорта России в Киргизию за
                2021 г. (2,15 млрд долл.).
              </b>
            </p>
            <p>
              Наибольший экспортный потенциал был идентифицирован для товаров пищевой промышленности
              и сельского хозяйства, химической промышленности, легкой промышленности и
              электрического оборудования.
            </p>
          </div>
        </>
      )}

      {currentCountry === 'Moldova' && (
        <>
          <h2>Молдова</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что объем нереализованного экспортного потенциала России
              на рынок Молдовы{' '}
              <b>
                составляет 269 млн долл. США, что соответствует 15,3% от уровня экспорта России в
                Молдову за 2021 г. (1,75 млрд долл.).
              </b>
            </p>
            <p>
              Наибольший экспортный потенциал был идентифицирован для товаров химической
              промышленности, деревообработки, пищевой промышленности и металлургии.
            </p>
          </div>
        </>
      )}

      {currentCountry === 'Tajikistan' && (
        <>
          <h2>Таджикистан</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что объем нереализованного экспортного потенциала России
              на рынок Таджикистана{' '}
              <b>
                составляет 354 млн долл. США, что соответствует 32% от уровня экспорта России в
                Туркменистан за 2021 г. (1,11 млрд долл.).
              </b>
            </p>
            <p>
              Наибольший экспортный потенциал был идентифицирован для товаров сельского хозяйства,
              деревообработки, металлургии, транспортных средств и химической промышленности.
            </p>
          </div>
        </>
      )}

      {currentCountry === 'Turkmenistan' && (
        <>
          <h2>Туркменистан</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что объем нереализованного экспортного потенциала России
              на рынок Туркмен составляет 203 млн долл. США,{' '}
              <b>
                что соответствует 28% от уровня экспорта России в Таджикистан за 2021 г. (725 млн
                долл.).
              </b>
            </p>
            <p>
              Наибольший экспортный потенциал был идентифицирован для товаров металлургической
              промышленности, электрического оборудования, химической промышленности и пищевой
              промышленности.
            </p>
          </div>
        </>
      )}

      {currentCountry === 'Uzbekistan' && (
        <>
          <h2>Узбекистан</h2>
          <div style={itemBlockStyle}>
            <p>
              Результаты оценок показали, что объем нереализованного экспортного потенциала России
              на рынок Узбекистана{' '}
              <b>
                составляет 1,82 млрд долл. США, что соответствует 35% от уровня экспорта России в
                Узбекистан за 2021 г. (5,2 млрд долл.).
              </b>
            </p>
            <p>
              Наибольший экспортный потенциал был идентифицирован для товаров и пищевой
              промышленности и сельского хозяйства, деревообработки, металлургии, химической
              промышленности и транспортных средств.
            </p>
          </div>
        </>
      )}

      <div className={styles.page_section}>
        <div className={styles.sub_section}>
          <div className={styles.tile}>
            <div className={styles.tile_title}>
              Методология расчета нереализованного экспортного потенциала
            </div>
            <a
              href={calculationMethodology}
              download="Методология.pdf"
              className={styles.downloadPDF_link}
            >
              <span className={styles.downloadPDF_link_img}>
                <img src={DownloadPDF} alt="pdf download" />
              </span>
              <span>Скачать данные</span>
            </a>
          </div>
        </div>
        <div className={styles.sub_section}>
          <div className={styles.tile}>
            <div className={styles.tile_title}>
              Подробные результаты оценки нереализованного экспортного потенциала России на рынки
              стран СНГ
            </div>
            <a
              href={exportPotential}
              download="Экспортный потенциал.xlsx"
              className={styles.downloadXLSX_link}
            >
              <span className={styles.downloadXLSX_link_img}>
                <img src={DownloadXLSX} alt="xlsx download" />
              </span>
              <span>Скачать данные</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportPotentialSC;
