const realMoneyIncomeIndex = {
  realMonetaryIncomeIndexEAEU: {
    method: 'GET',
    url: 'EAEU/macro/yearly_real_income_index/',
  },
  // Для приведенных ниже API графики не строятся!
  realMonetaryIncomeIndexBel: {
    method: 'GET',
    url: 'EAEU/macro/yearly_real_income_index/',
    params: { region: 'Беларусь' },
  },
  realMonetaryIncomeIndexKaz: {
    method: 'GET',
    url: 'EAEU/macro/yearly_real_income_index/',
    params: { region: 'Казахстан' },
  },
  realMonetaryIncomeIndexRus: {
    method: 'GET',
    url: 'EAEU/macro/yearly_real_income_index/',
    params: { region: 'Россия' },
  },
};

export default realMoneyIncomeIndex;
