import { API } from 'services/API';
import { ChartOptions } from '../chartOptions';

export const ChartConfigs = {
  Covid19: {
    Russia: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesRus },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsRus },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Belarus: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesBel },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsBel },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Armenia: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesArm },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsArm },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Kyrgyzstan: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesKyr },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsKyr },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Kazakhstan: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesKaz },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsKaz },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Azerbaijan: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesAze },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsAze },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Georgia: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesGeo },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsGeo },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Moldova: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesMol },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsMol },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Tajikistan: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesTaj },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsTaj },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Uzbekistan: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesUzb },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsUzb },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
    Ukraine: [
      {
        title: 'Заболеваемость',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidCasesUkr },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
      {
        title: 'Вакцинация',
        chart: 'SplineChartOneY',
        request: { ...API.covid.covidVaccinationsUkr },
        chartOptions: { ...ChartOptions.SplineChartOneY.preset4 },
      },
    ],
  },
};
