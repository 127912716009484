import axios, { AxiosError } from 'axios';
import AuthUser from 'context/AuthUser';
import { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const AuthVerifyRoute = () => {
  const history = useHistory();
  const { setCommonError } = useContext(AuthUser);
  const { token } = useParams<{ token?: string }>();

  useEffect(() => {
    const abortController = new AbortController();

    if (token) {
      axios
        .get(`https://integration.vavt.ru/auth/api/v1/verify/${token}`, {
          signal: abortController.signal,
        })
        // .get(`http://localhost:7654/verify/${token}`, { signal: abortController.signal })
        .then((response) => {
          if (response.status === 200) {
            history.push('/login/?confirmed=true');
            return;
          }

          history.push('/');
        })
        .catch((error) => {
          if (abortController.signal.aborted) return;
          const err = error as AxiosError<any>;
          if (err.response?.data?.detail?.length) {
            setCommonError(err.response?.data?.detail[0].msg);
          }

          history.push('/');
        });
    }

    return () => {
      abortController.abort();
    };
  }, [token]);

  return <div />;
};
