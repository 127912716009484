import Emblem from 'assets/img/emblem2.svg';
import styles from './InNotSupportedBrowser.module.scss';

const IsNotSupportedBrowser = () => (
  <div className={styles.error_page}>
    <div className={styles.error_page_content}>
      <img src={Emblem} alt="is not supported browser" />
      <h1 className={styles.error_page_title}>Пожалуйста обновите браузер</h1>
      <p className={styles.error_page_description}>
        Для корректной работы портала необходима одна из последних версий браузеров Google Chrome,
        Opera, Mozilla Firefox, Microsoft Edge, браузеров на базе Chromium
      </p>
      <a href="https://economy.gov.ru/">Перейти на сайт Министерства экономического развития РФ</a>
    </div>
  </div>
);

export default IsNotSupportedBrowser;
